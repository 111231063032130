// src/hooks/use-axios-config.ts
import { AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { axios } from 'utils/axios';
import { configureAxiosInstance } from 'utils/configure-axios';

export const useAxiosConfig = (): AxiosInstance => {
  const { locale } = useRouter();

  useEffect(() => {
    configureAxiosInstance(axios, locale);
  }, [locale]);

  return axios;
};
