import { SubscriptionInterval } from '../constants/order';

export const ProductType = {
  WetFood: 'Nassfutter',
  DryFood: 'Trockenfutter',
  FoodSupplement: 'Nahrungsergänzung',
  Med: 'Med',
  FreshMenus: 'Frische-Menüs',
  Snacks: 'Snacks',
  Accessories: 'Zubehör',
  Gift: 'Geschenk',
  Care: 'Pflege',
  Testpaket: 'Testpaket',
} as const;

export type ProductType = (typeof ProductType)[keyof typeof ProductType];

export interface ShopifyImage {
  progressivePlaceholderSrc?: string;
  originalSrc: string;
  originalSrc2x?: string;
  altText: string | null;
  id: string;
}

export interface Product {
  id: number;
  title: string;
  handle?: string;
  onlineStoreUrl: string | null;
  description: string;
  tags: Array<string>;
  productType: ProductType;
  content: Array<ProductContentSection>;
  images: Array<ShopifyImage>;
  metadata: ProductMetadata;
  variantTitle?: string;
  variants: Array<any>;
  variant?: any;
  availableForSale?: boolean;
}

export interface ProductContentSection {
  index: number;
  title: string;
  html: string;
}

export interface ProductMetadata {
  accentuate: {
    contentfulId: string;
    icon1: string;
    icon2: string;
    icon3: string;
    mainTitle: string;
    number1: number;
    number2: number;
    number3: number;
    preorderText?: string;
    productVideo?: string;
    productVideoThumbnail?: string;
    ratioPlateImage: string;
    ribbonIcon: string;
    ribbonText: string;
    ribbonText1?: string;
    ribbonText2?: string;
    ribbonColor: string;
    title: string;
    subTitle: string;
    textAdditional: string;
    text1: string;
    text2: string;
    text3: string;
  };
  rating: {
    count: number;
    score: number;
  };
  subscription: {
    enabled: boolean;
    intervals: string;
    variants: Array<{
      productId: number;
      interval: SubscriptionInterval;
      price: number;
      variantId: number;
      originVariantId: number;
    }>;
  };
  petsdeli?: {
    includedProductVariants?: string;
    uid: string;
    feed_custom1?: string;
  };
}

export type CartUpsellProductDetails = {
  availableForSale: boolean;
  handle: string;
  id: number;
  image: string;
  price: number;
  productId: number;
  productTitle: string;
  productType: string;
  promoteVariantId?: number;
  quantity: number;
  sku: string;
  tags: string;
  title: string;
  variantId: number;
  variantTitle: string;
};

/**
 * Assert function for CartUpsellProductDetails.
 */
export function isCartUpsellProductDetails(
  val: any
): val is CartUpsellProductDetails {
  return typeof val === 'object' && typeof val['variantId'] === 'string';
}

export type CrossSellProductDetails = {
  availableForSale: boolean;
  compareAtPrice?: number;
  description: string;
  handle: string;
  image: string;
  price: number;
  productId: string;
  productTitle: string;
  productType: string;
  quantity: number;
  sku: string;
  tags: string;
  variantId: string;
  variantTitle: string;
};

export function assertCrossSellProductDetails(
  obj: any
): asserts obj is CrossSellProductDetails {
  if (!obj) throw new Error('Object cannot be null or undefined');

  const requiredKeys = [
    'availableForSale',
    'description',
    'handle',
    'image',
    'price',
    'productId',
    'productTitle',
    'productType',
    'quantity',
    'sku',
    'tags',
    'variantId',
    'variantTitle',
  ];

  requiredKeys.forEach((key) => {
    if (!(key in obj)) throw new Error(`Missing required key "${key}"`);
  });

  if (typeof obj.availableForSale !== 'boolean')
    throw new Error('availableForSale must be a boolean');
  if (typeof obj.description !== 'string')
    throw new Error('description must be a string');
  if (typeof obj.handle !== 'string')
    throw new Error('handle must be a string');
  if (typeof obj.image !== 'string') throw new Error('image must be a string');
  if (typeof obj.price !== 'number') throw new Error('price must be a number');
  if (typeof obj.productId !== 'string')
    throw new Error('productId must be a number');
  if (typeof obj.productTitle !== 'string')
    throw new Error('productTitle must be a string');
  if (typeof obj.productType !== 'string')
    throw new Error('productType must be a string');
  if (typeof obj.quantity !== 'number')
    throw new Error('quantity must be a number');
  if (typeof obj.sku !== 'string') throw new Error('sku must be a string');
  if (typeof obj.tags !== 'string') throw new Error('tags must be a string');
  if (typeof obj.variantId !== 'number')
    throw new Error('variantId must be a number');
  if (typeof obj.variantTitle !== 'string')
    throw new Error('variantTitle must be a string');

  if (
    obj.compareAtPrice !== undefined &&
    typeof obj.compareAtPrice !== 'number'
  ) {
    throw new Error('compareAtPrice must be a number if present');
  }
}
