import { getAllSpecialTestIds } from 'api/contentful-graphql';
import { useConfig } from 'config';
import React from 'react';
import useSWR from 'swr';
import SpecialTestWrapper from './special-test-wrapper';

interface SpecialTestContainerProps {}

/**
 * Component to create separated state for special test cases
 */
const SpecialTestContainer: React.FC<SpecialTestContainerProps> = () => {
  const { contentfulEnv, contentfulSpace } = useConfig();
  const { data: specialTestIds, isValidating } = useSWR(
    'getAllSpecialTestIds',
    () => getAllSpecialTestIds({ contentfulEnv, contentfulSpace }),
    {
      revalidateOnFocus: false,
    }
  );
  return (
    <>
      {!isValidating &&
        specialTestIds &&
        specialTestIds?.map((id) => <SpecialTestWrapper key={id} id={id} />)}
    </>
  );
};

export default SpecialTestContainer;
