import { captureMessage } from '@sentry/browser';
import {
  CollectionResponse,
  fetchCollectionWithSegmentation,
} from 'api/product';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import { ShopId } from 'constants/shop';
import { axios } from 'utils/axios';
import { SmartCollectionInput, SmartCollectionResponse } from './types';
import { isSmartCollectionResponse } from './utils';

/**
 * Overloaded function to fetch collection.
 * When handle is assigned, collection response is returned
 * Fetch products based on scoring logics.
 *
 * SmartCollection applies different logics based on the header values or ninetailed values
 * When `DynamicTestScenario` `variantB` is applied, API throws an error when only one segment is assigned without slug
 */
export async function getSmartCollection(
  input: SmartCollectionInput,
  shopId: ShopId
): Promise<SmartCollectionResponse>;
export async function getSmartCollection(
  collectionHandle: string,
  shopId: ShopId,
  /** Omit 404 error toast if true */
  shouldOmitError?: boolean
): Promise<CollectionResponse>;

export async function getSmartCollection(
  input: SmartCollectionInput | string,
  shopId: ShopId,
  shouldOmitError?: boolean
): Promise<SmartCollectionResponse | CollectionResponse> {
  const featureFlg = getFeatureFlag(
    FeatureFlag.smartCollectionEndpoint,
    shopId
  );
  if (!featureFlg) {
    if (typeof input === 'string') {
      return await fetchCollectionWithSegmentation(input, shouldOmitError);
    } else if ('slug' in input && input.slug) {
      return await fetchCollectionWithSegmentation(input.slug, shouldOmitError);
    } else {
      throw new Error('unexpected input');
    }
  }

  let audiences: undefined | string | number | boolean;

  const result = await axios
    .post<SmartCollectionResponse>(
      '/api/legacy/smart-collections',
      {
        ...(typeof input === 'string'
          ? {
              slug: input,
            }
          : input),
      },
      { isPdexApi: true }
    )
    .then((data) => {
      audiences = data.config.headers && data.config.headers['audiences'];
      return data;
    })
    .then(({ data }) => data);

  if (!result) {
    throw new Error('unexpected results');
  }

  if (isSmartCollectionResponse(result) && result.items.length === 0) {
    captureMessage('No items are found via SmartCollection API', {
      level: 'debug',
      extra: {
        input: input,
        audiences: audiences,
      },
    });
  }

  return result;
}
