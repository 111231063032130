import axios from 'axios';
import {
  GetAbTestByIdQuery,
  GetAbTestByIdQueryVariables,
  GetAbTestsByIdsQueryQuery,
  GetAbTestsByIdsQueryQueryVariables,
  GetAllAbTestIdsQuery,
  GetAllRedirectionTestIdsQuery,
  GetAllSpecialTestIdsQuery,
} from 'generated/graphql';
import { getAbTestByIdQuery } from 'queries/contentful/queries/getAbTestById';
import { getAbTestsByIdsQuery } from 'queries/contentful/queries/getAbTestsByIds';
import { getAllAbTestIdsQuery } from 'queries/contentful/queries/getAllAbTestIds';
import { getAllRedirectionTestIdsQuery } from 'queries/contentful/queries/getAllRedirectionTestIds';
import { getAllSpecialTestIdsQuery } from 'queries/contentful/queries/getAllSpecialTestIds';
import { omitNullableHandler } from 'utils/type-helper';

interface ContentfulConfig {
  contentfulEnv: string;
  contentfulSpace: string;
}

/**
 * Get all special test ids.
 */
export const getAllSpecialTestIds = async ({
  contentfulEnv,
  contentfulSpace,
}: ContentfulConfig): Promise<Array<string>> => {
  const res = await queryGraphql<GetAllSpecialTestIdsQuery, undefined>({
    query: getAllSpecialTestIdsQuery,
    contentfulEnv,
    contentfulSpace,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Get all redirection test ids.
 */
export const getAllRedirectionTestIds = async ({
  contentfulEnv,
  contentfulSpace,
}: ContentfulConfig): Promise<string[]> => {
  const res = await queryGraphql<GetAllRedirectionTestIdsQuery, undefined>({
    query: getAllRedirectionTestIdsQuery,
    contentfulEnv,
    contentfulSpace,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Fetch all A/B test ids.
 */
export const getAllAbTestIds = async ({
  contentfulEnv,
  contentfulSpace,
}: ContentfulConfig): Promise<string[]> => {
  const res = await queryGraphql<GetAllAbTestIdsQuery, undefined>({
    query: getAllAbTestIdsQuery,
    contentfulEnv,
    contentfulSpace,
  });

  return (
    res.data.experimentConfigCollection?.items
      .map((item) => item?.sys.id)
      .filter(omitNullableHandler) || []
  );
};

/**
 * Fetch all A/B test ids.
 * @deprecated
 */
export const getAbTestById = async ({
  id,
  contentfulEnv,
  contentfulSpace,
}: { id: string } & ContentfulConfig): Promise<GetAbTestByIdQuery> => {
  const { data } = await queryGraphql<
    GetAbTestByIdQuery,
    GetAbTestByIdQueryVariables
  >({
    query: getAbTestByIdQuery,
    variables: { id },
    contentfulEnv,
    contentfulSpace,
  });

  return data;
};

/**
 * Fetch A/B tests by ids.
 */
export const getAbTestsByIds = async ({
  ids,
  contentfulEnv,
  contentfulSpace,
}: {
  ids: Array<string>;
  contentfulEnv: string;
  contentfulSpace: string;
}): Promise<GetAbTestsByIdsQueryQuery> => {
  const { data } = await queryGraphql<
    GetAbTestsByIdsQueryQuery,
    GetAbTestsByIdsQueryQueryVariables
  >({
    query: getAbTestsByIdsQuery,
    contentfulEnv,
    contentfulSpace,
    variables: {
      ids,
    },
  });

  return data;
};

const queryGraphql = <Type = unknown, Variables = Record<string, unknown>>({
  contentfulEnv,
  contentfulSpace,
  query,
  variables,
}: {
  contentfulEnv: string;
  contentfulSpace: string;
  query?: string;
  variables?: Variables;
}): Promise<{ data: Type }> =>
  axios
    .post<{ data: Type }>(
      `https://graphql.contentful.com/content/v1/spaces/${contentfulSpace}/environments/${contentfulEnv}`,
      { query, variables },
      {
        headers: {
          Authorization: `Bearer db857604e8d1eac420e74525ec9395d6ecf464bf54a2b10750ceb814aaca0a8d`,
        },
      }
    )
    .then((res) => res.data);
