import { CommonCollections } from 'config/types';

export default {
  bundles: 'probierpakete',
  dogsWetFood: 'fur-hunde-nassfutter',
  dogsDryFood: 'fur-hunde-trockenfutter',
  dogsBarf: 'fur-hunde-barf-frische-menus',
  dogsSnacks: 'fur-hunde-snacks',
  dogsGreen: 'fur-hunde-green',
  dogsSupplements: 'fur-hunde-nahrungserganzung',
  dogsHealth: 'fur-hunde-gesundheit',
  dogsBundles: 'fur-hunde-bundles',
  dogsBestsellers: 'fur-hunde-beliebte-produkte',
  dogsAccessories: 'fur-hunde-zubehor',
  dogsCare: 'fur-hunde-pflege',
  catsWetFood: 'fur-katzen-nassfutter',
  catsDryFood: 'fur-katzen-trockenfutter',
  catsBarf: 'fur-katzen-barf-frische-menus',
  catsSnacks: 'fur-katzen-snacks',
  catsSupplements: 'fur-katzen-nahrungserganzung',
  catsBundles: 'fur-katzen-bundles',
  catsBestsellers: 'fur-katzen-beliebte-produkte',
  catsAccessories: 'fur-katzen-zubehor',
  catsCare: 'fur-katzen-pflege',

  dogsWetFoodFoodline: 'startseite-cdp-nafu-hund-no-content',
  dogsDryFoodFoodline: 'startseite-cdp-trofu-hund-no-content',
  dogsBarfFoodline: 'startseite-cdp-barf-hund-no-content',
  dogsSnacksFoodline: 'startseite-cdp-snack-hund-no-content',
  catsWetFoodFoodline: 'startseite-cdp-nafu-katze-no-content',
  catsDryFoodFoodline: 'startseite-cdp-trofu-katze-no-content',
  catsBarfFoodline: 'startseite-cdp-barf-katze-no-content',
  catsSnacksFoodline: 'startseite-cdp-snack-katze-no-content',

  dogsSmallBreeds: 'fur-hunde-futter-fur-kleine-rassen',
  dogsPuppyJunior: 'fur-hunde-welpen-und-junghunde',
  dogsAllProducts: 'fur-hunde-alle-produkte',
  catsAllProducts: 'fur-katzen-alle-produkte',
} as CommonCollections;
