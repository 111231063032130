import { useConfig } from 'config';
import { CookieSerializeOptions } from 'cookie';
import { useEffect } from 'react';
import { useCookieStore } from 'stores/cookie';
export const CookieName = {
  Audiences: 'pd_audiences',
  Experiments: 'pd_ninetailed',
} as const;

export type CookieName = (typeof CookieName)[keyof typeof CookieName];

type Input = {
  cookieId?: CookieName;
  cookieValue?: string;
};

const useSetToCookie = ({ cookieId, cookieValue }: Input): void => {
  const { updateCookie } = useCookieStore();
  const { cookieDomain } = useConfig();

  useEffect(() => {
    const cookieName = cookieId || CookieName.Experiments;
    const options: CookieSerializeOptions = {
      path: '/',
      domain: cookieDomain,
    };

    if (cookieValue) {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      options.expires = date;
      updateCookie(cookieName, cookieValue, options);
    } else {
      // delete cookie value by setting expires
      const date = new Date();
      date.setTime(0);
      options.expires = date;
      updateCookie(cookieName, '', options);
    }
  }, [cookieDomain, cookieId, cookieValue, updateCookie]);
};

export default useSetToCookie;
