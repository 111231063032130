import { getLocaleConfig } from 'config';
import { Entry } from 'contentful';
import { IProductPageFields, IProductPagesFields } from 'global';
import { ProductType } from '../interfaces/product';

export const isCollectionPage = (
  page: Entry<IProductPageFields | IProductPagesFields>
): boolean => {
  return !!(
    page.fields.handle && page.fields.handle?.indexOf('collections') > -1
  );
};

/**
 * creates collection handles by using product types
 * @param petType
 * @param productType
 * @returns
 */
export const getCollectionHandleByProductDetails = (
  petType: 'dog' | 'cat',
  productType: ProductType,
  locale?: string
): string => {
  const { commonCategories, commonCollections } = getLocaleConfig(locale);

  const dogHandle = (): string => {
    switch (productType) {
      case commonCategories.wetFood:
        return commonCollections.dogsWetFood;
      case commonCategories.dryFood:
        return commonCollections.dogsDryFood;
      case commonCategories.barf:
        return commonCollections.dogsBarf;
      case commonCategories.snacks:
        return commonCollections.dogsSnacks;
      case commonCategories.supplements:
        return commonCollections.dogsSupplements;
      case commonCategories.health:
        return commonCollections.dogsHealth;
      case commonCategories.accessories:
        return commonCollections.dogsAccessories;
      case commonCategories.care:
        return commonCollections.dogsCare;
      case commonCategories.bundle:
        return commonCollections.dogsBundles;
      default:
        return commonCollections.dogsWetFood;
    }
  };
  const catsHandle = (): string => {
    switch (productType) {
      case commonCategories.wetFood:
        return commonCollections.catsWetFood;
      case commonCategories.dryFood:
        return commonCollections.catsDryFood;
      case commonCategories.barf:
        return commonCollections.catsBarf;
      case commonCategories.snacks:
        return commonCollections.catsSnacks;
      case commonCategories.supplements:
        return commonCollections.catsSupplements;
      case commonCategories.accessories:
        return commonCollections.catsAccessories;
      case commonCategories.care:
        return commonCollections.catsCare;
      default:
        return commonCollections.catsWetFood;
    }
  };

  return petType === 'dog' ? dogHandle() : catsHandle();
};
