export default {
  'cart:title': 'Warenkorb',
  'cart:sub-title': '{value} Artikel',
  'cart:top-message-1': 'Gute Wahl!',
  'cart:top-message-2': 'Hier sind deine Produkte',
  'cart:available-payment-methods': 'Wir akzeptieren',
  'cart:available-payment-methods-2': 'Sicher bezahlen mit',
  'cart:empty:title': 'Dein Warenkorb ist leer.',
  'cart:empty:description':
    'Stöbere durch unseren Shop und finde leckere und gesunde Tiernahrung für Deinen Vierbeiner.',
  'cart:empty:description-test':
    'Du suchst noch nach dem richtigen Futter? Probier doch mal unseren Futterberater.',
  'cart:empty:button': 'Zum Futterberater',
  'cart:product:interval': 'Lieferung',
  'cart:minimum-basket-value': 'Mindestbestellwert',
  'cart:free-shipping-notice':
    'Noch <span>{insufficientMoney}</span> bis zum <span>kostenlosen Versand</span>',
  'cart:free-shipping-notice-test':
    'Noch {insufficientMoney} bis zum kostenlosen Versand',
  'cart:summary:shipping:free': 'Kostenlos',
  'cart:summary:shipping:free-test': '<div><span>4,90 €</span> 0,00 €</div>',
  'cart:summary:shipping:min-price': 'ab {price}',
  'cart:empty:dogs-button': 'FÜR HUNDE',
  'cart:empty:cats-button': 'FÜR KATZEN',
  'cart:mbv:notice-text':
    'Benötigter Betrag, um den Mindestbestellwert zu erreichen',
  'cart:mbv:notice-text-test':
    'Der Mindestbestellwert ist {mov}. Es fehlen {insufficientMoney}',
  'cart:mbv:cta-text':
    'Warum wir bei Tiefkühl-Bestellungen einen Mindestbestellwert haben',
  'cart:mbv:modal:title': 'Mindestbestellwert bei Frische-Menüs',
  'cart:mbv:modal:description': `
    <p>
      Bitte beachte, dass bei Bestellungen, die tiefgekühlte Produkte enthalten, ein Mindestbestellwert von 30 €.
    </p>
    {br}
    <p>
      Die verbleibenden <b>{insufficientMoney}</b> kannst Du mit Produkten Deiner Wahl auffüllen. Kennst Du bereits unsere hochwertigen BARF-Ergänzungen wie unseren Mineral-Mix oder eines unserer Öle?
    <p/>
  `,
  'cart:mbv:modal:cta': 'Jetzt weiter shoppen',
  'cart:sub-total': 'Zwischensumme inkl. MwSt.',
  'cart:sub-total-with-disscounts': 'Zwischensumme inkl. Rabatt',
  'cart:savings': 'Du sparst',
  'cart:savings:subscription': 'Abo Rabatt',
  'cart:savings:referal': 'Freunde werben Freunde',
  'cart:savings:credit': 'Pets Deli Guthaben',
  'cart:savings:offer': 'Angebot',
  'cart:savings:offer:without-badge': 'Angebot',
  'cart:savings:subscription-discount': 'Abo-Rabatt',
  'cart:savings:coupon': 'Gutschein {couponCode}',
  'cart:total': 'Gesamtsumme',
  'cart:total:test': 'Preis inkl. MwSt.',
  'cart:total-with-shipping:test': 'Preis inkl. MwSt. und Versand',
  'cart:tax': 'inkl. MwSt.',
  'cart:tax:with-shipping': 'inkl. MwSt. & Versand',
  'cart:tax:with-free-shipping': 'inkl. MwSt. & kostenloser Versand',
  'cart:shipping': 'Versand',
  'cart:shipping:new-customer':
    'Versand <span>(Kostenfrei für Neukunden)</span>',
  'cart:shipping:new-customer-test': 'Gratis Versand',
  'cart:shipping:existing-customer':
    'Versand <span>(Kostenfrei ab 60 €)</span>',
  'cart:go-to-checkout': 'Zur Kasse',
  'cart:gift-items:title': 'Sichere dir bis zu 4 Geschenke',
  'cart:gift-items:free': 'Gratis',
  'cart:preorder-shipping-delay-title': 'Hinweis',
  'cart:preorder-mixed-shipping-delay':
    'Der Versand der Adventskalender findet ab Oktober 2020 statt. Der Rest deiner Bestellung wird direkt verarbeitet und zugestellt.',
  'cart:preorder-only-shipping-delay':
    'Der Versand der Adventskalender findet ab Oktober 2020 statt. Freu dich drauf!',
  'cart:discount:Referal': 'Freunde werben Freunde',
  'cart:discount:Credit': 'Pets Deli Guthaben',
  'cart:discount:Coupon': 'Pets Deli Gutschein',
  'cart:discount-error:referal:ItemsTotalToSmall':
    'Benötigter Betrag, um den Freunde werben Rabatt zu bekommen',
  'cart:discount-error:referal:ItemsTotalToSmall-test':
    'Benötigter Betrag, um den Freunde werben Rabatt zu bekommen {insufficientMoney}',
  'cart:discount-error:referal:InvalidReferal':
    'Ungültiger Referenzcode. Bitte wenden Sie sich an den Kundendienst, wenn Sie der Meinung sind, dass dies ein Fehler ist.',

  'cart:bonus-program:summary': '{value} Punkte für deinen Einkauf',
  'cart:bonus-program:details': 'Details',

  'cart:bonus-program:info-title': 'Pets Deli Bonus Programm',
  'cart:bonus-program:info-subtitle':
    'Sammle mit unserem Bonusprogramm Punkte bei jeder Bestellung und tausche diese gegen Einkaufsguthaben um. Deinen Punktestand kannst du jederzeit in deinem Account unter → "<a>Bonusprogramm</a>" einsehen.',
  'cart:bonus-program:info-benefit-1': '1€ Einkaufswert = 1 Punkt',
  'cart:bonus-program:info-benefit-2': '200 Bonuspunkte = 5€',
  'cart:bonus-program:info-benefit-3': '500 Bonuspunkte = 15€',
  'cart:bonus-program:more-info':
    'Und sicher dir bei besonderen Anlässen und Aktionen unbedingt Extrapunkte!',
  'cart:interval-selection:regular-delivery': 'Regelmäßige Lieferung',
  'cart:interval-selection:activate-subscription': 'Abo aktivieren',
  'cart:interval-selection:order-type': 'Lieferung',
  'cart:interval-selection:one-off': 'Einmalig',
  'cart:interval-selection:deactivate-subscription': 'Kein Abo',

  'cart:subs-info-modal:header': 'DAS PETS DELI FUTTERABO',
  'cart:subs-info-modal:instruction:point-1':
    'Einfach Lieblingsprodukte im Shop suchen',
  'cart:subs-overlay:title':
    'Pass das Abo so an, dass es am besten zu dir passt',
  'cart:subs-overlay:accept-selection': 'Auswahl akzeptieren',
  'cart:subs-info-modal:instruction:point-2':
    'Auf den Produktseiten Größe & Menge auswählen',
  'cart:subs-info-modal:instruction:point-3':
    'Regelmäßige Lieferung aktivieren & Lieferintervall festlegen',
  'cart:subs-info-modal:instruction:point-4': 'Wie gewohnt bestellen...fertig!',
  'cart:subs-info-modal:benefits:header': 'BEQUEM, FLEXIBEL, RISIKOFREI!',
  'cart:subs-info-modal:benefits:point-1': 'Keine Mindestvertragslaufzeit',
  'cart:subs-info-modal:benefits:point-2': 'Jederzeit selbstständig kündbar',
  'cart:subs-info-modal:benefits:point-3':
    'Dauerhaft {discount}% mit jeder Lieferung sparen',
  'cart:subs-info-modal:benefits:point-4':
    'Änderung des Lieferintervalls sowie Pausierung des Abos jederzeit möglich',

  'cart:summary:pay-also-with': 'Oder direkt bezahlen mit',
  'cart:summary:pay-also-with-test': 'Oder',
  'cart:summary:button:express-checkout-mobile': 'Express Checkout',

  'cart:gift-selection:header':
    'Sichere dir ein kostenloses Geschenk von Pets Deli',
  'cart:gift-selection:message:part-1': 'Ab einem',
  'cart:gift-selection:message:part-2': 'Einkaufswert von',
  'cart:gift-selection:message:part-3':
    ' bekommst du ein kostenloses Geschenk von uns zu deiner Bestellung dazu.',
  'cart:gift-selection:header-with-gift':
    'Dein kostenloses Geschenk von Pets Deli',

  'cart:money-back:title': '30-Tage Lecker-Garantie 😋',
  'cart:money-back:sub-title':
    'Wir bieten dir eine kostenlose Futter-Alternative, falls es nicht schmeckt.',
  'cart:money-back:modal:title':
    'Pets Deli löst Futterprobleme - nachweislich.',
  'cart:money-back:modal:sub-title':
    "Unsere Devise: <b>Schmeckt nicht, gibt's nicht!</b> Falls es deiner Fellnase wiedererwartend nicht schmecken sollte, erhältst du von uns eine kostenlose Futter-Alternative.",
  'cart:money-back:modal:list-title': "So einfach geht's:",
  'cart:money-back:modal:step-1':
    'Fülle einfach unser Kontaktformular aus, welches du hier findest. Nutze bei Problem bitte “Lecker Garantie” <a>Anfrage einreichen - PETS DELI</a>',
  'cart:money-back:modal:step-2':
    'Erhalte eine kostenlose Futterberatung mit unseren Ernährungsexperten, um das richtige Alternativ-Produkt passend für eure Bedürfnisse zu finden.',
  'cart:money-back:modal:step-3':
    'Wir kümmern uns, dass du dein neues Produkt schnellstmöglich zugeschickt bekommst.',

  'cart:upsell-product:title': 'Pssst - dieses Angebot ist nur für dich!',
  'cart:upsell-product:cta': 'Mitbestellen',
  'cart:upsell-product:cta-item-added': 'Hinzugefügt',
  // PT-4103 Frontend :: A/B test :: Improve Voucher Redemption Communication
  // https://petsdeli.atlassian.net/browse/PT-4103
  'cart:coupon:successfully-applied':
    'Der Coupon {couponCode} wurde erfolgreich hinterlegt und wird beim Bezahlvorgang angewendet.',
  'cart:coupon:successfully-applied-variant:close': 'Schliessen',

  'cart:discount-modal:greeting': `wir haben'nen heißen Deal für dich!`,
  'cart:discount-modal:title': 'Speziell für dich',
  'cart:discount-modal:discount': '<span>{percentage}%</span> auf {category}',
  'cart:discount-modal:category:supplements': 'Nahrungsergänzer',
  'cart:discount-modal:category:snacks': 'Snacks',
  'cart:discount-modal:category:snacks:subsscription':
    'Snacks mit der regelmäßige Lieferung',
  'cart:discount-modal:category:mov': 'ab 40€ Bestellwert',
  'cart:discount-modal:category:cta:discover-offers': 'TOP ANGEBOTE ENTDECKEN',
  'cart:discount-modal:category:cta:add-snacks': 'Snacks hinzufügen',
  'cart:discount-modal:category:cta:subscribe-snacks': 'SNACKS ABONNIEREN',
  'cart:discount-modal:category:cta:go-to-checkout': 'Weiter zur Kasse',

  'cart:coupon-modal-pop-up:successfully-applied':
    '<p>Glückwunsch!</p>Dein Gutschein <strong> {couponCode} </strong> wurde eingelöst.',
  'cart:coupon-modal-pop-up:minimum-order-value':
    'ab {miniminumOrderValue}€ Bestellwert',

  'cart:subs-reminder-modal-pop-up:title': 'Smart gespart im Abo!',
  'cart:subs-reminder-modal-pop-up:title:success': 'Erfolgreich',
  'cart:subs-reminder-modal-pop-up:title:success:text':
    'Yay! Du hast gerade <br></br> <span></span> <br></br> gespart!',
  'cart:subs-reminder-modal-pop-up:title:success:info-text':
    'Du kannst dein Abo im Warenkorb oder nach dem Bezahlen in deinem Account bearbeiten.',
  'cart:subs-reminder-modal-pop-up:sub-title:scenario':
    "Mach's regelmäßig und spare <span></span> im Abo!",
  'cart:subs-reminder-modal-pop-up:info-abo':
    'Abo <b>jederzeit flexibel</b> anpassen, pausieren & kündigen',
  'cart:subs-reminder-modal-pop-up:interval-selector:title':
    'Wähle dein Lieferintervall',
  'cart:subs-reminder-modal-pop-up:info-abo:show-all-button': 'Zeige Alle',
  'cart:subs-reminder-modal-pop-up:CTA1': 'Abonnieren und Sparen',
  'cart:subs-reminder-modal-pop-up:CTA2': 'Weiter zur Kasse',
  'cart:subs-reminder-modal-pop-up:go-to-checkout': 'Jetzt bezahlen',

  'cart:apple-pay-modal:title': 'Apple Pay Error',
  'cart:apple-pay-modal:content':
    'Etwas ist schiefgelaufen! Bitte versuche es nocheinmal.',

  'cart:bonus-points': 'Bonus Punkte',
  'cart:bonus-points:amount':
    '{amount, plural, =0 {# Punkte} one {# Punkt} other {# Punkte}}',
  'cart:bonus-points:standard': '{amount} Einkaufswert',
  'cart:bonus-points:extra-bonus': 'Extra Punkte',
  'cart:bonus-points:multiplier': '{conversionRate} fach Punkte ',
  'cart:bonus-points:new-customer': 'Willkommenspunkte (für Neukunden)',

  'cart:modal-discount-selector:title': 'Bitte auswählen',
  'cart:modal-discount-selector:info:credits-and-coupon':
    'Das Pets Deli Guthaben und der Gutschein {couponCode} wurden zu deinen Warenkorb hinzugefügt. Es kann jedoch nicht beides für einen Einkauf genutzt werden. Bitte wähle die Option, die du nutzen möchtest.',
  'cart:modal-discount-selector:info:referal-and-coupon':
    'Der Freunde Werben Bonus und der Gutschein BF30 wurden zu deinen Warenkorb hinzugefügt. Es kann jedoch nicht beides für einen Einkauf genutzt werden. Bitte wähle die Option, die du nutzen möchtest.',
  'cart:modal-discount-selector:credits:title': 'Pets Deli Guthaben',
  'cart:modal-discount-selector:credits:cta': 'Guthaben nutzen',
  'cart:modal-discount-selector:coupon:title': 'Gutschein',
  'cart:modal-discount-selector:coupon:titl-with-coupon':
    '{couponCode} Gutschein',
  'cart:modal-discount-selector:coupon:cta': 'Gutschein nutzen',
  'cart:modal-discount-selector:referal:title': 'Freunde werben Bonus',
  'cart:modal-discount-selector:referal:cta': 'Bonus nutzen',
  'cart:oos-modal:title': 'Ups, einige Produkte sind ausverkauft',
  'cart:oos-modal:subtitle':
    '{itemsCount, plural, one {Wir müssen das folgende Produkt aus dem Warenkorb entfernen, weil es nicht mehr auf Lager ist.} other {Wir müssen die folgenden Produkte aus dem Warenkorb entfernen, weil sie nicht mehr auf Lager sind.}}',
  'cart:oos-modal:cta:continue-shopping': 'Weiter einkaufen',
  'cart:oos-modal:cta:go-to-checkout': 'Zur Kasse',

  'cart:low-rate-subscription-modal:very-low:title':
    'Wurde ein Abo von dir erstellt?',
  'cart:low-rate-subscription-modal:very-low:description':
    'Es scheint, als hättest du Produkte im Warenkorb abonniert*. War das beabsichtigt?<span>*Für das Abo gilt ein Mindestbestellwert von {amount}.</span>',
  'cart:low-rate-subscription-modal:very-low:cta:primary':
    'Abo kündigen und zur Kasse',
  'cart:low-rate-subscription-modal:very-low:cta:secondary':
    'Abo auf {amount} erhöhen',
  'cart:low-rate-subscription-modal:low:title': 'Mehr abonnieren, mehr sparen!',
  'cart:low-rate-subscription-modal:low:description':
    'Für das Abo gilt ein Mindestbestellwert von {amount}. Bitte füge weitere Artikel hinzu, um diesen zu erreichen.',
  'cart:low-rate-subscription-modal:low:cta:primary':
    'Abo auf {amount} erhöhen',
  'cart:low-rate-subscription-modal:low:cta:secondary':
    'Abo kündigen und zur Kasse',
  'cart:low-rate-subscription-modal:note':
    'Abo <span>jederzeit flexibel</span> anpassen, pausieren & kündigen',
  'cart:low-rate-subscription:group:title:success':
    'Jetzt kannst du abonnieren und Geld sparen! 🥳',
  'cart:low-rate-subscription:group:title:missing-amount':
    'Bitte füge noch {amount} für das Abo hinzu.',
  'cart:low-rate-subscription:modal-info:title':
    'Deine Ersparnis bei jeder Bestellung',
  'cart:low-rate-subscription:modal-info:original-price':
    'Originaler Preis inkl. MwSt.',
  'cart:low-rate-subscription:modal-info:subscription-discount':
    'Rabatt Regelmäßige Lieferung',
  'cart:low-rate-subscription:modal-info:coupon-discount':
    '{couponCode} Gutschein',
  'cart:low-rate-subscription:modal-info:total-price': 'Dein Preis inkl. MwSt.',
  'cart:low-rate-subscription:modal-info-note':
    'Abo ab <span>{amount} Mindestbestellwert.</span> Weniger Lieferungen helfen, <span>Emissionen zu senken.</span>',
  'cart:tiny-cart:urgency-note':
    'Deine Produkte werden nur {minutes} Minuten reserviert',
};
