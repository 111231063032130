import { NutritionalNeedsTags } from 'interfaces/collection-filter';
import { slugify } from 'utils/strings';

/**
 * Segmentation Ids for allergy
 */
export const AllergySegmentationIds = {
  grainFree: 'grain-free',
  beef: 'beef',
  chicken: 'chicken',
  duck: 'duck',
  fish: 'fish',
  lamb: 'lamb',
  horse: 'horse',
  goat: 'goat',
  sweetPotato: 'sweet-potato',
  potato: 'potato',
} as const;

export type AllergySegmentationIds =
  (typeof AllergySegmentationIds)[keyof typeof AllergySegmentationIds];

const AllergySegmentationSet = new Set(Object.values(AllergySegmentationIds));

/**
 * Assert function for AllergySegmentationIds
 */
export function isAllergySegmentation(
  segmentation: SegmentationIds
): segmentation is AllergySegmentationIds {
  return AllergySegmentationSet.has(segmentation as unknown as any);
}

/**
 * Segmentation Ids for dog age segmentation
 */
export const DogAgeSegmentationIds = {
  /** Dog Puppy */
  Welpe: 'welpe',
  /** Dog Adult  */
  DogAdult: 'dog-adult',
  /** Dog Senior  */
  DogSenior: 'senior',
} as const;

export type DogAgeSegmentationIds =
  (typeof DogAgeSegmentationIds)[keyof typeof DogAgeSegmentationIds];

/**
 * Segmentation Ids for cat age segmentation
 */
export const CatAgeSegmentationIds = {
  /** Kitten (0-1 years old) */
  Kitten: 'kitten',
  /** Cat Adult  */
  CatAdult: 'adultcat',
  /** Senior (older than 10 years) */
  CatSenior: 'seniorcat',
} as const;

export type CatAgeSegmentationIds =
  (typeof CatAgeSegmentationIds)[keyof typeof CatAgeSegmentationIds];

const AgeSegmentationSet = new Set(
  [
    Object.values(DogAgeSegmentationIds),
    Object.values(CatAgeSegmentationIds),
  ].flat()
);

export const AgeSegmentationIds = {
  ...DogAgeSegmentationIds,
  ...CatAgeSegmentationIds,
} as const;

export type AgeSegmentationIds = DogAgeSegmentationIds | CatAgeSegmentationIds;

/**
 * Assert function for AgeSegmentationIds
 */
export function isAgeSegmentation(
  segmentation: SegmentationIds
): segmentation is AgeSegmentationIds {
  return AgeSegmentationSet.has(segmentation as unknown as any);
}

export const NutritionSegmentationIds = {
  /** VeryPicky */
  PickyEater: 'pickyeater',
  /** DigestionIssues */
  Digestion: 'digestion',
  /** intolerances or allergies */
  Intolerance: 'intolerance',
  /** Skin and coat problems */
  SkinFur: 'skin-fur',
  /** Overweight */
  Overweight: 'overweight',
  /** No help needed */
  NoHelpNeeded: 'no-help-needed',
  /** Needs guidance */
  Foodaid: 'foodaid',
  /** Specific Disease */
  Disease: 'disease',
} as const;

export type NutritionSegmentationIds =
  (typeof NutritionSegmentationIds)[keyof typeof NutritionSegmentationIds];

export const CatNutritionSegmentationIds = {
  /** Picky eater for cat */
  CatPickyEater: 'pickyeatercat',
  /** Digestion problem for cat */
  CatDigestion: 'digestioncat',
  /** Overweight cat */
  CatOverweight: 'overweightcat',
  /** Cat which has had an operation for sterilization  */
  Sterilized: 'sterilized',
  /** Outdoor / Freigänger */
  Outdoor: 'outdoor',
  /** No helps are needed */
  Noneedcat: 'noneedcat',
} as const;

export type CatNutritionSegmentationIds =
  (typeof CatNutritionSegmentationIds)[keyof typeof CatNutritionSegmentationIds];

export const BreedSegmentationIds = {
  /** Bulldog / Bulldogge */
  Bulldog: 'bulldog',
  /** Retriever */
  Retriever: 'retriever',
  /** Spitz */
  Spitz: 'spitz',
  /** Dachshund / Dackel */
  Dachshund: 'dachshund',
  /** Poodle / Pudel, Pudelmix */
  Poodle: 'poodle',
  /** Havanese, Maltese / Havaner, Malteser */
  Havanese: 'havanese',
  /** ShepherdDog / Schäferhund */
  ShepherdDog: 'shepherd-dog',
  /** Chihuahua   */
  Chihuahua: 'chihuahua',
  /** Terrier */
  Terrier: 'terrier',
  /** Hütehund / Herding dog */
  Herdingdog: 'herdingdog',
  /** Mixed small */
  MixedSmall: 'mixed-dog-small',
  /** Mixed large  */
  MixedLarge: 'mixed-dog-large',
} as const;

export type BreedSegmentationIds =
  (typeof BreedSegmentationIds)[keyof typeof BreedSegmentationIds];

const BreedSegmentationSet = new Set(Object.values(BreedSegmentationIds));

/**
 * Assert function for BreedSegmentationIds
 */
export function isBreedSegmentation(
  segmentation: SegmentationIds
): segmentation is BreedSegmentationIds {
  return BreedSegmentationSet.has(segmentation as unknown as any);
}

export type DogSegmentationIds =
  | BreedSegmentationIds
  | NutritionSegmentationIds
  | AllergySegmentationIds
  | DogAgeSegmentationIds;

export type CatSegmentationIds =
  | CatNutritionSegmentationIds
  | AllergySegmentationIds
  | CatAgeSegmentationIds;

export type SegmentationIds = DogSegmentationIds | CatSegmentationIds;

const nutritionSegmentationSet = new Set(
  [
    Object.values(NutritionSegmentationIds),
    Object.values(CatNutritionSegmentationIds),
  ].flat()
);

/**
 * Assert function for NutritionSegmentationIds
 */
export function isNutritionSegmentation(
  segmentation: SegmentationIds
): segmentation is NutritionSegmentationIds | CatNutritionSegmentationIds {
  return nutritionSegmentationSet.has(segmentation as unknown as any);
}

export const CatSegmentationIds = {
  ...AllergySegmentationIds,
  ...CatAgeSegmentationIds,
  ...CatNutritionSegmentationIds,
} as const;

const catSegmentationSet = new Set(Object.values(CatSegmentationIds));

/**
 * Assert function for CatSegmentationIds
 */
export function isCatSegmentation(
  segmentation: SegmentationIds
): segmentation is CatSegmentationIds {
  return catSegmentationSet.has(segmentation as unknown as any);
}

export const DogSegmentationIds = {
  ...AllergySegmentationIds,
  ...DogAgeSegmentationIds,
  ...NutritionSegmentationIds,
  ...BreedSegmentationIds,
} as const;

export const SegmentationIds = {
  ...CatSegmentationIds,
  ...DogSegmentationIds,
} as const;

export type Segmentation = {
  id: SegmentationIds;
  redirectPages: ReadonlyArray<string>;
};

/**
 * @TODO : Data might not be finalized
 */
export const SegmentationData: { segmentation: ReadonlyArray<Segmentation> } = {
  segmentation: [
    {
      id: NutritionSegmentationIds.PickyEater,
      redirectPages: [
        '/collections/fur-hunde-sehr-waehlerisch',
        '/collections/fur-hunde-sehr-waehlerisch-lp',
        '/collections/fur-hunde-sehr-waehlerisch-lp1',
        '/collections/fur-hunde-sehr-waehlerisch-lp2',
        '/collections/fur-hunde-paid-social-leckerbox-50-a',
        '/collections/fur-hunde-paid-social-leckerbox-50-b',
        '/collections/fur-hunde-paid-social-leckerbox-50-a-ch',
      ],
    },
    {
      id: NutritionSegmentationIds.Digestion,
      redirectPages: [
        '/collections/fur-hunde-verdauungsprobleme',
        '/collections/fur-hunde-verdauungsprobleme-lp',
        '/collections/fur-hunde-verdauungsprobleme-lp1',
        '/collections/fur-hunde-verdauungsprobleme-lp2',
        '/collections/fur-hunde-nassfutter-classic-bundle-40',
      ],
    },
    {
      id: NutritionSegmentationIds.Intolerance,
      redirectPages: [
        '/collections/fur-hunde-unvertraeglichkeiten-allergien',
        '/collections/fur-hunde-unvertraeglichkeiten-allergien-lp',
        '/collections/fur-hunde-unvertraeglichkeiten-allergien-lp1',
      ],
    },
    {
      id: NutritionSegmentationIds.SkinFur,
      redirectPages: [
        '/collections/fur-hunde-haut-fellprobleme',
        '/collections/fur-hunde-haut-fellprobleme-lp',
      ],
    },
    {
      id: NutritionSegmentationIds.Overweight,
      redirectPages: ['/collections/fur-hunde-uebergewicht'],
    },
    {
      id: DogAgeSegmentationIds.Welpe,
      redirectPages: [
        '/collections/fur-hunde-welpen-und-junghunde',
        '/collections/fur-hunde-welpen-und-junghunde-lp1',
      ],
    },
    {
      id: DogAgeSegmentationIds.DogAdult,
      redirectPages: ['/collections/fur-hunde-adult'],
    },
    {
      id: DogSegmentationIds.DogSenior,
      redirectPages: ['/collections/fur-hunde-senior'],
    },
    {
      id: NutritionSegmentationIds.Disease,
      redirectPages: ['/collections/fur-hunde-krankheiten'],
    },
    {
      id: NutritionSegmentationIds.Foodaid,
      redirectPages: ['/futterberater'],
    },
    {
      id: BreedSegmentationIds.Bulldog,
      redirectPages: [
        '/collections/fur-hunde-bulldogge',
        '/collections/fur-hunde-bulldogge-lp',
        '/collections/fur-hunde-bulldogge-lp1',
        '/collections/fur-hunde-bulldogge-lp2',
      ],
    },
    {
      id: BreedSegmentationIds.Retriever,
      redirectPages: ['/collections/fur-hunde-retriever'],
    },
    {
      id: BreedSegmentationIds.Dachshund,
      redirectPages: [
        '/collections/fur-hunde-dackel',
        '/collections/fur-hunde-dackel-lp',
        '/collections/fur-hunde-dackel-lp1',
      ],
    },
    {
      id: BreedSegmentationIds.Chihuahua,
      redirectPages: [
        '/collections/fur-hunde-chihuahua',
        '/collections/fur-hunde-chihuahua-lp',
        '/collections/fur-hunde-chihuahua-lp1',
      ],
    },
    {
      id: BreedSegmentationIds.Havanese,
      redirectPages: ['/collections/fur-hunde-malteser'],
    },
    {
      id: BreedSegmentationIds.Poodle,
      redirectPages: ['/collections/fur-hunde-pudel'],
    },

    {
      id: BreedSegmentationIds.ShepherdDog,
      redirectPages: ['/collections/fur-hunde-schaeferhund'],
    },
    {
      id: BreedSegmentationIds.Spitz,
      redirectPages: ['/collections/fur-hunde-spitz'],
    },
    {
      id: BreedSegmentationIds.Terrier,
      redirectPages: [
        '/collections/fur-hunde-terrier',
        '/collections/fur-hunde-terrier-lp',
      ],
    },
    {
      id: BreedSegmentationIds.Herdingdog,
      redirectPages: ['/collections/fur-hunde-huetehunde'],
    },
    {
      id: BreedSegmentationIds.MixedLarge,
      redirectPages: ['/collections/fur-hunde-mischling'],
    },
    {
      id: BreedSegmentationIds.MixedSmall,
      redirectPages: ['/collections/fur-hunde-mischling'],
    },
    {
      id: CatNutritionSegmentationIds.CatPickyEater,
      redirectPages: [
        '/collections/fur-katzen-wahlerisch',
        '/collections/fur-katzen-wahlerisch-lp',
        '/collections/saucen-menu-fur-katzen-bundle-33',
        '/collections/schlemmerpaket-saucen-silky-creams-fur-katzen-33',
        '/collections/saucen-menu-fur-katzen-bundle-33-ch',
      ],
    },
    {
      id: CatNutritionSegmentationIds.CatDigestion,
      redirectPages: ['/collections/fur-katzen-verdauungsprobleme'],
    },
    {
      id: CatNutritionSegmentationIds.CatOverweight,
      redirectPages: ['/collections/fur-katzen-ubergewicht'],
    },
    {
      id: CatNutritionSegmentationIds.Sterilized,
      redirectPages: ['/collections/fur-katzen-sterilisiert'],
    },
    {
      id: CatNutritionSegmentationIds.Outdoor,
      redirectPages: ['/collections/fur-katzen-freiganger'],
    },
    {
      id: CatAgeSegmentationIds.Kitten,
      redirectPages: ['/collections/fur-katzen-kitten-und-junge-katze'],
    },
    {
      id: CatAgeSegmentationIds.CatSenior,
      redirectPages: [
        '/collections/fur-katzen-senior',
        '/collections/fur-katzen-senior-lp',
      ],
    },
    {
      id: CatNutritionSegmentationIds.Noneedcat,
      redirectPages: [
        '/collections/fur-katzen-alle-fleischsorten',
        '/collections/fur-katzen-alle-fleischsorten-lp',
      ],
    },
  ],
} as const;

/**
 * Get segmentation from collection url
 */
export const getSegmentationFromUrl = ({
  url,
}: {
  url: string;
}): Segmentation | undefined => {
  // trim 1. url prams and filter value ([handle]/huhn) 2.empty space
  const trimmedUrl = url.replace(/(\/collections\/[^\/]+)\/.*$/, '$1').trim();
  return SegmentationData.segmentation.find(
    // It needs to remove the filters in the url, in case these are present
    (seg) =>
      seg.redirectPages.some((redirectPage) => redirectPage === trimmedUrl)
  );
};

/**
 * Map of filter to segmentation
 */
export const SEGMENTS_FILTER_MAP: {
  [key in NutritionalNeedsTags]: SegmentationIds;
} = {
  'bestimmte-erkrankung': 'disease',
  'haut-and-fellprobleme': 'skin-fur',
  'sehr-wahlerisch': 'pickyeater',
  'senior-7-jahre': 'senior',
  'unvertraglichkeiten-allergien': 'intolerance',
  verdauungsprobleme: 'digestion',
  welpe: 'welpe',
  ubergewicht: SegmentationIds.Overweight,
} as const;

/**
 * Map of filter tags before slugify to segmentation
 */
export const SEGMENTS_RAW_FILTER_MAP: {
  [key in string]: SegmentationIds | Array<SegmentationIds>;
} = {
  'Bestimmte Erkrankung': SegmentationIds.Disease,
  'Haut & Fellprobleme': SegmentationIds.SkinFur,
  'Sehr wählerisch': [
    SegmentationIds.PickyEater,
    SegmentationIds.CatPickyEater,
  ],
  'Adult (1-6 Jahre)': SegmentationIds.DogAdult,
  'Senior (8+ Jahre)': SegmentationIds.DogSenior,
  'Unverträglichkeiten / Allergien': SegmentationIds.Intolerance,
  adult: [SegmentationIds.DogAdult, SegmentationIds.CatAdult],
  Verdauungsprobleme: [SegmentationIds.Digestion, SegmentationIds.CatDigestion],
  Welpe: SegmentationIds.Welpe,
  übergewicht: SegmentationIds.Overweight,
  bulldog: SegmentationIds.Bulldog,
  chihuahua: SegmentationIds.Chihuahua,
  dackel: SegmentationIds.Dachshund,
  herdingdog: SegmentationIds.Herdingdog,
  maltese: SegmentationIds.Havanese,
  pudel: SegmentationIds.Poodle,
  retriever: SegmentationIds.Retriever,
  shepperd: SegmentationIds.ShepherdDog,
  spitz: SegmentationIds.Spitz,
  terrier: SegmentationIds.Terrier,
  [SegmentationIds.CatPickyEater]: SegmentationIds.CatPickyEater,
  [SegmentationIds.CatDigestion]: SegmentationIds.CatDigestion,
  [SegmentationIds.CatOverweight]: SegmentationIds.CatOverweight,
  [SegmentationIds.Sterilized]: SegmentationIds.Sterilized,
  [SegmentationIds.Outdoor]: SegmentationIds.Outdoor,
  [SegmentationIds.Kitten]: SegmentationIds.Kitten,
  [SegmentationIds.CatSenior]: SegmentationIds.CatSenior,
} as const;

/**
 * Segmentation shopify tags converted for usability for filter logics
 */
export const SEGMENTS_SLUGIFIED_FILTER_MAP = Object.fromEntries(
  Object.entries(SEGMENTS_RAW_FILTER_MAP).map((entry) => {
    return [slugify(entry[0]), entry[1]];
  })
);

/**
 * Check if the url is a segmentation link
 */
export const isSegmentationLink = (url: string): boolean =>
  SegmentationData.segmentation.some((segment) =>
    segment.redirectPages.includes(url)
  );
