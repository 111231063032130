import { sendData } from 'connectors/kinesis';
import cookie from 'cookie';
import useIsClient from 'hooks/common/use-is-client';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { gtm, pageView } from 'tracking';

declare global {
  interface Window {
    __pageViewCache?: Set<string>;
  }
}

const STREAM_ARN =
  'arn:aws:kinesis:eu-central-1:093173765289:stream/pageViewEvent';

const PageViewTrigger: React.FC = () => {
  const router = useRouter();
  const isClient = useIsClient();

  useEffect(() => {
    if (!isClient) return;

    gtm(pageView());

    const minute = Math.floor(Date.now() / 60000);
    const cacheKey = `${router.asPath}-${minute}`;

    // Initialize or get existing cache
    const cache = window.__pageViewCache || new Set<string>();
    window.__pageViewCache = cache;

    if (!cache.has(cacheKey)) {
      const gaSessionId = cookie.parse(document.cookie)._ga;
      const timestamp = new Date().toISOString();
      const randomSuffix = Math.floor(Math.random() * 1000);
      const partitionKey = `${gaSessionId ?? ''}-${timestamp}-${randomSuffix}`;

      const data = {
        gaSessionId,
        pagePath: router.asPath,
        timestamp,
        userAgent: navigator.userAgent,
      };

      sendData({
        data: new Uint8Array(Buffer.from(JSON.stringify(data))),
        partitionKey,
        streamArn: STREAM_ARN,
      });

      cache.add(cacheKey);
    }
  }, [isClient, router.asPath]);

  return null;
};

export default PageViewTrigger;
