import { useProfile } from '@ninetailed/experience.js-next';
import { getAbTests } from 'api/ab-tests';
import { getAllAbTestIds } from 'api/contentful-graphql';
import { useConfig } from 'config';
import { GetAbTestsByIdsQueryQuery } from 'generated/graphql';
import useSetToCookie, { CookieName } from 'hooks/common/use-set-to-cookie';
import React, { useEffect, useMemo, useState } from 'react';
import { useSessionStorage } from 'react-use';
import useSWR from 'swr';

export type TestConfig = NonNullable<
  GetAbTestsByIdsQueryQuery['experimentConfigCollection']
>['items'][0];

interface Context {
  audiencesHeaderStr?: string;
  experimentHeaderStr: string;
  isLoading: boolean;
  deleteHeader: ({ experienceId }: { experienceId: string }) => void;
  getTestEntry: (id: string) => TestConfig | undefined;
  setToHeader: ({
    experienceId,
    variant,
  }: {
    experienceId: string;
    variant: number;
  }) => void;
}

/**
 * the idea is to have one global state for test variants
 * so that we only ever have to create the callback ONCE for each test
 */

const NinetailedAbTestContext = React.createContext({} as Context);

const NinetailedAbTestProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { contentfulEnv, contentfulSpace } = useConfig();
  const { data: abTestIds, isValidating: isLoadingIds } = useSWR(
    'ninetailedAbIds',
    () => getAllAbTestIds({ contentfulEnv, contentfulSpace }),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: abTests, isValidating: isLoadingTests } = useSWR(
    abTestIds ? ['abTests', abTestIds] : null,
    () =>
      abTestIds
        ? getAbTests({ ids: abTestIds, contentfulEnv, contentfulSpace })
        : null,
    {
      revalidateOnFocus: false,
    }
  );

  const getTestEntry = React.useCallback(
    (id: string) => {
      if (!abTestIds || !abTestIds.includes(id) || isLoadingTests || !abTests) {
        return undefined;
      }
      return abTests[id];
    },
    [abTestIds, abTests, isLoadingTests]
  );

  const isLoading = isLoadingIds || isLoadingTests;

  /**
   * Local storage to hold the last experiment results
   */
  const [abTestResultStore, setAbTestResultStore] = useSessionStorage<{
    [id: string]: number;
  }>('pd:experimentStore', {});

  const [abTestResult, setAbTestResult] = useState<{
    [id: string]: number;
  }>(abTestResultStore || {});

  useEffect(() => {
    setAbTestResultStore(abTestResult);
  }, [abTestResult, setAbTestResultStore]);

  /**
   * Set result to communicate with backend
   */
  const setToHeader: Context['setToHeader'] = React.useCallback(
    ({ experienceId, variant }) =>
      setAbTestResult((prev) => ({
        ...prev,
        [experienceId]: variant,
      })),
    [setAbTestResult]
  );
  /**
   * Delete result from header
   */
  const deleteHeader: Context['deleteHeader'] = React.useCallback(
    ({ experienceId }) => {
      setAbTestResult((prev) => {
        if (!prev) {
          return {};
        } else {
          return Object.fromEntries(
            Object.entries(prev).filter((entry) => {
              return entry[0] !== experienceId;
            })
          );
        }
      });
    },
    [setAbTestResult]
  );

  const experimentHeaderStr = useMemo(() => {
    if (!abTestResult) return '';
    return Object.keys(abTestResult)
      .map((key) => `${key}.${abTestResult[key]}`)
      .join(',');
  }, [abTestResult]);
  const { profile } = useProfile();

  const audiencesHeaderStr = useMemo(
    () => profile?.audiences.join(','),
    [profile?.audiences]
  );

  useSetToCookie({
    cookieId: CookieName.Audiences,
    cookieValue: audiencesHeaderStr,
  });

  useSetToCookie({
    cookieId: CookieName.Experiments,
    cookieValue: experimentHeaderStr,
  });

  return (
    <NinetailedAbTestContext.Provider
      value={{
        audiencesHeaderStr,
        experimentHeaderStr,
        isLoading: isLoading,
        deleteHeader,
        getTestEntry,
        setToHeader,
      }}
    >
      {children}
    </NinetailedAbTestContext.Provider>
  );
};

const useNinetailedAbTestContext = (): Context =>
  React.useContext(NinetailedAbTestContext);

export { NinetailedAbTestProvider, useNinetailedAbTestContext };
