import { Entry } from 'contentful';
import { IExperimentConfig, IGlobalData, IGlobalDataFields } from 'global';
import reject from 'lodash/reject';
import getContentfulLocale from 'utils/i18n/get-contentful-locale';
import { contentfulClient } from '../connectors/contentful';

/**
 * Get global contentful data
 */
export const getGlobalContentfulData = async ({
  globalDataEntryId,
  locale,
}: {
  globalDataEntryId: string;
  locale?: string;
}): Promise<IGlobalData> => {
  const contentfulLocale = getContentfulLocale(locale);
  return new Promise((resolve) => {
    contentfulClient
      .getEntry<IGlobalDataFields>(globalDataEntryId, {
        content_type: 'globalData',
        include: 10,
        locale: contentfulLocale,
      })
      .then((entry) => {
        resolve(entry as IGlobalData);
      })
      .catch((error) => reject(error));
  });
};

/**
 * Batch request for A/B test data by IDs
 */
export const getAbTestsByIds = async ({
  baseEntryIds,
}: {
  baseEntryIds: string[];
}): Promise<Entry<IExperimentConfig>[]> => {
  const result = await contentfulClient.getEntries<IExperimentConfig>({
    'sys.id[in]': baseEntryIds.join(','),
    content_type: 'experimentConfig',
    include: 10,
  });

  return result.items;
};
