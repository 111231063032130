import useExperiment from 'hooks/common/use-experiment';
import { useCart } from './cart';

/**
 * Place holder to set up global test.
 * Should be useful because the all important global context is available on this point.
 */
const GlobalTestHolder: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { reFetchEffectiveCart } = useCart();

  /**
   * QA :: FE :: Setup test on /version page to verify tracking behaviour.
   * @SEE : https://petsdeli.atlassian.net/browse/PT-6564
   */
  useExperiment({
    baseEntryId: 'KFgwVfvBRoICtKKFkf51v',
  });

  /**
   * FE: Create a Custom GTM Plugin to Dispatch viewElement Event
   * @SEE : https://petsdeli.atlassian.net/browse/PT-6984
   */
  useExperiment({
    baseEntryId: 'UJ6HNtv3aIUiX1b7fsWut',
  });

  /**
   * AB test: hardcode subscription first order(FO) discount of certain user audience and for certain product
   * @SEE : https://petsdeli.atlassian.net/browse/PT-5471
   */
  useExperiment({
    baseEntryId: '2YR6EsyBVFAKqh7OEANfwE',
    shouldAddToHeader: true,
    // When user is not eligible to A/B test, then we need to recalcitrate cart.
    // NC => EC
    onExperimentChange: async (profile) => {
      // Unfortunately, propagation of profile update -> cookie ->  experience header
      // happens outside of react state management.
      // we need to make sure it ends by timeout is done.
      setTimeout(async () => {
        await reFetchEffectiveCart();
      }, 2000);
    },
  });

  return <>{children}</>;
};

export default GlobalTestHolder;
