import {
  NinetailedAnalyticsPlugin,
  SanitizedElementSeenPayload,
  Template,
} from '@ninetailed/experience.js-plugin-analytics';
import { template } from '@ninetailed/experience.js-shared';
import { TEMPLATE_OPTIONS } from '.';

type NinetailedGoogleTagmanagerPluginOptions = {
  actionTemplate?: string;
  labelTemplate?: string;

  template?: Template;
};

/**
 * Custom Google Tag Manager plugin for Ninetailed
 * Reference: https://github.com/ninetailed-inc/experience.js/blob/main/packages/plugins/google-tagmanager/src/NinetailedGoogleTagmanagerPlugin.ts
 */
interface CachedEvent {
  payload: Record<string, string>;
  attempts: number;
  timestamp: number;
}

interface DebugInfo {
  cacheStatus: {
    size: number;
    events: Array<[string, CachedEvent]>;
  };
  failedEvents: {
    size: number;
    events: Array<[string, CachedEvent]>;
  };
  isGAReady: boolean;
  eventCacheSize: number;
  retryInterval: number;
  maxRetries: number;
}

let gtmPluginInstance: NinetailedGoogleTagmanagerPlugin | null = null;

/**
 * Returns a singleton instance of the Google Tag Manager plugin.
 * This ensures we always work with the same plugin instance across the application,
 * preventing issues with multiple instances tracking events differently or losing
 * cached/failed events during SSR hydration or component re-renders.
 * The singleton pattern is especially important for maintaining consistent event tracking
 * and retry mechanisms for failed GTM events.
 *
 * @returns {NinetailedGoogleTagmanagerPlugin} The singleton GTM plugin instance
 */
export const getGtmPlugin = (): NinetailedGoogleTagmanagerPlugin => {
  if (!gtmPluginInstance) {
    gtmPluginInstance = new NinetailedGoogleTagmanagerPlugin();

    // Expose in debug registry if needed
    if (typeof window !== 'undefined') {
      (window as any).__NT_DEBUG = (window as any).__NT_DEBUG || {};
      (window as any).__NT_DEBUG.pluginRegistry =
        (window as any).__NT_DEBUG.pluginRegistry || new Map();
      (window as any).__NT_DEBUG.pluginRegistry.set('gtm', gtmPluginInstance);
    }
  }
  return gtmPluginInstance;
};

export class NinetailedGoogleTagmanagerPlugin extends NinetailedAnalyticsPlugin {
  public name = 'ninetailed:googleTagmanager';
  private eventCache: Set<string>;
  private retryCache: Map<string, CachedEvent> = new Map();
  private failedEventsCache = new Map<string, CachedEvent>();
  private retryInterval = 2000;
  private maxRetries = 5;
  private retryTimeoutId: NodeJS.Timeout | null = null;

  constructor(
    private readonly options: NinetailedGoogleTagmanagerPluginOptions = {}
  ) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}',
      ninetailed_experience_type: '{{experience.type}}',
      ninetailed_profile_id: '{{profile.id}}',
      ninetailed_stable_id: '{{profile.stableId}}',
    });
    this.eventCache = new Set();

    if (typeof window !== 'undefined') {
      // Expose debug namespace if it doesn't exist
      (window as any).__NT_DEBUG = (window as any).__NT_DEBUG || {};

      // Create a registry if it doesn't exist
      (window as any).__NT_DEBUG.pluginRegistry =
        (window as any).__NT_DEBUG.pluginRegistry || new Map();

      // Register this plugin instance
      (window as any).__NT_DEBUG.pluginRegistry.set('gtm', this);
    }
  }

  // Debug methods for QA
  public getRetryCacheStatus(): {
    size: number;
    events: Array<[string, CachedEvent]>;
  } {
    return {
      size: this.retryCache.size,
      events: Array.from(this.retryCache.entries()),
    };
  }

  // Get GTM plugin instance
  // window.__NT_DEBUG.pluginRegistry.get('gtm')

  // Get cache status
  // window.__NT_DEBUG.pluginRegistry.get('gtm').getDebugInfo()
  public getDebugInfo = (): DebugInfo => ({
    cacheStatus: this.getRetryCacheStatus(),
    failedEvents: {
      size: this.failedEventsCache.size,
      events: Array.from(this.failedEventsCache.entries()),
    },
    isGAReady: this.isGAReady(),
    eventCacheSize: this.eventCache.size,
    retryInterval: this.retryInterval,
    maxRetries: this.maxRetries,
  });
  public initialize = (): void => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
    }
  };

  private generateEventKey = (payload: Record<string, string>): string =>
    `${payload.ninetailed_experience}_${payload.ninetailed_component}`;

  private isGAReady(): boolean {
    return (
      typeof window !== 'undefined' &&
      Array.isArray(window.dataLayer) &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.gtag === 'function' &&
      document.cookie.includes('_ga=')
    );
  }

  private logDebug(...args: any[]): void {
    console.log('[NinetailedGTM]', ...args);
  }

  private pushToDataLayer(
    payload: Record<string, string>,
    eventKey: string
  ): boolean {
    if (!this.isGAReady()) {
      this.logDebug('GA not ready, caching event', eventKey);
      return false;
    }

    try {
      window.dataLayer?.push(payload);
      this.eventCache.add(eventKey);
      this.logDebug('Event pushed successfully', eventKey);
      return true;
    } catch (error) {
      this.logDebug('Error pushing event', error);
      return false;
    }
  }

  private scheduleRetry(): void {
    if (this.retryTimeoutId) return;

    this.retryTimeoutId = setTimeout(async () => {
      this.retryTimeoutId = null;
      await this.processRetryCacheEvents();
    }, this.retryInterval);
  }

  private async processRetryCacheEvents(): Promise<void> {
    this.logDebug('Processing retry cache', {
      size: this.retryCache.size,
      events: Array.from(this.retryCache.entries()),
    });

    for (const [eventKey, cachedEvent] of this.retryCache.entries()) {
      if (cachedEvent.attempts >= this.maxRetries) {
        this.logDebug('Max retries reached for event', eventKey, cachedEvent);

        // Store the failed event before removing it from the retry cache
        this.failedEventsCache.set(eventKey, cachedEvent);
        this.retryCache.delete(eventKey);
        continue;
      }

      const success = this.pushToDataLayer(cachedEvent.payload, eventKey);
      if (success) {
        this.retryCache.delete(eventKey);
      } else {
        this.retryCache.set(eventKey, {
          ...cachedEvent,
          attempts: cachedEvent.attempts + 1,
        });
      }
    }

    if (this.retryCache.size > 0) {
      this.scheduleRetry();
    }
  }

  protected async onTrackExperience(
    properties: SanitizedElementSeenPayload,
    hasSeenExperienceEventPayload: Record<string, string>
  ): Promise<void> {
    const profile = window.ninetailed?.profile;
    // Don't track personalization experiences
    if (
      hasSeenExperienceEventPayload.ninetailed_experience_type ===
      'nt_personalization'
    )
      return;

    // Add profile id and stable id to the payload
    if (profile) {
      hasSeenExperienceEventPayload.ninetailed_profile_id = profile.id;
      hasSeenExperienceEventPayload.ninetailed_stable_id = profile.stableId;
    }

    // In order to avoid duplicate events, we cache the event key
    const eventKey = this.generateEventKey(hasSeenExperienceEventPayload);
    if (this.eventCache.has(eventKey)) return;

    const success = await this.pushToDataLayer(
      hasSeenExperienceEventPayload,
      eventKey
    );
    if (!success) {
      this.retryCache.set(eventKey, {
        payload: hasSeenExperienceEventPayload,
        attempts: 1,
        timestamp: Date.now(),
      });
      this.scheduleRetry();
    }
  }

  protected async onTrackComponent(properties): Promise<void> {
    const { variant, audience, isPersonalized } = properties;

    const action = template(
      this.options.actionTemplate || 'Has Seen Experience',
      { component: variant, audience },
      TEMPLATE_OPTIONS.interpolate
    );

    const label = template(
      this.options.labelTemplate ||
        '{{ baselineOrVariant }}:{{ component.id }}',
      {
        component: variant,
        audience,
        baselineOrVariant: isPersonalized ? 'Variant' : 'Baseline',
      },
      TEMPLATE_OPTIONS.interpolate
    );

    window.dataLayer?.push({
      event: action,
      properties: {
        category: 'Ninetailed',
        label,
        nonInteraction: true,
      },
    });
  }
}
