import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocalStorage, useLockBodyScroll } from 'react-use';
import { useSearchStore } from 'stores/search';
import { gtm, selectElement, unselectElement } from 'tracking';
import { EventName, trackEvent } from 'utils/tracking';
import IconArrowLeft from '../../../assets/icons/arrow-left.svg';
import IconCross from '../../../assets/icons/cross.svg';
import IconLastSearches from '../../../assets/icons/last-searches.svg';
import IconMagnifier from '../../../assets/icons/magnifier.svg';
import { usePetContext } from '../../../contexts/pet';
import useOnClickOutside from '../../../hooks/common/use-on-click-outside';

type searchHistory = {
  searchHistoryContainer?: Array<string>;
};

interface Props {
  setSearchOverlayClose?: (arg0: boolean) => void;
}

/**
 * Search component for the header. It is a controlled component that uses the search store to manage the search term.
 */
const Search: React.FC<Props> = ({ setSearchOverlayClose }) => {
  const router = useRouter();
  const { current } = usePetContext();
  const intl = useIntl();
  const [focused, setFocused] = React.useState(false);
  const [visible, setVisibility] = React.useState(false);
  const { searchedTerm, searchedUrlTerm, setSearchedTerm, setSearchedUrlTerm } =
    useSearchStore();
  const searchRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [searchHistory, setSearchHistory] = useLocalStorage<searchHistory>(
    'pd:search-history',
    undefined
  );

  useEffect(() => {
    if (router.query.term) {
      setSearchedUrlTerm(searchedTerm);
    } else {
      setSearchedUrlTerm('');
    }
  }, [router.query.term, searchedTerm, setSearchedUrlTerm]);

  useLockBodyScroll(focused);

  // Add the updated setInputOpen function here
  const setInputOpen = useCallback(
    (isOpen = true) => {
      setFocused(isOpen);
      setVisibility(isOpen);
      setSearchOverlayClose && setSearchOverlayClose(!isOpen);
      if (
        isOpen &&
        inputRef.current &&
        window.matchMedia('(max-width: 1024px)').matches
      ) {
        inputRef.current.focus();
      }
    },
    [setFocused, setVisibility, setSearchOverlayClose, inputRef]
  );

  useOnClickOutside(searchRef, () => {
    setInputOpen(false);
  });

  const deleteHistory = (): void => {
    if (searchHistory) {
      searchHistory.searchHistoryContainer = [];
      setSearchHistory(searchHistory);
    }
  };

  const addNewSearch = (newSearch: string): void => {
    if (searchHistory?.searchHistoryContainer?.includes(newSearch)) {
      searchHistory.searchHistoryContainer =
        searchHistory.searchHistoryContainer.filter(
          (term) => term !== newSearch
        );
      searchHistory.searchHistoryContainer.unshift(newSearch);
    } else {
      searchHistory?.searchHistoryContainer?.unshift(newSearch);
    }
    setSearchHistory(searchHistory);
  };

  const cleanInputField = (): void => {
    if (inputRef.current) {
      setSearchedUrlTerm('');
      inputRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (!searchHistory) setSearchHistory({ searchHistoryContainer: [] });
  }, [setSearchHistory, searchHistory]);

  React.useEffect(() => {
    if (visible === true) {
      document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => document.body.classList.remove('overflow-hidden');
  }, [visible]);

  React.useEffect(() => {
    setInputOpen(false);
  }, [router.asPath, setInputOpen]);

  const removeFocus = (): void => {
    document.activeElement && (document.activeElement as HTMLElement).blur();
  };

  return (
    <>
      <form
        data-search
        // ↓↓↓ needed to display the 'search' button on the mobile keyboard
        action="."
        role="search"
        data-test="search-form"
        className={cn(
          {
            'bg-other-white lg:bg-primary-main fixed top-0 left-0 p-2 lg:relative lg:h-full lg:w-full lg:p-0':
              focused,
          },
          focused
            ? 'h-screen w-screen'
            : 'my-auto flex h-9 w-full items-center lg:h-full'
        )}
        ref={searchRef}
        onSubmit={(event) => {
          event.preventDefault();
          removeFocus();
          setInputOpen(false);
          setSearchedTerm(searchedUrlTerm);
          addNewSearch(searchedUrlTerm);

          router.push({
            pathname: '/search',
            query: {
              term: searchedUrlTerm.toLowerCase(),
              ...(current !== false && {
                category: current,
              }),
            },
          });
        }}
      >
        <div
          className={cn(
            'z-search relative h-full w-full items-center justify-center'
          )}
        >
          {/* magnifier */}
          <div
            data-test="search-icon-container"
            className={cn(
              focused
                ? 'absolute h-12 bg-transparent'
                : 'lg:bottom-0 lg:left-0',
              'text-grey-700-60 hover:text-grey-700-80 z-search flex h-9 flex-col items-center justify-between leading-none focus:outline-hidden lg:h-12 lg:w-12'
            )}
          >
            <button
              type="button"
              data-test={focused ? 'search-modal-close' : 'search-button'}
              className={cn(
                !focused && 'flex-col justify-center',
                'relative flex h-full w-full items-center justify-center'
              )}
              onClick={() => {
                setInputOpen(!focused);
                focused
                  ? gtm(
                      unselectElement({
                        element: ['Header', 'Search'],
                      })
                    )
                  : gtm(
                      selectElement({
                        element: ['Header', 'Search'],
                      })
                    );
              }}
            >
              {focused ? (
                <>
                  <IconArrowLeft
                    data-test="search-back-icon"
                    strokeWidth="2"
                    className="text-primary-main mt-3 h-6 bg-transparent pr-4 lg:hidden"
                  />
                  <IconMagnifier
                    data-test="search-magnifier-icon-desktop"
                    className="text-text-light-bg-secondary hidden h-6 lg:block"
                  />
                </>
              ) : (
                <>
                  <IconMagnifier
                    data-test="search-magnifier-icon-mobile"
                    className="text-primary-main lg:text-text-light-bg-secondary h-6 shrink-0 lg:h-7"
                  />
                  <span
                    data-test="search-text"
                    className="text-xxs text-primary-main leading-4 font-medium lg:hidden"
                  >
                    <FormattedMessage id="header:search" />
                  </span>
                </>
              )}
            </button>
          </div>

          <div
            className={cn(
              focused ? 'z-modal top-0 py-0' : 'pr-2',
              'flex w-full',
              'pointer-events-none h-full w-full lg:static lg:w-auto lg:p-0'
            )}
            data-test="search-modal"
          >
            {/* search field */}
            <div
              data-test="search-input-container"
              className={cn(
                'absolute top-0 bottom-0 flex w-full lg:items-center',
                !focused && 'items-center',
                !focused && 'h-0 overflow-hidden lg:h-full lg:overflow-auto',
                focused &&
                  'lg:border-primary-main lg:rounded-t-2xl lg:border-r lg:border-l',
                {
                  'bg-other-white':
                    focused &&
                    searchHistory?.searchHistoryContainer?.length !== 0,
                }
              )}
            >
              <input
                type="search"
                required
                ref={inputRef}
                aria-label="Futter suchen"
                data-test="search-input"
                className={cn(
                  'placeholder-grey-300 pointer-events-auto grow lg:h-full',
                  'border-other-white bg-grey-100 lg:bg-other-white h-12 rounded-xl focus:outline-hidden lg:h-10 lg:rounded-lg lg:border',
                  focused
                    ? 'lg:border-primary-main ml-8 rounded-2xl pl-3 lg:ml-0 lg:rounded-2xl lg:border lg:pl-12'
                    : 'rounded-xs pl-12'
                )}
                placeholder={intl.formatMessage({
                  id: 'header:search:placeholder',
                })}
                value={searchedUrlTerm}
                onFocus={() => {
                  trackEvent(EventName.SearchStart);
                  setInputOpen();
                }}
                onClick={() => {
                  setInputOpen();
                }}
                onChange={(event) =>
                  setSearchedUrlTerm(event.target.value as string)
                }
              />
              {focused && searchedUrlTerm.length > 0 && (
                <button
                  type="button"
                  data-test="search-input-clear"
                  onClick={() => {
                    cleanInputField();
                  }}
                  className="pointer-events-auto absolute right-2 z-20 mt-2 flex h-9 w-9 items-center justify-center bg-transparent lg:top-auto lg:bottom-auto"
                >
                  <IconCross
                    data-test="search-clear-icon"
                    className="text-primary-main w-3 lg:w-5"
                  />
                </button>
              )}
            </div>
            {focused && (
              <div
                data-test="search-history"
                className={cn(
                  {
                    'lg:hidden':
                      searchHistory?.searchHistoryContainer?.length === 0,
                  },
                  'z-modal bg-other-white lg:border-primary-main pointer-events-auto absolute top-14 left-0 w-full overflow-auto p-4 lg:top-10 lg:mt-2 lg:h-auto lg:rounded-b-2xl lg:border lg:border-t-0'
                )}
              >
                <div
                  data-test="search-history-header"
                  className={cn(
                    {
                      hidden:
                        searchHistory?.searchHistoryContainer?.length === 0,
                    },
                    'mt-2 flex items-center justify-between font-black'
                  )}
                >
                  <p
                    data-test="search-history-title"
                    className="text-text-light-bg-secondary text-lg lg:text-base"
                  >
                    <FormattedMessage id="header:search:last-queries" />
                  </p>
                  <button
                    type="button"
                    data-test="search-history-delete"
                    className="text-grey-950 text-sm font-black lg:text-xs"
                    onClick={() => {
                      deleteHistory();
                    }}
                  >
                    <FormattedMessage id="header:search:delete" />
                  </button>
                </div>

                {searchHistory !== null && (
                  <div
                    data-test="search-history-items-container"
                    className="mt-2"
                  >
                    {searchHistory?.searchHistoryContainer
                      ?.slice(0, 8)
                      .map((term: string) => {
                        return (
                          <Link
                            href={`/search?term=${term.toLowerCase()}&category=${
                              current === false ? 'both' : current
                            }`}
                            key={term}
                            onClick={() => {
                              setInputOpen(false);
                              setSearchedTerm(term);
                              setSearchedUrlTerm(term);
                            }}
                          >
                            <div
                              data-test="search-history-item"
                              className="text-grey-950 mb-1 flex"
                            >
                              <div>
                                <IconLastSearches
                                  data-test="search-history-icon"
                                  className="mr-3 w-5 lg:mt-1 lg:w-4"
                                />
                              </div>
                              <p
                                data-test="search-history-item-text"
                                className="text-base"
                              >
                                {term}
                              </p>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
      {focused && (
        <div
          data-test="search-overlay"
          className={cn(
            'z-search-backdrop bg-other-backdrop-overlay fixed top-0 left-0 hidden h-screen w-screen lg:block'
          )}
        ></div>
      )}
    </>
  );
};

export default Search;
