import { CommonLinks } from 'config/types';

export default {
  aboutUs: 'uber-uns',
  dataProtection: 'datenschutz',
  foodAward: 'futtertester-nassfutter-fur-hunde',
  foodTypesCats: 'die-verschiedenen-futterungsarten-fur-katzen',
  foodTypesDogs: 'die-verschiedenen-futterungsarten-fur-hunde',
  referFriend: 'freund-geworben',
  subscription: 'regelmassige-lieferung',
  termsAndConditions: 'agb-und-widerruf',
} as CommonLinks;
