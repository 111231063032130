import IconChevronRight from 'assets/icons/chevron-right-stroke.svg';
import DefaultDogProfileIcon from 'assets/icons/default-dog-profile-icon.svg';
import { usePetProfile } from 'contexts/pet-profile';
import { isFoodType } from 'interfaces/api-input/input-product-type';
import getPetProfileArray from 'modules/pet-profile/personalized-cdp/utils/get-pet-profile-array';
import isPetProfileEmpty from 'modules/pet-profile/personalized-cdp/utils/is-pet-profile-empty';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import { BREED_IMAGE_MAP } from '../constants';

interface Props {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Mobile navigation link for pet profile
 */
const PetProfileMobileNavigationLink: React.FC<Props> = ({ setVisibility }) => {
  const { petProfile } = usePetProfile();

  const handlePetProfileClick = (): void => {
    setVisibility(false);
    gtm(
      selectElement({
        element: ['Navigation', 'Hunde', 'Pet Profile'],
      })
    );
  };

  /**
   * Show edit modal when user has less than 2 input
   */
  const href = useMemo(() => {
    const array = getPetProfileArray({ petProfile }).filter(
      (v) => !isFoodType(v)
    );
    let url = '/pet-profile';
    if (array.length <= 1) {
      url = url + '?edit=1';
    }
    return url;
  }, [petProfile]);

  return (
    <Link
      href={href}
      className="border-grey-300 relative flex items-center gap-1.5 border-b px-4 py-3 leading-none"
      onClick={() => handlePetProfileClick()}
    >
      {petProfile?.selectedBreed ? (
        <span className="h-8 shrink-0 overflow-hidden rounded-full">
          <Image
            src={BREED_IMAGE_MAP[petProfile.selectedBreed]}
            alt={`Breed ${petProfile.selectedBreed}`}
            width={32}
            height={32}
          />
        </span>
      ) : (
        <DefaultDogProfileIcon className="h-8" />
      )}
      <span className="text-primary-main text-sm leading-4 font-black">
        <FormattedMessage
          id={
            isPetProfileEmpty({ petProfile })
              ? 'common:pet-profile:create-new-profile'
              : 'common:pet-profile:your-pet'
          }
        />
      </span>
      <span className="bg-secondary-main text-text-dark-bg-contrast-white rounded-sm px-1.5 py-0.5 text-xs leading-[18px]">
        <FormattedMessage id="header:pet-profile:badge" />
      </span>
      <IconChevronRight className="text-primary-main ml-auto h-8 w-6" />
    </Link>
  );
};

export default PetProfileMobileNavigationLink;
