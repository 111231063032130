import { Reference } from '@ninetailed/experience.js';
import {
  AudienceEntryLike,
  AudienceMapper,
  BaselineWithExperiencesEntry,
  Entry,
  ExperienceEntryLike,
  ExperienceMapper,
} from '@ninetailed/experience.js-utils-contentful';
import { contentfulClient } from 'connectors/contentful';
import { isTraits, isVariantRef } from './assert-functions';
import { getGtmPlugin } from './gtm-plugin';
import { InternalTrackingPlugin } from './internal-tracking-plugin';

export * from './assert-functions';
export * from './gtm-plugin';
export * from './internal-tracking-plugin';
export * from './test-gtm-plugin';

type SingularBlock = Entry | BaselineWithExperiencesEntry;

export type MyVariant<Fields = unknown> = Omit<
  Fields,
  'nt_variants' | 'nt_audience' | 'nt_experiences'
> &
  Reference;

export const TEMPLATE_OPTIONS = {
  interpolate: /{{([\s\S]+?)}}/g,
};

const hasExperiences = (
  entry: SingularBlock
): entry is BaselineWithExperiencesEntry => {
  return (
    (entry as BaselineWithExperiencesEntry).fields.nt_experiences !== undefined
  );
};

/**
 * @TODO : add comment
 */
export const parseExperiences = ({ entry }: { entry?: SingularBlock }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _hasEx = hasExperiences(entry);

    return _hasEx
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        entry.fields.nt_experiences
          .filter((experience) =>
            ExperienceMapper.isExperienceEntry(experience)
          )
          .map((experience) =>
            ExperienceMapper.mapCustomExperience(experience, (variant) => ({
              ...variant.fields,
              id: variant.sys.id,
            }))
          )
      : [];
  } catch (error) {
    return [];
  }
};

export const getAllExperiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_experience',
  });
  return (entries.items as ExperienceEntryLike[])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience);
};

export const getAllAudiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_audience',
  });
  return (entries.items as AudienceEntryLike[])
    .filter(AudienceMapper.isAudienceEntry)
    .map(AudienceMapper.mapAudience);
};

export { InternalTrackingPlugin, getGtmPlugin, isTraits, isVariantRef };
