import IconAmex from 'assets/icons/amex.svg';
import IconApplePay from 'assets/icons/apple-pay.svg';
import IconKlarna from 'assets/icons/klarna.svg';
import IconMastercard from 'assets/icons/mastercard.svg';
import IconPaypal from 'assets/icons/paypal.svg';
import IconTwint from 'assets/icons/twint.svg';
import IconVisa from 'assets/icons/visa.svg';
import cn from 'classnames';
import { useConfig } from 'config';
import { ShopId } from 'constants/shop';

interface PaymentMethodIconsProps {
  className?: string;
}

const PaymentMethodIcons: React.FC<PaymentMethodIconsProps> = ({
  className,
}) => {
  const { shopId } = useConfig();
  return (
    <div className={cn('flex items-center justify-center gap-2', className)}>
      <IconPaypal className="w-8 lg:w-10" />
      <IconVisa className="w-8 lg:w-10" />
      <IconMastercard className="w-8 lg:w-10" />
      <IconAmex className="w-8 lg:w-10" />
      {window.ApplePaySession && <IconApplePay className="w-8 lg:w-10" />}
      {shopId === ShopId.DE && <IconKlarna className="w-8 lg:w-10" />}
      {shopId === ShopId.CH && <IconTwint className="w-8 lg:w-10" />}
    </div>
  );
};

export default PaymentMethodIcons;
