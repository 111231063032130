import { useConfig } from 'config';
import React from 'react';
import { FormattedNumber } from 'react-intl';

interface PropTypes {
  value: number;
  cents?: boolean;
  /** If true, decimal is omitted  e.g €20,00 -> €20 */
  hideDecimal?: boolean;
}

const Price: React.FC<PropTypes> = ({ value, cents, hideDecimal }) => {
  const { currencyCode } = useConfig();
  const price = cents ? value / 100 : value;

  return (
    <FormattedNumber
      style="currency"
      currency={currencyCode}
      value={price}
      {...(hideDecimal
        ? {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        : {})}
    />
  );
};

export default Price;
