export const SubscriptionInterval = {
  TWO_WEEKS: '2week',
  FOUR_WEEKS: '4week',
  SIX_WEEKS: '6week',
  EIGHT_WEEKS: '8week',
} as const;

export type SubscriptionInterval =
  (typeof SubscriptionInterval)[keyof typeof SubscriptionInterval];

export const isSubscriptionInterval = (
  value: unknown
): value is SubscriptionInterval => {
  return (
    typeof value === 'string' &&
    Object.values(SubscriptionInterval).includes(value as SubscriptionInterval)
  );
};

export enum OrderType {
  SUBSCRIPTION = 'subscription',
  ONE_OFF = 'one-off',
}

export enum LoyaltyProgram {
  POINT_CONVERSION_RATE = 1,
}

export const IntervalOptions = [
  {
    translationKey: 'pdp:buy-panel:subscription-intervals:2-weeks',
    translationKeyCdp: 'cdp:subscription-intervals:2-weeks',
    value: SubscriptionInterval.TWO_WEEKS,
    daysCount: 14,
  },
  {
    translationKey: 'pdp:buy-panel:subscription-intervals:4-weeks',
    translationKeyCdp: 'cdp:subscription-intervals:4-weeks',
    value: SubscriptionInterval.FOUR_WEEKS,
    daysCount: 28,
  },
  {
    translationKey: 'pdp:buy-panel:subscription-intervals:6-weeks',
    translationKeyCdp: 'cdp:subscription-intervals:6-weeks',
    value: SubscriptionInterval.SIX_WEEKS,
    daysCount: 42,
  },
  {
    translationKey: 'pdp:buy-panel:subscription-intervals:8-weeks',
    translationKeyCdp: 'cdp:subscription-intervals:8-weeks',
    value: SubscriptionInterval.EIGHT_WEEKS,
    daysCount: 56,
  },
];

export const getIntervalTranslationKey = (
  interval: SubscriptionInterval
): string | null => {
  const option = IntervalOptions.find(({ value }) => value === interval);
  return option ? option.translationKey : null;
};
/**
 * Returns the translation string in the format used on the CDP and in the cart, as the value received is compatible with this key.
 * @param {SubscriptionInterval} interval
 * @returns {string|null} The translation key for the interval or null if not found.
 */
export const getIntervalTranslationKeyShort = (
  interval: SubscriptionInterval
): string | null => {
  const option = IntervalOptions.find(({ value }) => value === interval);
  return option ? option.translationKeyCdp : null;
};
