// # fetch only experiment config connected to experience
export const getAllAbTestIdsQuery = /* GraphQL */ `
  query getAllAbTestIds {
    experimentConfigCollection(
      where: { nt_experiencesCollection_exists: true, isControlVariant: true }
    ) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
