import IconCheckmark from 'assets/icons/checkmark-action-done.svg';
import cn from 'classnames';
import Link from 'next/link';
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    forwardRef,
} from 'react';
import { useIntl } from 'react-intl';
import IconAnimatedSpinner from '../../assets/icons/animated-spinner.svg';

export type Layout = 'contained' | 'outline' | 'outline-dark' | 'text';

export type ButtonProps = (
  | React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  | React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >
) & {
  actionDone?: boolean;
  as?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  href?: string;
  iconLeft?: boolean;
  iconRight?: boolean;
  layout?: Layout;
  loading?: boolean;
  size: 'xs' | 'base' | 'large';
  styleDisabled?: boolean;
  target?: '_blank' | '_top' | '_self';
  translationValues?: Record<
    string,
    | string
    | number
    | boolean
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | Iterable<ReactNode>
    | undefined
  >;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

const LayoutClassNames: {
  [key in Layout]: {
    actionDone?: string;
    default: string;
    disabled?: string;
    focus?: string;
  };
} = {
  contained: {
    actionDone:
      'bg-success-main text-success-contrast lg:hover:bg-success-main',
    default:
      'bg-secondary-main rounded-full text-secondary-contrast lg:hover:bg-secondary-contained-hover-background',
    disabled:
      'bg-action-disabled-background text-action-disabled lg:bg-action-disabled-background lg:hover:text-text-action-disabled ',
    focus: 'border-[3px] border-error-outlined-resting-border',
  },
  outline: {
    actionDone:
      'border border-success-outlined-resting-border text-success-main lg:hover:bg-transparent lg:hover:text-success-main',
    default:
      'border border-secondary-outlined-resting-border text-secondary-main lg:hover:bg-secondary-outlined-hover-background lg:hover:border-secondary-outlined-resting-border lg:hover:text-secondary-main',
    disabled: 'border-action-disabled-background text-action-disabled border',
    focus: 'border-2',
  },
  'outline-dark': {
    default:
      'border border-other-white/40 text-text-dark-bg-contrast-other-white lg:hover:bg-other-black/5',
    focus: 'border-2 border-other-white/60',
  },
  text: {
    actionDone: 'text-success-main',
    default:
      'text-secondary-main lg:hover:text-secondary-contained-hover-background',
    disabled: 'text-action-disabled',
  },
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      actionDone,
      as,
      children,
      className,
      disabled,
      focus,
      href,
      iconLeft,
      iconRight,
      layout = 'contained',
      loading,
      size,
      styleDisabled,
      target = '_self',
      translationValues,
      type,
      onClick,
      ...rest
    } = props;
    const intl = useIntl();

    const icon = actionDone && (
      <IconCheckmark
        className={cn(
          'h-auto',
          size === 'xs' && 'w-3',
          size === 'base' && 'w-3.5',
          size === 'large' && 'w-4'
        )}
      />
    );

    const buttonClassName = cn(
      'focus-default inline-flex items-center justify-center leading-none text-sm transition-colors duration-700 ease-in-out w-full font-medium cursor-pointer disabled:cursor-default',
      layout === 'text' ? 'p-0 rounded-none' : 'px-3 py-2 rounded-full ',
      !actionDone && !styleDisabled && LayoutClassNames[layout].default,
      focus && LayoutClassNames[layout].focus,
      styleDisabled && LayoutClassNames[layout].disabled,
      actionDone && LayoutClassNames[layout].actionDone,
      size === 'xs' && 'text-xs h-[30px] leading-[18px]',
      size === 'base' && 'h-9 text-sm leading-[21px]',
      size === 'large' && 'h-[42px] text-base leading-normal',
      className
    );

    const buttonChildren =
      typeof children === 'string'
        ? intl.formatMessage({ id: children }, translationValues)
        : children;

    // Return a link element when href is set
    if (href) {
      return (
        (<Link
          as={as}
          href={href}
          passHref
          onClick={onClick}
          className={buttonClassName}
          target={target}
          {...(rest as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          ref={ref as React.RefObject<HTMLAnchorElement>}>

          <span className="block">{buttonChildren}</span>

        </Link>)
      );
    }

    return (
      <button
        className={buttonClassName}
        disabled={disabled}
        onClick={onClick}
        type={type}
        {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        ref={ref as React.RefObject<HTMLButtonElement>}
      >
        {loading ? (
          <IconAnimatedSpinner className="h-auto w-5" />
        ) : (
          <div className="flex items-center justify-end gap-2 leading-none">
            {iconLeft && icon}
            {buttonChildren}
            {iconRight && icon}
          </div>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
