import IconClose from 'assets/icons/cross.svg';
import cn from 'classnames';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import { AnimatePresence, m } from 'framer-motion';
import useMobile from 'hooks/common/use-mobile';
import useOnClickOutside from 'hooks/common/use-on-click-outside';
import Image from "next/legacy/image";
import React from 'react';
import ReactDOM from 'react-dom';
import { useSwipeable } from 'react-swipeable';
import { useDom } from '../../contexts/dom';

interface Props {
  backdropClassName?: string;
  containerClassName?: string;
  containerBackgroundColor?: string;
  contentClassName?: string;
  divider?: boolean;
  headerClassName?: string;
  imageUrl?: string;
  open: boolean;
  swipeConfig?: {
    swipeDuration?: number;
    preventScrollOnSwipe?: boolean;
    delta?: number;
  };
  swipeToClose?: boolean;
  title?: React.ReactNode;
  titleClassName?: string;
  titleSvg?: React.ReactNode;
  onClose: () => void;
}

const ModalSlideUp: React.FC<React.PropsWithChildren<Props>> = ({
  backdropClassName = 'bg-primary-main/80',
  children,
  containerClassName,
  containerBackgroundColor = 'bg-other-white',
  contentClassName,
  divider,
  headerClassName,
  imageUrl,
  open,
  title,
  titleClassName,
  titleSvg,
  swipeConfig = {
    swipeDuration: 500,
    preventScrollOnSwipe: true,
  },
  swipeToClose = false,
  onClose,
}) => {
  const $content = React.useRef<HTMLDivElement>(null);
  const { modalWindowMountPointRef } = useDom();
  const isMobile = useMobile();

  React.useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  const handlers = useSwipeable({
    onSwipedDown: () => {
      if (isMobile && swipeToClose) onClose();
    },
    ...swipeConfig,
  });

  useOnClickOutside($content, onClose);
  if (!modalWindowMountPointRef.current) {
    return null;
  }
  const modal = (
    <LazyMotionWrapper>
      <AnimatePresence initial={false}>
        {open && (
          <m.div
            key="modalContainer"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.25,
              },
            }}
            className="z-modal fixed inset-0 mt-6 flex h-full w-full items-end justify-center lg:items-center lg:pb-10"
            onClick={(e) => e.stopPropagation()}
            {...handlers}
          >
            <m.div
              ref={$content}
              key="modalContent"
              initial={{ y: '100vh' }}
              animate={{ y: 0 }}
              exit={{ y: '100vh', transition: { delay: 0 } }}
              transition={{ delay: 0.1, duration: 0.5, ease: 'easeOut' }}
              className={cn(
                'w-full rounded-t-lg pb-8 lg:max-w-2xl lg:rounded-lg lg:pb-3',
                containerClassName,
                containerBackgroundColor
              )}
            >
              <div className={cn('flex justify-between p-3', headerClassName)}>
                <div className="flex">
                  {imageUrl && (
                    <Image
                      width={55}
                      height={55}
                      src={imageUrl}
                      alt={imageUrl}
                    />
                  )}
                  {title && (
                    <div className="mt-2 flex items-center">
                      {titleSvg}
                      <div
                        className={cn(
                          'text-primary-main pt-1 text-xl leading-tight font-black',
                          titleClassName
                        )}
                      >
                        {title}
                      </div>
                    </div>
                  )}
                </div>

                <button
                  className="focus-default mt-3 mr-3 lg:mt-6 lg:mr-6"
                  onClick={onClose}
                >
                  <IconClose className="text-brand-blue-800 h-4 w-4 lg:h-5 lg:w-5" />
                </button>
              </div>

              {divider && <div className="bg-grey-200 h-px w-full" />}

              <div className={cn('px-3', contentClassName)}>{children}</div>
            </m.div>
          </m.div>
        )}

        {open && (
          <m.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: { delay: 0.25 },
            }}
            className={cn(
              'z-backdrop bg-other-black/50 fixed inset-0',
              backdropClassName
            )}
          />
        )}
      </AnimatePresence>
    </LazyMotionWrapper>
  );
  return ReactDOM.createPortal(modal, modalWindowMountPointRef.current);
};

export default ModalSlideUp;
