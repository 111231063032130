import { CouponSettingResponse, getCouponCodesSettings } from 'api/coupon';
import { useConfig } from 'config';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import GenericDiscountModalPopUp from '../generic-discount-modal-pop-up';

type CouponModalPopUpProps = {
  couponCode: string;
};

/**
 * Used to improve the communication coupon with a modal pop up displaying coupon details after fetching API
 */
const CouponModalPopUp: React.FC<CouponModalPopUpProps> = ({ couponCode }) => {
  const { currencyCode } = useConfig();

  const [couponSettings, setCouponSettings] = useState<
    CouponSettingResponse | undefined
  >();
  const [isOpen, setIsOpen] = useState(false);
  const { formatNumber } = useIntl();

  useEffect(() => {
    try {
      const fetchData = async (): Promise<void> => {
        const cs = await getCouponCodesSettings(couponCode);
        setCouponSettings(cs);
      };
      fetchData();
    } catch (error) {
      console.error(`Error while fetching data: ${error}`);
    }
  }, [couponCode]);

  useEffect(() => {
    setIsOpen(!!couponSettings);
  }, [couponSettings]);

  const handleClose = (): void => setIsOpen(false);

  const discountUsps = useMemo(() => {
    const _discountUsps: Array<React.ReactNode> = [];

    if (couponSettings && couponSettings.minimumBasketValue) {
      _discountUsps.push(
        <>
          {couponSettings.minimumBasketValue && (
            <FormattedMessage
              id="cart:coupon-modal-pop-up:minimum-order-value"
              values={{
                miniminumOrderValue: couponSettings.minimumBasketValue,
              }}
            />
          )}
        </>
      );
    }
    if (couponSettings && couponSettings.comments) {
      _discountUsps.push(
        <>{couponSettings.comments && <>{couponSettings.comments}</>}</>
      );
    }
    return _discountUsps;
  }, [couponSettings]);

  if (!couponSettings) return null;

  return (
    <GenericDiscountModalPopUp
      impressionTrackingConfig={{
        trackingGroupName: 'pt-4601',
        trackingLabel: 'dynamic-coupon-popup',
      }}
      onRequestClose={handleClose}
      isOpen={isOpen}
      titleText={
        <FormattedMessage
          id="cart:coupon-modal-pop-up:successfully-applied"
          values={{
            p: (content) => (
              <p className="text-xl font-black text-text-primary">{content}</p>
            ),
            strong: (content) => <strong>{content}</strong>,
            couponCode: couponCode,
          }}
        />
      }
      discountValue={
        <>
          {couponSettings.percentage ? (
            <>{couponSettings.percentage * 100}%</>
          ) : (
            <>
              {formatNumber(couponSettings.absoluteValue, {
                style: 'currency',
                currency: currencyCode,
                // Omit decimal
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </>
          )}
        </>
      }
      discountHelpText={<FormattedMessage id="common:price:discount" />}
      discountUsps={discountUsps}
    />
  );
};

export default CouponModalPopUp;
