import { getAllRedirectionTestIds } from 'api/contentful-graphql';
import RedirectionTestWrapper from 'components/redirection-test-wrapper';
import { useConfig } from 'config';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
// import PawLoader from '../paw-loader';

/**
 * Fetch redirection tests and render component which attaches route redirections
 * Test url : https://dev.petsdeli.de:3000/collections/fur-hunde-trockenfutter?exp=3
 */
const RedirectComponent: React.FC = () => {
  const { contentfulEnv, contentfulSpace } = useConfig();
  const { data: redirectionTestIds, isValidating } = useSWR(
    'getAllRedirectionTestIds',
    () => getAllRedirectionTestIds({ contentfulEnv, contentfulSpace }),
    {
      revalidateOnFocus: false,
    }
  );

  const [_history, setHistory] = useState<Array<string>>([]);

  const { isReady, asPath } = useRouter();

  useEffect(() => {
    if (isReady) {
      setHistory((prev) => [asPath, ...prev]);
    }
  }, [isReady, asPath]);

  return (
    <>
      {/* Redirection hooks inside RedirectionTestWrapper is unmounted when redirection occurs, which means the condition of _history.length < 2 is met  */}
      {isReady &&
        _history.length < 2 &&
        !isValidating &&
        redirectionTestIds &&
        redirectionTestIds.map((id) => (
          <RedirectionTestWrapper key={id} testId={id} currentPath={asPath} />
        ))}

      {/* Would be nice if we could show loading screen but _history.length < 2 is not good enough when no redirection condition is met  */}
      {/* {_history.length < 2 && (
        <div className="flex items-center justify-center min-h-screen bg-primary-main">
          <PawLoader />
        </div>
      )} */}
    </>
  );
};

export default RedirectComponent;
