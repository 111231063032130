import cn from 'classnames';
import Link from 'next/link';
import React from 'react';
import { PetContextValues, usePetContext } from '../../../../contexts/pet';

interface IPrimaryLinkProps {
  petContextAware: PetContextValues | undefined;
  href: string;
  onClick: () => void;
}

const PrimaryLink: React.FC<React.PropsWithChildren<IPrimaryLinkProps>> = ({
  children,
  petContextAware,
  href,
  onClick,
}) => {
  const { current } = usePetContext();
  return (
    (<Link
      href={href}
      passHref
      data-test={`nav-${current}-${children}`}
      className={cn(
        'flex h-14 w-28 items-center justify-center self-end rounded-t-lg border-r border-primary-main px-4 pt-1 text-lg font-black last:border-r-0',
        petContextAware === current
          ? 'bg-other-white text-primary-main'
          : 'text-text-dark-bg-contrast-white'
      )}
      onClick={() => {
        onClick();
      }}>

      {children}

    </Link>)
  );
};

export default PrimaryLink;
