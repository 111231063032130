import { PdEnv } from 'constants/pd-env';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import productionCH from './de-CH/production';
import stagingCH from './de-CH/staging';
import commonCategoriesDE from './de/common-categories';
import commonCollectionsDE from './de/common-collections';
import commonLinksDE from './de/common-links';
import productionDE from './de/production';
import stagingDE from './de/staging';
import { Config, ShopEnvironment, ShopSettings } from './types';

export const config: Config = {
  branchName: process.env.BRANCH_NAME,
  commitHash: process.env.COMMIT_HASH,
  disableSentry: process.env.DISABLE_SENTRY,
  isLive: process.env.IS_LIVE,
  // Supported locales for magzaine sitemap (e.g. Shops where we have the magazine)
  magazineSitemapLocales: ['de', 'de-CH'],
  nodeEnv: process.env.NODE_ENV,
  pdEnv: process.env.PD_ENV,
  pdexEndpoint:
    process.env.PD_ENV === 'production'
      ? 'https://merchant.petsdeli.de'
      : 'https://qa.851725625427.petsdeli.de',
  sentryUrl: 'https://1f4bffcbbc934ebea3a7cc168fa6420b@sentry.io/5168631',

  ['de']: {
    production: productionDE,
    staging: stagingDE,

    calendlyUrl:
      'https://calendly.com/futterberatungpetsdeli/telefonische-futterberatung-von-pets-deli',
    commonCategories: commonCategoriesDE,
    commonCollections: commonCollectionsDE,
    commonLinks: commonLinksDE,
    customerHotline: '+4930220564405',
    globalDataEntryId: 'wtYOE2aGXQEDbBtU64htw',
    helpUrl: 'https://help.petsdeli.de/hc/de/requests/new',
    judgeMePublicToken: 'H_wgWW_5Y6On2s2rCScBCJy-OVA',
    magazineBasePath: 'magazin',
    minimumBasketValue: 2000,
    minimumBasketValueFixedCoupon: 100,
    minimumSubscriptionValue: 2000,
    referalCouponAmountFriend: 10,
    referalCouponAmountSelf: 40,
    sovendusTrafficMediumNumber: '4',
    sovendusTrafficSourceNumber: '2294',
    subscriptionDiscount: 10,
    whatsappNumber: '493033083819',
  },
  ['de-CH']: {
    production: productionCH,
    staging: stagingCH,

    // Switzerland has the same paths
    calendlyUrl:
      'https://calendly.com/futterberatungpetsdeli/telefonische-futterberatung-von-pets-deli',
    commonCategories: commonCategoriesDE,
    commonCollections: commonCollectionsDE,
    commonLinks: commonLinksDE,
    customerHotline: '+41445051160',
    globalDataEntryId: '6Xmpgdxp9s2BLHernpdhNq',
    helpUrl: 'https://help.petsdeli.de/hc/de-ch/requests/new',
    judgeMePublicToken: 'wIwdlKMf8ApcnQvLZEODAPYnsVQ',
    magazineBasePath: 'magazin',
    minimumBasketValue: 3000,
    minimumBasketValueFixedCoupon: 100,
    minimumSubscriptionValue: 3000,
    referalCouponAmountFriend: 10,
    referalCouponAmountSelf: 40,
    sovendusTrafficMediumNumber: '1',
    sovendusTrafficSourceNumber: '7806',
    subscriptionDiscount: 10,
    whatsappNumber: '493033083819',
  },
};

type LocaleConfig = ShopSettings & ShopEnvironment;

const getLocaleConfig = (locale?: string): LocaleConfig => {
  const currentLocale = locale && config[locale] ? locale : 'de';

  return {
    ...config[currentLocale][process.env.PD_ENV],
    ...omit(config[currentLocale], [PdEnv.PRODUCTION, PdEnv.STAGING]),
  };
};

const useConfig = (): LocaleConfig => {
  const { locale } = useRouter();

  return getLocaleConfig(locale);
};

export { getLocaleConfig, useConfig };
export default config;
