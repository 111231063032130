import IconChevronDown from 'assets/icons/chevron-down-stroke.svg';
import cn from 'classnames';
import { isSegmentationLink } from 'constants/segmentation';
import { usePetContext } from 'contexts/pet';
import { useTrackingContext } from 'contexts/tracking';
import { NestedEntryNavigationItem } from 'interfaces/navigation-data';
import React, { useEffect, useState } from 'react';
import Link from '../link';

const DROPDOWN_TRACK_DELAY = 800;

const Dropdown: React.FC<
  Pick<NestedEntryNavigationItem, 'title' | 'items'> & {
    isNew?: boolean;
    onClick?: () => void;
    onItemClick?: (title: string) => void;
    setDropShadow: (arg: boolean) => any;
  }
> = ({
  isNew = false,
  items,
  title,
  onClick,
  onItemClick,
  setDropShadow = () => null,
}) => {
  const { current } = usePetContext();
  const [active, setActive] = useState(false);
  const { trackHotjarEvent } = useTrackingContext();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (active && onClick)
      timer = setTimeout(() => onClick(), DROPDOWN_TRACK_DELAY);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [active, onClick]);

  return (
    <div
      className="relative flex px-2"
      onMouseEnter={() => {
        setActive(true);
        setDropShadow(true);
      }}
      onMouseLeave={() => {
        setActive(false);
        setDropShadow(false);
      }}
    >
      <button
        data-test={`nav-${current}-${title}`}
        className="flex items-center text-sm font-medium text-primary-main hover:text-other-black focus:outline-hidden"
        onClick={onClick}
      >
        {title}
        <IconChevronDown className="w-6 text-primary-main" />
        {isNew && (
          <span className="absolute left-6 top-0 h-1 w-1 rounded-full bg-blue-neu" />
        )}
      </button>
      <div
        className={cn(
          active ? 'block' : 'hidden',
          'absolute left-0 top-full z-50 w-105  transform rounded-b-md border-2 border-other-white bg-other-white'
        )}
      >
        {items && (
          <div data-test={`nav-${current}-${title}-dropdown`} className="z-50 mt-4 w-full bg-other-white">
            {items.map((navItem) => {
              if (navItem.itemType === 'card' || navItem.itemType === 'entry') {
                return (
                  <Link
                    item={navItem}
                    key={navItem.title}
                    className="z-50 my-2 block px-4 py-1 text-sm font-medium text-primary-main hover:bg-grey-100 hover:text-grey-700"
                    onClick={() => {
                      isSegmentationLink(navItem.url) && trackHotjarEvent();
                      onItemClick?.(String(navItem.title));
                    }}
                  >
                    {navItem.title}
                  </Link>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
