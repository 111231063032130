import { noop } from 'lodash';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import * as translations from '../translations';

const TranslationsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { locale } = useRouter();

  const translationConfig = useMemo(() => {
    switch (locale) {
      case 'de-CH':
        return {
          locale: 'de-ch',
          messages: translations.ch,
        };
      case 'de':
      default:
        return {
          locale: 'DE',
          messages: translations.de,
        };
    }
  }, [locale]);

  return (
    <IntlProvider {...translationConfig} onError={noop}>
      {children}
    </IntlProvider>
  );
};

export default TranslationsProvider;
