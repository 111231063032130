import { SubscriptionInterval } from 'constants/order';

export interface BasicCartItemInput {
  variantId: string;
  quantity: number;
}

export interface SubCartItemInput extends BasicCartItemInput {
  interval?: SubscriptionInterval;
}

export interface RedemptionCartItemInput extends BasicCartItemInput {
  points: number;
  redeemItem: true;
}

export type CartItemInput =
  | BasicCartItemInput
  | SubCartItemInput
  | RedemptionCartItemInput;

export function isSubCartItemInput(val: any): val is SubCartItemInput {
  return (
    typeof val === 'object' &&
    (typeof val['interval'] === 'string' || val['interval'] === null)
  );
}

export function isRedemptionCartItemInput(
  val: any
): val is RedemptionCartItemInput {
  return typeof val === 'object' && typeof val['redeemItem'] === 'boolean';
}

export function isBasicCartItemInput(val: any): val is BasicCartItemInput {
  return (
    typeof val === 'object' &&
    (typeof val['redeemItem'] === 'undefined' || val['redeemItem'] === null) &&
    (typeof val['interval'] === 'undefined' || val['interval'] === null)
  );
}

type TargetPropertyIntervalType = {
  interval: SubscriptionInterval;
};
type TargetPropertyRedemptionType = {
  redeemItem: true;
};

export function isTargetPropertyIntervalType(
  val: any
): val is TargetPropertyIntervalType {
  return typeof val === 'object' && typeof val['interval'] === 'string';
}

export function isTargetPropertyRedemptionType(
  val: any
): val is TargetPropertyRedemptionType {
  return typeof val === 'object' && typeof val['redeemItem'] === 'boolean';
}

export const ActionType = {
  Crate: 'create',
  Update: 'update',
} as const;

export type ActionType = (typeof ActionType)[keyof typeof ActionType];

export interface BaseCartItemFunctionInput {
  actionType: ActionType;
}

export interface CreateSubCartItemFunctionInput
  extends BaseCartItemFunctionInput,
    SubCartItemInput {
  actionType: 'create';
}
export interface UpdateSubCartItemFunctionInput
  extends BaseCartItemFunctionInput,
    SubCartItemInput {
  actionType: 'update';
  targetProperty:
    | TargetPropertyIntervalType
    | TargetPropertyRedemptionType
    | undefined;
}
export interface CreateBaseItemFunctionInput
  extends BaseCartItemFunctionInput,
    BasicCartItemInput {
  actionType: 'create';
}
export interface UpdateBaseItemFunctionInput
  extends BaseCartItemFunctionInput,
    BasicCartItemInput {
  actionType: 'update';
  targetProperty:
    | TargetPropertyIntervalType
    | TargetPropertyRedemptionType
    | undefined;
}

export interface CreateRedItemFunctionInput
  extends BaseCartItemFunctionInput,
    RedemptionCartItemInput {
  actionType: 'create';
}
export interface UpdateRedItemFunctionInput
  extends BaseCartItemFunctionInput,
    RedemptionCartItemInput {
  actionType: 'update';
  targetProperty:
    | TargetPropertyIntervalType
    | TargetPropertyRedemptionType
    | undefined;
}

export type CartItemFunctionInput =
  | CreateSubCartItemFunctionInput
  | UpdateSubCartItemFunctionInput
  | CreateBaseItemFunctionInput
  | UpdateBaseItemFunctionInput
  | CreateRedItemFunctionInput
  | UpdateRedItemFunctionInput;
