import { getLocaleConfig } from 'config';
import merge from 'lodash/merge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { axiosShopify } from 'utils/axios';

/**
 * Hooks an axios interceptor with shared configuration for API requests
 */
const useShopifyInterceptor = (): void => {
  const { locale } = useRouter();
  const { shopifyGraphqlEndpoint, shopifyAccessToken } =
    getLocaleConfig(locale);

  let requestInterceptor: number | null = null;

  if (typeof window !== 'undefined') {
    requestInterceptor = axiosShopify.interceptors.request.use((config) => {
      config.url = shopifyGraphqlEndpoint;

      // Automatically add headers
      return merge(config, {
        headers: {
          'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
        },
      });
    });
  }

  useEffect(() => {
    return () => {
      if (requestInterceptor !== null) {
        axiosShopify.interceptors.request.eject(requestInterceptor);
      }
    };
  });
};

export default useShopifyInterceptor;
