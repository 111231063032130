import SharpCross from 'assets/icons/sharp-cross.svg';
import cn from 'classnames';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import isBoolean from 'lodash/isBoolean';
import TastyGuaranteePromoBarModal from 'modules/header/tasty-guarantee-promo-bar-modal';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useCookieStore } from 'stores/cookie';
import { Gtm, gtm } from 'tracking/gtm';

export interface PromoBarConfiguration {
  enabled: boolean;
  href?: string;
  identifier: string;
  icon?: React.ReactNode;
  text?: React.ReactNode;
  cta?: React.ReactNode;
  backgroundColor?: string;
  couponCode?: string;
  toastText?: string;
  discountRate?: number;
  hideCloseButton?: boolean;
  modalId?: string;
  /** Callback after user clicks promo bar. By resolving true, hide function is called afterwards*/
  callBack?: () => any | Promise<boolean>;
}

interface PromoBarProps {
  promoBarConfiguration: PromoBarConfiguration;
}

const getWrapper = ({
  href,
  couponCode,
  modalCallBack,
}: {
  href?: string;
  couponCode?: string;
  modalCallBack?: (isOpen: boolean) => void;
}): React.FC<{ children: React.ReactNode }> => {
  if (href && !couponCode) {
    const LinkComp: React.FC<React.PropsWithChildren> = ({ children }) => (
      <Link href={href} passHref>
        {children}
      </Link>
    );
    return LinkComp;
  } else if (modalCallBack) {
    const ModalComp: React.FC<React.PropsWithChildren> = ({ children }) => (
      <div onClick={() => modalCallBack(true)}>{children}</div>
    );
    return ModalComp;
  } else {
    const FragmentComp: React.FC<React.PropsWithChildren> = ({ children }) => (
      <>{children}</>
    );
    return FragmentComp;
  }
};

/**
 * Common PromoBar component. When children is present, the whole design is overrided.
 */
const PromoBar: React.FC<React.PropsWithChildren<PromoBarProps>> = ({
  promoBarConfiguration: {
    enabled,
    href,
    identifier,
    text,
    cta,
    backgroundColor,
    icon,
    callBack,
    couponCode,
    discountRate,
    hideCloseButton = false,
    modalId,
  },
  children,
}) => {
  const router = useRouter();
  const { cookies, updateCookie } = useCookieStore();
  const [showBar, setShowBar] = useState(false);
  const [isMoneyBackModalOpen, setIsMoneyBackModalOpen] = useState(false);
  const { isEc } = useIsExistingCustomer();
  const [fistVisitNc, storeFistVisitNc] = useLocalStorage<boolean>(
    'pd:first-visit-nc',
    true
  );

  useEffect(() => {
    const handleRouteChange = (
      _url: string,
      { shallow }: { shallow: boolean }
    ): void => {
      if (!shallow) storeFistVisitNc(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events, storeFistVisitNc]);

  /**
   * Checks if the user is a new customer and it's his first visit to a page.
   * In this case, we don't show the promo bar. After a route change, the value
   * is set to false.
   */
  const isNewCustomerFirstVisit = useMemo(
    () => isEc || !fistVisitNc,
    [fistVisitNc, isEc]
  );

  const { pathname } = useRouter();

  const promoIdentifer = `promobar-${identifier}`;

  /**
   * User Journey Test - hide promo bar on home home
   */
  const isHomeTest = React.useMemo(() => {
    if (pathname === '/') {
      return true;
    }
    return false;
  }, [pathname]);

  const isCartPage = React.useMemo(() => {
    if (pathname === '/cart') {
      return true;
    }
    return false;
  }, [pathname]);

  const isFube = React.useMemo(() => {
    if (pathname === '/futterberater/[[...params]]') {
      return true;
    }
    return false;
  }, [pathname]);

  const shouldShowPromoBar = useMemo(() => {
    if (!enabled || !isNewCustomerFirstVisit || isHomeTest || isFube) {
      return false;
    }

    // first condition is for compatibility for previous implementation
    if (
      (cookies && cookies[identifier] === identifier) ||
      (cookies && cookies[promoIdentifer] === identifier)
    ) {
      return false;
    }

    return true;
  }, [
    cookies,
    isFube,
    isHomeTest,
    isNewCustomerFirstVisit,
    promoIdentifer,
    enabled,
    identifier,
  ]);

  useEffect(() => setShowBar(shouldShowPromoBar), [shouldShowPromoBar]);

  useEffect(() => {
    if (!enabled) setShowBar(false);
  }, [enabled]);

  const hide = React.useCallback(() => {
    const date = new Date();
    date.setDate(date.getDate() + 14);

    updateCookie(`promobar-${identifier}`, identifier, {
      expires: date,
      path: '/',
    });
    setShowBar(false);
  }, [identifier, updateCookie]);

  const modalCallBack =
    modalId === 'lecker-garantie' ? setIsMoneyBackModalOpen : undefined;

  const Wrapper = getWrapper({ href, couponCode, modalCallBack });

  const trackPromoClick = async (): Promise<void> => {
    gtm({
      group: Gtm.GroupName.PromoBar,
      name: Gtm.CommonEventName.Click,
      data: {
        id: identifier,
      },
    });
    if (callBack) {
      const data = await callBack();
      if (isBoolean(data) && data) {
        hide();
      }
    }
  };

  return (
    <>
      {!showBar || !enabled ? null : (
        <div
          id="promobar"
          data-test="promobar"
          className={cn(
            'relative',
            (isCartPage || isFube) && 'hidden lg:block'
          )}
        >
          <Wrapper>
            {children ? (
              <div onClick={trackPromoClick}>{children}</div>
            ) : (
              <div
                onClick={trackPromoClick}
                className={
                  'flex min-h-12 cursor-pointer items-center justify-start bg-primary-main px-3 py-2 text-left text-sm leading-tight text-other-white md:flex-row md:items-center md:px-6 md:text-center md:text-base lg:justify-center'
                }
                style={{ backgroundColor: `${backgroundColor}` }}
              >
                {discountRate && (
                  <div className="mr-2 rounded-lg bg-badge-discount px-2 pt-1 text-2xl font-black">
                    {discountRate}%
                  </div>
                )}
                {icon && <div className={'pr-3'}>{icon}</div>}
                <div className="flex flex-col md:flex-row lg:mt-0">
                  <span
                    data-test="Promobar.titel"
                    className="block pr-3 text-lg font-black lg:pb-0 lg:text-base"
                  >
                    {text}
                  </span>
                  <span
                    data-test="Promobar.Cta"
                    className="text-other-white underline"
                  >
                    {cta}
                  </span>
                </div>
              </div>
            )}
          </Wrapper>

          {!hideCloseButton && (
            <button
              data-test="close-promobar"
              aria-label="Schließen"
              className="absolute right-0 top-0 mr-1 h-full p-3 text-other-white opacity-75 hover:opacity-100 focus:outline-hidden lg:mr-0"
              onClick={hide}
            >
              <SharpCross />
            </button>
          )}
        </div>
      )}
      {modalId === 'lecker-garantie' && (
        <TastyGuaranteePromoBarModal
          open={isMoneyBackModalOpen}
          onClose={() => setIsMoneyBackModalOpen(false)}
        />
      )}
    </>
  );
};

export default PromoBar;
