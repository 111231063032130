import { axios } from 'utils/axios';
import { ERROR_CODES_TO_HANDLE } from './axios-interceptors';

export const track = (data: object): any =>
  axios
    .post('/default/track', data, {
      // tracking error has nothing to do with users. we don't show any indication on this error
      errorHandling: {
        statusesToOmit: [...ERROR_CODES_TO_HANDLE],
      },
    })
    .then((response) => response.data);
