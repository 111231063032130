import { useConfig } from 'config';
import { PetContextValues } from 'contexts/pet';

type GetPetContextByUrl = (url: string) => PetContextValues;

/** Get PetContextValues from string */
const useGetPetContextByUrlMethod = (): GetPetContextByUrl => {
  const { commonCategories } = useConfig();
  return (url: string): PetContextValues => {
    switch (true) {
      case url.indexOf(commonCategories.dog) > -1:
      case url.indexOf(commonCategories.dogs) > -1:
      case url.indexOf(commonCategories.pup) > -1:
      case url.indexOf(commonCategories.puppy) > -1:
        return 'dogs';
      case url.indexOf(commonCategories.cat) > -1:
      case url.indexOf(commonCategories.cats) > -1:
      case url.indexOf(commonCategories.catShort) > -1:
      case url.indexOf(commonCategories.kitten) > -1:
        return 'cats';
    }

    return false;
  };
};

export { useGetPetContextByUrlMethod };
