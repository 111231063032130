import { Address } from 'interfaces/address';
import { CustomerCredit } from 'interfaces/customer';
import { axios } from 'utils/axios';
import * as AddressModel from '../models/address';
import * as OrdersModel from '../models/order';

export interface FetchCustomerResponse extends CustomerCredit {
  acceptsMarketing: boolean;
  /** created date in unix time stamp */
  acceptsMarketingUpdatedAt: string;
  addresses: Array<Address>;
  adminGraphqlApiId: string;
  /** created date in unix time stamp */
  createdAt: string;
  currency: string;
  defaultAddress?: Address;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  lastOrderId?: number;
  lastOrderName?: string;
  marketingOptInLevel: any;
  multipassIdentifier: any;
  note: any;
  ordersCount: number;
  phone: any;
  state: string;
  /** Formatted with ","  */
  tags: string;
  taxExempt: boolean;
  taxExemptions: Array<any>;
  /** Sum of total spend in the format with cent */
  totalSpent: string;
  /** Updated date in unix time stamp */
  updatedAt: '2022-07-01T11:20:32+02:00';
  verifiedEmail: boolean;
}

export const fetchCustomer = (
  customerId: number
): Promise<FetchCustomerResponse> =>
  axios
    .get<FetchCustomerResponse>(`/account/customer/${customerId}`)
    .then(({ data }) => data);

export interface AddressResponse {
  address1: string;
  /** @deprecated */
  address2: string;
  city: string;
  company: string;
  country: string;
  countryCode: string;
  countryName: string;
  customerId: number;
  default: boolean;
  firstName: string;
  id: number;
  lastName: string;
  name: string;
  phone: string;
  /** @deprecated */
  province: string;
  /** @deprecated */
  provinceCode: string;
  zip: string;
}

export const updateAddress = (customerId: number, address: Address) =>
  axios
    .post<AddressResponse>(
      `/account/address/${customerId}`,
      AddressModel.toServerFormat(address)
    )
    .then(({ data }) => AddressModel.fromServerFormat(data));

export const createAddress = updateAddress;

export const deleteAddress = (customerId: number, addressId: number) =>
  axios.delete(`/account/address/${customerId}`, {
    data: {
      id: addressId,
    },
  });

export const fetchOrders = (customerId: number) =>
  axios
    .get(`/account/account/${customerId}/orders`)
    .then(({ data }) => data)
    .then(OrdersModel.ordersFromServerFormat);

export const fetchOrderDetails = (customerId: number, orderId: number) =>
  axios
    .get(`/account/account/${customerId}/orders/${orderId}`)
    .then(({ data }) => data)
    .then(OrdersModel.orderDetailsFromServerFormat);

interface InvoiceResult {
  file: string;
  fileName: string;
}

/**
 * fetch user's invoice of a order.
 * @param {number} customerId The id of the customer.
 * @param {number} orderId The id of the order.
 * @returns {Promise}
 */
export const fetchInvoice = (
  customerId: number,
  orderId: number
): Promise<InvoiceResult> =>
  axios
    .get(`/account/${customerId}/orders/${orderId}/invoice`)
    .then(({ data }) => data);

/**
 * Send a password reset email to customer.
 */
export const passwordForgot = (email: string): Promise<{ ok: 'ok' }> =>
  axios
    .post(`${process.env.API_ORIGIN_CUSTOMER}`, {
      action: 'forgot',
      email,
    })
    .then(({ data }) => data.data);

/**
 * Reset password for customer.
 */
export const passwordReset = (
  password: string,
  password_confirmation: string,
  token: string
): Promise<{ ok: 'ok' }> =>
  axios
    .post(`${process.env.API_ORIGIN_CUSTOMER}`, {
      action: 'reset',
      password,
      password_confirmation,
      token,
    })
    .then(({ data }) => data.data);

/**
 * Push segmentation for customer session.
 * @TODO : stub
 * @TODO : Add docs about how it works
 */
export const pushSegmentation = ({
  segmentation,
}: {
  segmentation: string;
}): Promise<string> => Promise.resolve('okay');
