import { useNinetailed } from '@ninetailed/experience.js-next';
import { useConfig } from 'config';
import useIsClient from 'hooks/common/use-is-client';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCookieStore } from 'stores/cookie';
import { useGetPetContextByUrlMethod } from 'utils/get-pet-context-from-url';
import { useAuth } from './auth';

export type PetContextValues = false | 'dogs' | 'cats';

interface PetContextType {
  current: PetContextValues;
  homeLink: {
    href: string;
  };
  switchContext: (name: PetContextValues) => void;
}

const PetContext = createContext<PetContextType>({} as any);

export const PetContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { cookies, updateCookie } = useCookieStore();
  const [current, switchContext] = useState<PetContextValues>(
    (cookies?.petContext as 'dogs' | 'cats') || false
  );
  const { isClient } = useIsClient();
  const { customer } = useAuth();
  const { identify } = useNinetailed();

  const { commonCategories } = useConfig();

  const getPetContextFromUrl = useGetPetContextByUrlMethod();

  useEffect(() => {
    const url = window.location.pathname;

    // do nothing on the main homepage and fube
    if (url !== '/' && url !== '/futterberater') {
      const petContext = getPetContextFromUrl(url);

      if (petContext === false) {
        if (cookies && cookies.petContext) {
          switchContext(cookies.petContext as PetContextValues);
        } else {
          if (!cookies) return;
          // show default for non home-pages
          switchContext('dogs');
        }
      } else {
        switchContext(petContext);
      }
    }
  }, [cookies]);

  useEffect(() => {
    if (document) {
      const root = document.documentElement;
      let color = '#19335a';

      if (current === 'dogs') {
        color = '#55c3d4';
      } else if (current === 'cats') {
        color = '#F39C86';
      }

      if (current === 'dogs' || current === 'cats') {
        const date = new Date();
        date.setDate(date.getDate() + 900);

        updateCookie('petContext', current, {
          expires: date,
          path: '/',
        });
      }

      root.style.setProperty('--pet-context-color', color);
    }
  }, [current, updateCookie]);

  const homeLink = useMemo(() => {
    switch (current) {
      case 'dogs':
        return {
          href: `/pages/${commonCategories.dogs}`,
        };
      case 'cats':
        return {
          href: `/pages/${commonCategories.cats}`,
        };
      default:
        return {
          href: '/',
        };
    }
  }, [commonCategories.cats, commonCategories.dogs, current]);

  useEffect(() => {
    if (current) {
      identify(`${customer ? customer.customerId : ''}`, {
        petType: current,
      });
    }
  }, [current, customer, identify]);

  const value = {
    current: isClient ? current : false,
    homeLink,
    switchContext,
  };

  return <PetContext.Provider value={value}>{children}</PetContext.Provider>;
};

export const usePetContext = () => useContext(PetContext);
