import {
  NinetailedAnalyticsPlugin,
  SanitizedElementSeenPayload,
  Template,
} from '@ninetailed/experience.js-plugin-analytics';
import { gtm, viewElement } from 'tracking';

type NinetailedGoogleTagmanagerPluginOptions = {
  actionTemplate?: string;
  labelTemplate?: string;

  template?: Template;
};

const TEST_EXPERIENCE_ID = '1IRtehyuxkhgxLyHdd49JN';

/**
 * TestGtmPlugin is a plugin for Ninetailed Analytics that is used to track a single experience for testing purposes.
 * The idea is to track a single experience, placed in the GlobalTestHolder Context
 * and see if the there will be a any discrepancy with the standard Ninetailed GTM Plugin.
 */
export class TestGtmPlugin extends NinetailedAnalyticsPlugin {
  public name = 'ninetailed:test-gtm-plugin';
  private eventCache: Set<string>;

  constructor(
    private readonly options: NinetailedGoogleTagmanagerPluginOptions = {}
  ) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}',
    });
    this.eventCache = new Set<string>();
  }

  public initialize = (): void => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
    }
  };

  private generateEventKey = (experienceId: string): string =>
    `experience_${experienceId}`;

  protected async onTrackExperience(
    properties: SanitizedElementSeenPayload,
    hasSeenExperienceEventPayload: Record<string, string>
  ): Promise<void> {
    if (
      hasSeenExperienceEventPayload.ninetailed_experience === TEST_EXPERIENCE_ID
    ) {
      const eventKey = this.generateEventKey(
        hasSeenExperienceEventPayload.ninetailed_experience
      );

      if (this.eventCache.has(eventKey)) {
        return;
      }

      gtm(
        viewElement({
          element: [
            'Ninetailed Experience Tracked: ',
            hasSeenExperienceEventPayload.ninetailed_experience,
          ],
        })
      );

      this.eventCache.add(eventKey);
    }
  }

  protected async onTrackComponent(): Promise<void> {
    return;
  }
}
