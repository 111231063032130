import { setTag } from '@sentry/browser';
import { getGlobalContentfulData } from 'api/contentful';
import config, { useConfig } from 'config';
import { Entry } from 'contentful';
import {
  IAdTileSlider,
  ICrossSellPopUpDataFields,
  IGlobalData,
  IGlobalDataFields,
  IPromoBar,
} from 'global';
import useExperimentInterceptor from 'hooks/common/use-experiment-interceptor';
import useGlobalDiscount, {
  GlobalDiscount,
} from 'hooks/common/use-global-discount';
import useGlobalLoyaltyCampaign, {
  GlobalLoyaltyCampaign,
} from 'hooks/common/use-global-loyalty-campaign';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'react-use';
import { useCookieStore } from 'stores/cookie';
import useSWR from 'swr';
import { Gtm, gtm } from 'tracking/gtm';
import { globalData as fallbackData } from 'utils/static-global-data';

/**  Product input type for Impressions*/
export interface ImpressionTrackProductType {
  productId: string | number;
  productType: string;
  productTitle: string;
  tags: Array<string>;
  /** Position in slider */
  position: number;
  /** Collection List name */
  list: string;
}

interface CommonContextType {
  crossSellPopUpData?: ICrossSellPopUpDataFields;
  globalData?: Entry<IGlobalDataFields>;
  globalDiscount: GlobalDiscount;
  globalLoyaltyCampaign: GlobalLoyaltyCampaign;
  trackedImpressionStorage: {
    [key in string]: Array<string | number>;
  };
  setTrackedImpressionStorage: React.Dispatch<
    React.SetStateAction<{
      [key in string]: Array<string | number>;
    }>
  >;

  productImpressionsQueue: Array<ImpressionTrackProductType>;
  setProductImpressionsQueue: React.Dispatch<
    React.SetStateAction<ImpressionTrackProductType[]>
  >;
  /** Weekly offers for EC. Personalization should be done individually with usePersonalizedWeeklyOffer*/
  weeklyOffers?: IAdTileSlider[];
  mainPromoBar: IPromoBar | undefined;
}

const CommonContext = createContext<CommonContextType>({} as any);

export const CommonContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { branchName } = config;
  const { globalDataEntryId } = useConfig();
  const { cookies } = useCookieStore();
  if (!fallbackData) {
    console.error('static global data is missing!');
  }

  /**
   * Tracks impression per page
   * @see useImpressionTracking
   */
  const [trackedImpressionStorage, setTrackedImpressionStorage] = useState<{
    [key in string]: Array<string | number>;
  }>({});

  /**
   * Tracks product impressions per page
   */
  const [productImpressionsQueue, setProductImpressionsQueue] = useState<
    Array<ImpressionTrackProductType>
  >([]);

  /**
   * Track product impressions
   * @see ProductImpressionsTracker
   */
  useDebounce(
    () => {
      if (productImpressionsQueue.length > 0) {
        const newMap = productImpressionsQueue.map((product) => ({
          productId: product.productId + '',
          productType: product.productType,
          productTitle: product.productTitle,
          tags: product.tags.join(','),
          position: product.position,
          list: product.list,
        }));

        gtm({
          group: Gtm.GroupName.Product,
          name: Gtm.ProductEventName.Impressions,
          data: {
            products: newMap,
          },
          nonInteractive: true,
        });
        setProductImpressionsQueue([]);
      }
    },
    500,
    [productImpressionsQueue]
  );

  const router = useRouter();

  /**
   * Reset impression store when page routes to another path
   * so that impression is tracked per page
   * */
  useEffect(() => {
    const handleRouteChange = (url, { shallow }): void => {
      // reset only when deep page transition, meaning excluding page params update
      if (!shallow) {
        setTrackedImpressionStorage({});
        setProductImpressionsQueue([]);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, setTrackedImpressionStorage]);

  const { data: globalData } = useSWR(
    'global',
    () =>
      getGlobalContentfulData({
        globalDataEntryId,
        locale: router.locale,
      }),
    {
      fallback: {
        // refer to json property on fallback data
        global: fallbackData[router.locale ?? 'de'],
      },
      revalidateOnFocus: false,
    }
  );

  const crossSellPopUpData = globalData?.fields.crossSellPopUp?.fields;

  const globalLoyaltyCampaign = useGlobalLoyaltyCampaign(
    globalData as IGlobalData
  );
  const globalDiscount = useGlobalDiscount(globalData as IGlobalData);

  useExperimentInterceptor();

  const mainPromoBar = useMemo(
    () => globalData?.fields.mainPromoBar,
    [globalData]
  );

  // Set release version tag for Sentry
  useEffect(() => {
    if (cookies?.pd_rv) setTag('pd_release_version', cookies.pd_rv);
  }, [cookies?.pd_rv]);

  // Set branch name tag for Sentry
  useEffect(() => {
    setTag('branchName', branchName);
  }, [branchName]);

  const data = useMemo(() => {
    return {
      crossSellPopUpData,
      globalData,
      globalDiscount,
      globalLoyaltyCampaign,
      productImpressionsQueue,
      trackedImpressionStorage,
      weeklyOffers: globalData?.fields.weeklyOffers,
      setProductImpressionsQueue,
      setTrackedImpressionStorage,
      mainPromoBar,
    };
  }, [
    crossSellPopUpData,
    globalData,
    globalDiscount,
    globalLoyaltyCampaign,
    productImpressionsQueue,
    trackedImpressionStorage,
    setTrackedImpressionStorage,
    mainPromoBar,
  ]);

  return (
    <CommonContext.Provider value={data}>{children}</CommonContext.Provider>
  );
};

export const useCommon = (): CommonContextType => useContext(CommonContext);
