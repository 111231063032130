// src/utils/axios.ts
import AxiosLib, { AxiosError } from 'axios';
import identity from 'lodash/identity';
import { errorsHandlingInterceptor } from '../api/axios-interceptors';
import { configureAxiosInstance } from './configure-axios';

const axios = AxiosLib.create();
// Initial configuration with default locale
configureAxiosInstance(axios);
axios.interceptors.response.use(identity, errorsHandlingInterceptor);

const axiosShopify = AxiosLib.create();

const axiosWithoutRequestInterceptor = AxiosLib.create();
axiosWithoutRequestInterceptor.interceptors.response.use(
  identity,
  errorsHandlingInterceptor
);

/**
 * Assert function for AxiosError.
 */
function isAxiosError<T = any>(error: any): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

export { axios, axiosShopify, axiosWithoutRequestInterceptor, isAxiosError };
