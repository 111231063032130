import cn from 'classnames';
import useExperiment from 'hooks/common/use-experiment';
import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import useNavigationData from 'hooks/navigation/use-navigation-data';
import Link from 'next/link';
import React from 'react';
import { isAbsoluteUrl } from 'utils/url';
import UspBar from '../usp-bar';
import styles from './top-header.module.css';

const HeaderTop: React.ComponentType = () => {
  const { trustbar } = useNavigationData();

  // PT-6244: FE: ABC test: if removing the USP vs. updating it or keeping it the same has any effect on NC CVR
  // @see https://petsdeli.atlassian.net/browse/PT-6244
  const { variant, loading } = useExperiment({
    baseEntryId: '6osTIt51FwDYRkU0VfK4Kf',
  });
  const { isEc } = useIsExistingCustomer();

  const isUspBarHidden =
    loading ||
    variant === undefined ||
    variant === 2 ||
    (isEc && variant !== undefined);

  return (
    (<div className="w-full bg-grey-100">
      <div className="mx-auto lg:container">
        {/* Trustbar for desktop */}
        <div
          className={cn(
            styles.headerTopMaxWidth,
            'hidden h-8 items-center text-center text-sm leading-none lg:flex'
          )}
        >
          <div className="relative flex w-full items-center justify-end">
            {isUspBarHidden ? null : (
              <div data-test="promo-banner2" className="absolute w-full">
                <UspBar testVariant={variant} variant="embedded-top" />
              </div>
            )}
            <div className="z-50 flex items-center justify-end pr-8">
              {trustbar.map((item) => {
                if (isAbsoluteUrl(item.url)) {
                  return (
                    <a
                      key={item.url}
                      data-test={`desktop-trustbar-${item.title}`}
                      href={item.url}
                      target={item.target}
                      rel={item.rel}
                      className="pt-pxleading-none ml-4 flex cursor-pointer justify-end text-primary-main "
                    >
                      <span className="hover:underline">{item.title}</span>
                    </a>
                  );
                } else {
                  return (
                    (<Link
                      key={item.url}
                      href={item.url}
                      passHref
                      className="pt-pxleading-none ml-4 flex cursor-pointer justify-end text-primary-main ">

                      <span className="hover:underline">{item.title}</span>

                    </Link>)
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>)
  );
};

export default HeaderTop;
