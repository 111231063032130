import * as Sentry from '@sentry/browser';
import config from 'config';

const ignoreErrors = [
  /Trustbadge/gi,
  // Ignore network errors e.g user's internet connection is disconnected
  /Network Error/gi,
  // Ignore Kinesis errors
  /Kinesis/gi,
  // Ignore request aborted errors
  /Request aborted/gi,
  // ignore Hydration error on production
  /Minified React error #418/gi,
  /Hydration failed because the initial UI/gi,
  // Ignore abort fetching errors from Next.js when user cancels a request
  /Abort fetching/gi,
  // Ignore loading initial props cancelled errors from Next.js e.g when user navigates away from a page
  /Loading initial props cancelled/gi,
  // Ignore error when hard navigating to the same URL
  /Error: Invariant: attempted to hard navigate to the same URL/gi,
  // Ignore error when route did not complete loading due to slow connection
  /Route did not complete loading/gi,
  // Ignore error from 3rd party library injected from tag manager
  /The method setUserId is registered more than once/gi,
  // Ignore error when sending data to Kinesis
  /Error sending data to Kinesis/gi,
];
const denyUrls = [
  // Ignore Pinterest images
  /s\.pinimg\.com/i,
];

if (
  config.sentryUrl &&
  config.disableSentry !== 'true' &&
  config.nodeEnv !== 'development'
) {
  Sentry.init({
    dsn: config.sentryUrl,
    release: config.commitHash,
    environment: config.pdEnv,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error', 'debug'],
      }),
      /**
       * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
       */
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['petsdeli-sentry'],
        behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
      }),
    ],
    ignoreErrors: [...ignoreErrors],
    denyUrls,
    beforeSend: function (event) {
      // Check if there's an apiRoute tag
      if (event.tags && event.tags.apiRoute) {
        // Parse the URL and remove query parameters
        try {
          const url = new URL(event.tags.apiRoute as string);
          // Split path into segments and filter out segments containing numbers
          // assume subdirectories with numbers are user-specific
          const pathSegments = url.pathname
            .split('/')
            .map((segment) => {
              return /\d/.test(segment) ? '' : segment;
            })
            .filter(Boolean);
          // Set the apiRoute tag to origin + filtered path
          event.tags.apiRoute = url.origin + '/' + pathSegments.join('/');
        } catch (e) {
          // Handle invalid URLs
        }
      }
      return event;
    },
  });
}
