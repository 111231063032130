import { VariantRef } from '@ninetailed/experience.js';
import { ShopId } from 'constants/shop';
import { CustomerSubscriptionStatus } from 'interfaces/customer';

type OurTraits = Partial<{
  ordersCount: number;
  createdAt: number;
  subscriptionStatus: CustomerSubscriptionStatus;
  isUserLoggedIn: boolean;
  /** PetType. Should be used to differentiate contents for dogs or cats  */
  petType: 'dogs' | 'cats';
  /** shopId. Should be used to differentiate audience in each shops  */
  shopId: ShopId;
  /** Snacl likelihood */
  snackLikelihood: number;
  /** Supplement likelihood */
  supplementLikelihood: number;
  /**
   * Segmentation
   * @see : https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1974042625/Segmentation
   * */
  /** Last pushed segmentation  */
  lastSegment: string;
  /** petProfile, array of segmentationId */
  petProfile: Array<string>;
  /** allSegments, array of segmentationId */
  allSegments: Array<string>;
}>;

/**
 * Assert traits property as our traits because Ninetailed traits is typed as JSON type.
 */
export function isTraits(val: any): val is OurTraits {
  return typeof val === 'object' && typeof val['ordersCount'] === 'number';
}

export function isVariantRef(val: any): val is VariantRef {
  return typeof val === 'object' && val['hidden'] === 'boolean';
}
