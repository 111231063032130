export default {
  'pdp:product-title:ratings':
    '{count, number} {count, plural, one {Bewertung} other {Bewertungen}}',
  'pdp:buy-panel:size': 'Variante',
  'pdp:buy-panel:amount': 'Menge',
  'pdp:buy-panel:subscription': 'Abo',
  'pdp:buy-panel:out-of-stock': ' - Ausverkauft',
  'pdp:buy-panel:order-type:one-off': 'Einmaliger Kauf',
  'pdp:buy-panel:order-type:one-off-test': 'Einzelkauf',
  'pdp:buy-panel:order-type:subscription': 'Regelmäßige Lieferung',
  'pdp:buy-panel:order-type:subscription-test': 'Spar-Abo',
  'pdp:buy-panel:order-type:subscription-test-2': 'Pets Deli Sparplan',
  'pdp:buy-panel:order-type:subscription:test-message':
    '-{discount}% auf deine 1. Bestellung, -10% immer ab deiner 2. Bestellung. Dein Abo kannst du jederzeit ändern, pausieren oder kündigen.',
  'pdp:buy-panel:order-type:subscription:cut-out': '{value}',
  'pdp:buy-panel:order-type:subscription:cut-out-active': '{value} abo aktiv',
  'pdp:buy-panel:subscription-interval:sub-title':
    'jederzeit änderbar, pausierbar oder kündbar',
  'pdp:buy-panel:subscription-intervals:2-weeks': '2 Wochen',
  'pdp:buy-panel:subscription-intervals:4-weeks': '4 Wochen',
  'pdp:buy-panel:subscription-intervals:6-weeks': '6 Wochen',
  'pdp:buy-panel:subscription-intervals:8-weeks': '8 Wochen',
  'pdp:buy-panel:subscription-intervals:2-weeks-test':
    'Lieferung alle 2 Wochen',
  'pdp:buy-panel:subscription-intervals:4-weeks-test':
    'Lieferung alle 4 Wochen',
  'pdp:buy-panel:subscription-intervals:6-weeks-test':
    'Lieferung alle 6 Wochen',
  'pdp:buy-panel:subscription-intervals:8-weeks-test':
    'Lieferung alle 8 Wochen',

  'pdp:buy-panel:subscription-good-choice-alert-1':
    'Spare {value}% mit unserer regelmäßigen Lieferung.',
  'pdp:buy-panel:subscription-good-choice-alert-2':
    'Du kannst die Lieferung jederzeit selbstständig pausieren oder beenden.',
  'pdp:buy-panel:add-to-cart': 'In den Warenkorb',
  'pdp:buy-panel:added-to-cart': 'In den Warenkorb gelegt',
  'pdp:buy-panel:back-in-stock': 'Benachrichtige mich!',
  'pdp:buy-panel:product-out-stock': 'Ausverkauft',
  'pdp:buy-panel:preorder': 'Jetzt vorbestellen',
  'pdp:buy-panel:price-per-kilo': 'entspricht:',
  'pdp:buy-panel:shipping-time': 'Lieferzeit: {value} Werktage',
  'pdp:buy-panel:pre-order-shipping-date': 'Lieferung {value}',
  'pdp:buy-panel:back-in-stock:title':
    'Benachrichtige mich, wenn das Produkt wieder verfügbar ist',
  'pdp:buy-panel:back-in-stock:description':
    'Trage hier Deine E-Mail ein und wir benachrichtigen Dich, sobald wir dieses Produkt wieder auf Lager haben.',
  'pdp:buy-panel:back-in-stock:cta': 'Senden',
  'pdp:buy-panel:back-in-stock:terms':
    'Wir benachrichtigen Dich bei Verfügbarkeit. Wir teilen Deine E-Mail mit niemand anderem.',
  'pdp:content:tabs:product-information-title': 'Produktionformation',
  'pdp:included-products': 'besteht aus',
  'pdp:buy-panel:bonus-points':
    'Jetzt kaufen und <span class="font-black text-gold">{value} Punkte erhalten</span>',
  'pdp:buy-panel:bonus-points-campaign-point': '{value} Punkte',
  'pdp:buy-panel:bonus-points-campaign-text':
    'Jetzt kaufen und <b>{numeral}-fach</b> Punkte erhalten',

  'pdp:buy-panel:bonus-points-campaign-point-newcustomer':
    '{value} + {firstCustomerBonus} Punkte für deinen Einkauf',
  'pdp:buy-panel:bonus-points-campaign-point-extra-bonus':
    '{value} + {extraBonusPoints} Punkte für deinen Einkauf',
  'pdp:buy-panel:bonus-points-campaign-point-extra-bonus-plus-newcustomer':
    '{value} + {firstCustomerBonus} + {extraBonusPoints} Punkte für deinen Einkauf',

  'pdp:buy-panel:free-shipping-new-customer':
    '<div><strong>Versandkostenfrei</strong><p> für Neukunden</p></div>',
  'pdp:buy-panel:free-shipping-new-customer:without-style':
    'Versandkostenfrei für Neukunden',
  'pdp:buy-panel:free-shipping-new-customer:test':
    '0 € Versand - du sparst 4,90 €',

  'pdp:buy-panel:free-shipping-existing-customer':
    '<div><strong>Versandkostenfrei</strong><p> ab 60€ Bestellwert</p></div>',
  'pdp:buy-panel:free-shipping-existing-customer:without-style':
    'Versandkostenfrei ab 60€ Bestellwert',

  'pdp:buy-panel:savings-banner-description':
    'Gute Wahl! Du <span>sparst {formattedSaving} im Jahr!</span> Nice!',

  'pdp:buy-panel:coupon-price-personalization:discount-percentage':
    '{couponName} Gutschein angewendet (-{discount}%)',

  'pdp:buy-panel:coupon-price-personalization:mov-info':
    'Gutschein gilt für Bestellung ab {mov}€ Bestellwert',

  'pdp:buy-panel:recommended-alternative:title':
    'Probier doch diese Alternative:',

  'common:one-time-purchase:title': 'Einmalige Lieferung',

  'pdp:subscription-confirmation-information:sub-number': 'Abo ({aboId})',
  'pdp:subscription-confirmation-information:success':
    'Erfolgreich hinzugefügt',
  'pdp:subscription-confirmation-information:interval': 'Lieferung: ',
  'pdp:subscription-confirmation-information:next-delivery':
    'Nächste Lieferung: ',
  'pdp:subscription-confirmation-information:cta:manage-subscription':
    'Abo verwalten',
  'pdp:subscription-confirmation-information:cta:continue-shopping':
    'Weiter einkaufen',
  'pdp:AddToExistingSubscriptionButton:added-successfully':
    'Erfolgreich einzugefügt',
  'pdp:AddToExistingSubscriptionButton:do-you-have-sub':
    'Du hast bereits ein Abo? Dann melde dich einfach an, füge es deinem Abo hinzu und spare.',
  'pdp:AddToExistingSubscriptionButton:add-to-sub:one-off':
    'Mit dem Abo liefern',
  'pdp:AddToExistingSubscriptionButton:add-to-sub:subscription':
    'Zum Abo hinzufügen',
  'pdp:AddToExistingSubscriptionButton:please-choose-your-abo':
    'Bitte auswählen deinem Abo',
  'pdp:AddToExistingSubscriptionButton:shipping': 'Lieferung',
  'pdp:subscription-confirmation-information:limited-items':
    'Noch ist es nicht deins! Damit das Produkt sicher in deiner Lieferung landet, wähle in <b>"Abo verwalten"</b> die Option <b>"Sofort ausführen"</b>',

  'pdp:buy-panel:eta-communication:days':
    'Das Produkt wird am {eta} wieder verfügbar sein.',
  'pdp:buy-panel:eta-communication:weeks':
    'Das Produkt wird in {eta} {eta, plural, one {Woche} other {Wochen}} wieder verfügbar sein.',
  'pdp:buy-panel:eta-communication:months':
    'Das Produkt wird in {eta} {eta, plural, one {Monat} other {Monaten}} wieder verfügbar sein.',
  'pdp:buy-panel:eta-communication:unknown':
    'Das Produkt ist auf unbestimmte Zeit nicht verfügbar.',
};
