import {
  NinetailedAnalyticsPlugin,
  SanitizedElementSeenPayload,
  Template,
} from '@ninetailed/experience.js-plugin-analytics';
import { template } from '@ninetailed/experience.js-shared';
import cookie from 'cookie';
import { TEMPLATE_OPTIONS } from '.';
import { sendData } from '../../connectors/kinesis';

type InternalTrackingPluginOptions = {
  actionTemplate?: string;
  labelTemplate?: string;
  template?: Template;
};

const streamArn =
  'arn:aws:kinesis:eu-central-1:093173765289:stream/eventtracking';

/**
 * Custom plugin to send tracking information to AWS Kinesis.
 */
export class InternalTrackingPlugin extends NinetailedAnalyticsPlugin {
  public name = 'internal:tracking';
  private eventCache: Set<string>;
  private sessionId: string | undefined;
  private releaseVersion: string | undefined;
  private userAgent: string | undefined;
  private cookieConsent: string | undefined;
  private eventQueue: Array<() => void> = [];
  private maxWaitTime = 5000;
  private checkInterval = 500;
  private elapsedTime = 0;

  constructor(private readonly options: InternalTrackingPluginOptions = {}) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}',
      ninetailed_experience_type: '{{experience.type}}',
      ninetailed_profile_id: '{{profile.id}}',
      ninetailed_stable_id: '{{profile.stableId}}',
    });
    this.eventCache = new Set();
    this.initialize();
  }

  private initialize = (): void => {
    if (typeof navigator !== 'undefined') {
      this.userAgent = navigator.userAgent;
    }
    this.initializeSessionData();
  };

  private initializeSessionData = (): void => {
    this.checkSessionId();
  };

  private checkSessionId = (): void => {
    if (typeof document !== 'undefined') {
      const cookies = cookie.parse(document.cookie);
      this.sessionId = cookies._ga;
      this.releaseVersion = cookies.pd_rv;
      this.cookieConsent = cookies.CookieConsent;
    }

    if (!this.sessionId && this.elapsedTime < this.maxWaitTime) {
      setTimeout(() => {
        this.elapsedTime += this.checkInterval;
        this.checkSessionId();
      }, this.checkInterval);
    } else {
      this.processQueuedEvents();
    }
  };

  private queueEvent = (eventFunction: () => void): void => {
    this.eventQueue.push(eventFunction);
  };

  private processQueuedEvents = (): void => {
    this.eventQueue.forEach((eventFunction) => eventFunction());
    this.eventQueue = [];
  };

  private isGoogleAnalyticsLoaded = (): string => {
    return (
      typeof window !== 'undefined' &&
      Array.isArray(window.dataLayer) &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.gtag === 'function'
    ).toString();
  };

  private isCookieBotLoaded = (): string => {
    if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
      for (const item of window.dataLayer) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item.event === 'cookie_consent_show' &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeof item.cookiebot_displayed !== 'undefined'
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return item.cookiebot_displayed.toString();
        }
      }
    }
    return 'false';
  };

  /**
   * Checks various privacy settings and returns the reasons why the privacy shield is enabled.
   * If there are no reasons, returns 'No'.
   */
  private isPrivacyShieldEnabled = (): string => {
    if (typeof window === 'undefined') return 'Window is undefined';

    const reasons: string[] = [];

    try {
      // Check if third-party cookies are blocked by checking GA cookie
      if (document.cookie.indexOf('_ga=') === -1) {
        reasons.push('Third-party cookies are blocked');
      }

      // Check if localStorage is blocked
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
      } catch (e) {
        reasons.push('localStorage is blocked');
      }

      // Check if cookies are generally blocked
      if (!navigator.cookieEnabled) {
        reasons.push('Cookies are disabled in the browser');
      }

      // Check Do Not Track setting
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (navigator.doNotTrack === '1' || window.doNotTrack === '1') {
        reasons.push('Do Not Track is enabled');
      }

      // Check if IndexedDB is blocked (common in private modes)
      try {
        if (!window.indexedDB) {
          reasons.push('IndexedDB is blocked');
        }
      } catch (e) {
        reasons.push('IndexedDB is blocked');
      }

      // Check if Google Analytics is blocked
      if (this.isGoogleAnalyticsLoaded() === 'false') {
        reasons.push('Google Analytics is blocked');
      }

      return reasons.length === 0 ? 'No' : reasons.join(', ');
    } catch (e) {
      return 'Error occurred while checking privacy shield status';
    }
  };

  private generateEventKey = (payload: Record<string, string>): string =>
    `${payload.ninetailed_experience}_${payload.ninetailed_component}`;

  private generatePartitionKey = (key: string): string =>
    `${this.sessionId}-${key}`;

  protected onTrackExperience = async (
    properties: SanitizedElementSeenPayload,
    hasSeenExperienceEventPayload: Record<string, string>
  ): Promise<void> => {
    // In order to avoid duplicate events, we cache the event key
    const eventKey = this.generateEventKey(hasSeenExperienceEventPayload);
    if (!this.eventCache.has(eventKey)) {
      this.eventCache.add(eventKey);

      const sendEvent = (): void => {
        const profile = window.ninetailed?.profile;
        const timestamp = new Date().toISOString();

        // Add profile id and stable id to the payload
        if (profile) {
          hasSeenExperienceEventPayload.ninetailed_profile_id = profile.id;
          hasSeenExperienceEventPayload.ninetailed_stable_id = profile.stableId;
        }
        if (this.sessionId) {
          hasSeenExperienceEventPayload.session_id = this.sessionId;
        }
        if (this.releaseVersion) {
          hasSeenExperienceEventPayload.releaseVersion = this.releaseVersion;
        }
        if (this.cookieConsent) {
          hasSeenExperienceEventPayload.cookieConsent = this.cookieConsent;
        }
        if (this.userAgent) {
          hasSeenExperienceEventPayload.userAgent = this.userAgent;
        }
        hasSeenExperienceEventPayload;
        hasSeenExperienceEventPayload.timestamp = timestamp;
        hasSeenExperienceEventPayload.privacyShieldEnabled =
          this.isPrivacyShieldEnabled();
        hasSeenExperienceEventPayload.cookiebotLoaded =
          this.isCookieBotLoaded();
        hasSeenExperienceEventPayload.gaLoaded = this.isGoogleAnalyticsLoaded();

        sendData({
          data: new Uint8Array(
            Buffer.from(JSON.stringify(hasSeenExperienceEventPayload))
          ),
          partitionKey: this.generatePartitionKey(eventKey),
          streamArn,
        });
      };

      if (!this.sessionId && this.elapsedTime < this.maxWaitTime) {
        this.queueEvent(sendEvent);
      } else {
        sendEvent();
      }
    }
  };

  protected onTrackComponent = async (properties): Promise<void> => {
    const { variant, audience, isPersonalized } = properties;

    const action = template(
      this.options.actionTemplate || 'Has Seen Experience',
      { component: variant, audience },
      TEMPLATE_OPTIONS.interpolate
    );

    const label = template(
      this.options.labelTemplate ||
        '{{ baselineOrVariant }}:{{ component.id }}',
      {
        component: variant,
        audience,
        baselineOrVariant: isPersonalized ? 'Variant' : 'Baseline',
      },
      TEMPLATE_OPTIONS.interpolate
    );

    const payload = {
      event: action,
      properties: {
        category: 'Ninetailed',
        label,
        nonInteraction: true,
      },
    };

    sendData({
      data: new Uint8Array(Buffer.from(JSON.stringify(payload))),
      partitionKey: this.generatePartitionKey(`${label}-${action}`),
      streamArn,
    });
  };
}
