import { useNinetailed } from '@ninetailed/experience.js-next';
import {
  Pet,
  PetType,
  UpdatePetPayload,
  createPet,
  getPets,
  updatePet,
} from 'api/pets';
import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  NutritionSegmentationIds,
  SegmentationIds,
  isAgeSegmentation,
  isBreedSegmentation,
  isNutritionSegmentation,
} from 'constants/segmentation';
import useSyncSegmentation from 'hooks/pet-profile/useSyncSegmentation';
import useDynamicTest from 'hooks/test/use-dynamic-ux';
import { DynamicTestScenario } from 'hooks/test/use-dynamic-ux/const';
import {
  InputProductType,
  isFoodType,
} from 'interfaces/api-input/input-product-type';
import debounce from 'lodash/debounce';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import convertProfileToArray from 'utils/pet-profile/convert-profile-to-array';
import { useAuth } from './auth';
import { useSegmentationContext } from './segmentation';

export interface PetProfile {
  id?: string;
  name?: string;
  selectedAge?: AgeSegmentationIds;
  selectedBreed?: BreedSegmentationIds;
  selectedNeeds?: Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  selectedAllergies?: Array<AllergySegmentationIds>;
  selectedFoodTypes?: Array<InputProductType>;
  /** For tracking last touched segmentation, allergy should be filtered out of from this property */
  lastTouched?: SegmentationIds;
  updatedAt?: string;
}

interface PetProfileContextType {
  isProfileMinimal: boolean;
  petProfile: PetProfile;
  setPetProfile: React.Dispatch<React.SetStateAction<PetProfile>>;
  updatePetProfile: (
    value: SegmentationIds | undefined,
    allergies?: AllergySegmentationIds[]
  ) => void;
}

const PetProfileContext = createContext<PetProfileContextType>(
  {} as PetProfileContextType
);

const INITIAL_STATE = {
  selectedAge: undefined,
  selectedAllergies: [],
  selectedBreed: undefined,
  selectedFoodTypes: [
    InputProductType.DryFood,
    InputProductType.WetFood,
    InputProductType.Snacks,
    InputProductType.Barf,
  ],
  selectedNeeds: [],
  lastTouched: undefined,
};

export const PetProfileProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const hasPetBeenCreatedRef = useRef(false);
  const { identify } = useNinetailed();
  const { customer } = useAuth();
  const { testScenario } = useDynamicTest();

  const [petProfileStorage, setPetProfileStorage] = useLocalStorage<PetProfile>(
    'pd:pet-profile',
    INITIAL_STATE
  );

  const { data: customerPets, mutate: revalidatePets } = useSWR(
    ['customerPets', customer?.id],
    () => (customer?.id ? getPets(customer?.id) : null)
  );

  const [petProfile, setPetProfile] = useState<PetProfile>({
    ...INITIAL_STATE,
    ...petProfileStorage,
  });

  // Check if the user has a 0 or 1 attribute in their pet profile
  const isProfileMinimal = useMemo(() => {
    return (
      convertProfileToArray({ petProfile }).filter((v) => !isFoodType(v))
        .length <= 1
    );
  }, [petProfile]);

  // Check if the user has no attribute in their pet profile
  const isProfileEmpty = useMemo(() => {
    return (
      convertProfileToArray({ petProfile }).filter((v) => !isFoodType(v))
        .length === 0
    );
  }, [petProfile]);

  const getTargetPet = (pets: Pet[]): Pet => {
    const nullNamePet = pets.find((pet) => pet.name === null);
    const mostRecentPet = pets.reduce((prev, curr) =>
      new Date(curr.updated_at).getTime() > new Date(prev.updated_at).getTime()
        ? curr
        : prev
    );
    return nullNamePet || mostRecentPet;
  };

  const debouncedUpdate = useMemo(
    () =>
      debounce(async (petData: UpdatePetPayload) => {
        try {
          await updatePet(petData);
          // Revalidate the SWR cache after update
          revalidatePets();
        } catch (error) {
          console.error('Failed to update pet:', error);
        }
      }, 500),
    [revalidatePets]
  );

  // Add a ref to track if we just synced from server
  const justSyncedRef = useRef(false);
  // Add a ref to track if we just created a pet
  const justCreatedPetRef = useRef(false);
  // Add a timestamp to track last update
  const lastUpdateTimestampRef = useRef(0);

  /**
   * Sync pet profile with the one from the server
   */
  useEffect(() => {
    if (customerPets && customerPets.length > 0) {
      const pet = getTargetPet(customerPets);

      // If we have a matching pet in local storage and petProfile
      if (petProfile.id === pet.id) {
        const localUpdatedAt = new Date(petProfile.updatedAt ?? 0).getTime();
        const serverUpdatedAt = new Date(pet.updated_at).getTime();

        // Only update from server if it's more recent
        if (serverUpdatedAt > localUpdatedAt) {
          justSyncedRef.current = true;
          setPetProfile((prevProfile) => ({
            ...prevProfile,
            id: pet.id,
            name: pet.name,
            ...(pet.age && { selectedAge: pet.age }),
            selectedAllergies: pet.allergies,
            ...(pet.breed && { selectedBreed: pet.breed }),
            selectedFoodTypes: pet.food_types,
            selectedNeeds: pet.needs,
            updatedAt: pet.updated_at,
          }));
        }
        return;
      }

      // If no matching pet in storage or isProfileEmpty, sync from server
      if (isProfileEmpty) {
        justSyncedRef.current = true;
        setPetProfile((prevProfile) => ({
          ...prevProfile,
          id: pet.id,
          name: pet.name,
          ...(pet.age && { selectedAge: pet.age }),
          selectedAllergies: pet.allergies,
          ...(pet.breed && { selectedBreed: pet.breed }),
          selectedFoodTypes: pet.food_types,
          selectedNeeds: pet.needs,
          updatedAt: pet.updated_at,
        }));
      }
    }
  }, [customerPets, isProfileEmpty, petProfile.id, petProfile.updatedAt]);

  const createInitialPet = useCallback(
    async ({
      customerId,
      petProfile,
    }: {
      customerId: string;
      petProfile: PetProfile;
    }) => {
      if (hasPetBeenCreatedRef.current) return; // Prevent multiple calls

      // Only create a pet in the BE if:
      // 1. testScenario is variantB or variantC
      const isValidTestScenario =
        testScenario === DynamicTestScenario.variantB ||
        testScenario === DynamicTestScenario.variantC;

      // 2. At least one meaningful attribute is set
      const hasProfileData =
        petProfile.selectedAge !== undefined ||
        petProfile.selectedBreed !== undefined ||
        (petProfile.selectedNeeds && petProfile.selectedNeeds.length > 0);

      // Only proceed if both conditions are met
      if (!isValidTestScenario || !hasProfileData) return;

      // Set the ref before the API call to prevent concurrent calls
      hasPetBeenCreatedRef.current = true;

      const pet = {
        // TODO: Update the logic for the pet type, as soon as we use pet profiles for both dogs and cats
        pet_type: PetType.Dog,
        age: petProfile.selectedAge,
        needs: petProfile.selectedNeeds,
        breed: petProfile.selectedBreed,
        allergies: petProfile.selectedAllergies,
        food_types: petProfile.selectedFoodTypes,
        customer_id: customerId,
      };

      try {
        await createPet(pet);
        // Set flag to prevent immediate update after creation
        justCreatedPetRef.current = true;
        // Track this as an update too
        lastUpdateTimestampRef.current = Date.now();
        // Revalidate the SWR cache to refresh customerPets
        revalidatePets();
      } catch (error) {
        console.error('Failed to create initial pet:', error);
        hasPetBeenCreatedRef.current = false;
      }
    },
    [testScenario, revalidatePets]
  );

  /**
   * Handle updates to server
   */
  useEffect(() => {
    if (!customer?.id || !customerPets || isProfileEmpty) return;
    if (justSyncedRef.current) {
      justSyncedRef.current = false;
      return;
    }
    // Skip update if we just created a pet
    if (justCreatedPetRef.current) {
      justCreatedPetRef.current = false;
      return;
    }

    // Prevent duplicate updates within 1 second - avoids the double call issue
    const now = Date.now();
    if (now - lastUpdateTimestampRef.current < 1000) return;

    if (customerPets.length > 0) {
      const nullNamePet = customerPets.find((pet) => pet.name === null);

      // If profile is not minimal and there's no unnamed pet, create one
      if (!isProfileEmpty && !nullNamePet) {
        createInitialPet({
          customerId: customer.id.toString(),
          petProfile,
        });
        return;
      }

      const targetPet = getTargetPet(customerPets);

      const currentPet: UpdatePetPayload = {
        id: targetPet.id,
        // TODO: Update the logic for the pet type, as soon as we use pet profiles for both dogs and cats
        pet_type: PetType.Dog,
        age: petProfile.selectedAge ?? null,
        needs: petProfile.selectedNeeds,
        breed: petProfile.selectedBreed ?? null,
        allergies: petProfile.selectedAllergies,
        food_types: petProfile.selectedFoodTypes,
      };
      lastUpdateTimestampRef.current = Date.now();
      debouncedUpdate(currentPet);

      // No need for additional revalidation here since debouncedUpdate already handles it
    } else {
      createInitialPet({
        customerId: customer.id.toString(),
        petProfile,
      });
    }
    return () => {
      debouncedUpdate.cancel();
    };
  }, [
    customer?.id,
    customerPets,
    debouncedUpdate,
    isProfileEmpty,
    petProfile,
    createInitialPet,
    revalidatePets,
  ]);

  /**
   * If the registered user has no pets, create an initial pet
   */
  useEffect(() => {
    if (customer?.id && customerPets?.length === 0) {
      createInitialPet({
        customerId: customer.id.toString(),
        petProfile,
      });
    }
  }, [customer, customerPets, petProfile, createInitialPet]);

  /**
   * Sync pet profile with the local storage, in case the user has not an account
   */
  useEffect(() => {
    setPetProfileStorage(petProfile);
  }, [petProfile, setPetProfileStorage]);

  /**
   * Update the pet profile with a new value based on the segmentation id
   */
  const updatePetProfile = (
    value: SegmentationIds | undefined,
    allergies: AllergySegmentationIds[] | undefined
  ): void => {
    if (!value) return;

    const updateNeedsAndAllergies = (
      prevProfile: PetProfile,
      needs: (NutritionSegmentationIds | CatNutritionSegmentationIds)[],
      value: SegmentationIds,
      allergies: AllergySegmentationIds[] | undefined
    ): PetProfile => {
      if (
        !needs.includes(
          value as NutritionSegmentationIds | CatNutritionSegmentationIds
        ) &&
        value !== NutritionSegmentationIds.Intolerance
      ) {
        return {
          ...prevProfile,
          selectedNeeds: [
            ...needs,
            value as NutritionSegmentationIds | CatNutritionSegmentationIds,
          ],
        };
      } else if (
        value === NutritionSegmentationIds.Intolerance ||
        (needs.includes(value as NutritionSegmentationIds) &&
          allergies &&
          allergies.length > 0)
      ) {
        const selectedAllergies = new Set(prevProfile.selectedAllergies ?? []);
        if (allergies) {
          allergies.forEach((allergy) => selectedAllergies.add(allergy));
        }

        return {
          ...prevProfile,
          ...(needs.includes(value as NutritionSegmentationIds)
            ? { selectedNeeds: needs }
            : {
                selectedNeeds: [...needs, value as NutritionSegmentationIds],
              }),
          ...(selectedAllergies.size > 0 && {
            selectedAllergies: Array.from(selectedAllergies),
          }),
        };
      }
      return prevProfile;
    };

    setPetProfile((prevProfile) => {
      const needs = prevProfile.selectedNeeds ?? [];

      if (isAgeSegmentation(value)) {
        return { ...prevProfile, selectedAge: value as AgeSegmentationIds };
      }

      if (isBreedSegmentation(value)) {
        return { ...prevProfile, selectedBreed: value as BreedSegmentationIds };
      }

      if (isNutritionSegmentation(value)) {
        return updateNeedsAndAllergies(prevProfile, needs, value, allergies);
      }

      return prevProfile;
    });
  };

  /**
   * Sync segmentation to Ninetailed
   */
  useEffect(() => {
    const petProfileArray = convertProfileToArray({ petProfile });
    identify(`${customer ? customer.customerId : ''}`, {
      petProfile: petProfileArray,
    });
  }, [customer, identify, petProfile]);

  const { pushSegmentation, multipleSegmentation } = useSegmentationContext();
  useSyncSegmentation({
    petProfile,
    multipleSegmentation,
    pushSegmentation,
  });

  const value = {
    isProfileMinimal,
    petProfile,
    setPetProfile,
    updatePetProfile,
  };

  return (
    <PetProfileContext.Provider value={value}>
      {children}
    </PetProfileContext.Provider>
  );
};

export const usePetProfile = (): PetProfileContextType =>
  useContext(PetProfileContext);
