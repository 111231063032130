// The structure of the keys is not the same as the others because they were imported from a yml file.
// Apparently this was a legacy project using a different structure.

export default {
  next: 'Weiter',
  back: 'Zurück',
  kcal: 'kcal',
  gram: 'g',
  or: 'oder',
  and: 'und',
  andIs: 'und ist',
  orWeight: 'und hat',
  weightKg:
    'Keine Sorge, solltest du es nicht genau wissen, reicht auch eine Schätzung.',
  year: 'Jahr',
  years: 'Jahre',
  month: 'Monat',
  months: 'Monate',
  'indefiniteArticle.male': 'ein',
  'indefiniteArticle.female': 'eine',
  'definiteArticle.male': 'der',
  'definiteArticle.female': 'die',
  'pronoun.male': 'er',
  'pronoun.female': 'sie',
  'possessivePronoun.male': 'sein',
  'possessivePronoun.female': 'ihr',
  'genderSuffix.male': 'er',
  'genderSuffix.female': 'e',
  'month.1': 'Januar',
  'month.2': 'Februar',
  'month.3': 'März',
  'month.4': 'April',
  'month.5': 'Mai',
  'month.6': 'Juni',
  'month.7': 'Juli',
  'month.8': 'August',
  'month.9': 'September',
  'month.10': 'Oktober',
  'month.11': 'November',
  'month.12': 'Dezember',
  'petName.message':
    'Erzähl uns gern mehr über dein Haustier, damit wir dir das Produkt empfehlen können, welches am besten zu euch passt.',

  'input.petName.placeholder': 'Name eingeben',
  'input.petName.hint.min': 'Min. zwei Buchstaben',

  'input.dateOfBirth.description':
    'Keine Sorge, solltest du es nicht genau wissen, reicht auch eine Schätzung.',

  'input.weightKg.placeholder': 'Gewicht in kg',

  'input.allergies.description':
    '<b>Wieso ist das wichtig?</b> So wissen wir, welche Lebensmittel in unserer Empfehlung vermieden werden müssen.',
  'input.allergies.opt.beefordairy': 'Rindfleisch oder Milchprodukte',
  'input.allergies.opt.fish': 'Fisch',
  'input.allergies.opt.grains': 'Getreide',
  'input.allergies.opt.duck': 'Ente',
  'input.allergies.opt.chickenoreggs': 'Hühnchen oder Ei',
  'input.allergies.opt.lamb': 'Lamm',
  'input.allergies.opt.potato': 'Kartoffeln',
  'input.allergies.opt.horse': 'Pferd',
  'input.allergies.opt.goat': 'Ziege',
  'input.allergies.opt.turkey': 'Pute',
  'input.allergies.opt.none': 'Keine bekannt',

  'input.otherAllergies.opt.kangaroo': 'Känguru',
  'input.otherAllergies.opt.deer': 'Hirsch',
  'input.otherAllergies.opt.buffalo': 'Büffel',
  'input.otherAllergies.opt.wildBoar': 'Wildschwein',
  'input.otherAllergies.opt.insects': 'Insekten',
  'input.otherAllergies.opt.sweetpotato': 'Süßkartoffeln',
  'input.otherAllergies.opt.peas': 'Erbsen',
  'input.otherAllergies.opt.pumpkin': 'Kürbis',
  'input.otherAllergies.opt.carrot': 'Karotten',
  'input.otherAllergies.opt.parsnip': 'Pastinake',
  'input.otherAllergies.opt.zuchini': 'Zucchini',
  'input.otherAllergies.opt.broccoli': 'Brokkoli',
  'input.otherAllergies.opt.amaranth': 'Amaranth',
  'input.otherAllergies.opt.quinoa': 'Quinoa',
  'input.otherAllergies.opt.buckwheat': 'Buchweizen',
  'input.otherAllergies.opt.other': 'Die Allergie wird hier nicht angezeigt',
  'input.otherAllergies.placeholder': 'Andere, hier eintragen',

  'input.foodTypePreference.description':
    'Du kannst mehrere Möglichkeiten auswählen. Entsprechend Deiner Auswahl stellen wir Dir ein passendes Probierpaket für {petName} zusammen. ',
  'input.foodTypePreferences.opt.barf': 'BARF',
  'input.foodTypePreferences.opt.wetfood': 'Nassfutter',
  'input.foodTypePreferences.opt.dryfood': 'Trockenfutter',
  'input.foodTypePreferences.opt.filetmenu': 'mit Filetstückchen',
  'input.foodTypePreferences.opt.mousse': 'lockere Mousse Konsistenz',
  'input.foodTypePreferences.opt.puremeat': 'viel Fleisch',
  'input.foodTypePreferences.opt.saucenmenu': 'Menu mit viel Sauce',
  'input.foodTypePreferences.opt.none': 'Unentschlossen',

  'input.foodTypePreferences.message':
    'Mehrere Optionen können ausgewählt werden. Wenn du unsicher bist oder keine Vorlieben hast, wähle alle Optionen aus.',
  'input.foodTypePreferences.description':
    '<b>Wieso ist das wichtig?</b> So können wir bei der Futterwahl auf bestimmte Vorlieben eingehen.',

  'input.catType.opt.indoor': 'Eine reine Wohnungskatze bzw. ein Wohnungskater',
  'input.catType.opt.outdoor': 'Freigänger/in',
  'input.isSpecificDisease.title': '',
  'input.isMeatIntolerance.title': '',
  'input.breed.description':
    '<b>Wieso ist das wichtig?</b> Manche Rassen haben spezielle Bedürfnisse, die wir bei unserer Futterempfehlung berücksichtigen möchten.',
  'input.breed.placeholder': 'Hier Rasse eingeben',
  'checkbox:breed': 'Weiß ich nicht',

  'input.illnesses.description':
    '<b>Wieso ist das wichtig?</b> So können wir das passende Futter für {petText} finden.',
  'input.illnesses.opt.low purine': 'Purinarm',
  'input.illnesses.opt.diabetes': 'Diabetes',
  'input.illnesses.opt.osteoarthritis': 'Gelenke',
  'input.illnesses.opt.storage mites': 'Futtermilben',
  'input.illnesses.opt.struvite stones': 'Struvitsteine',
  'input.illnesses.opt.renal or liver failure': 'Nieren oder Leber',
  'input.illnesses.opt.gastro intestinal issues or pancreatitis':
    'Magendarm / Pankreatitis',
  'input.illnesses.opt.other': 'Nicht gelistet',
  'input.illnesses.opt.none': 'Keine bekannt',

  'input.weightGoal.opt.lose': 'Untergewicht',
  'input.weightGoal.opt.maintain': 'Idealgewicht',
  'input.weightGoal.opt.gain': 'Übergewicht',

  'input.name.description':
    'Damit wir Dir eine individuelle Füttungsempfehlung für {petName} zukommen lassen können, lass uns wissen wie Du heißt.',
  'input.ownerFirstName.placeholder': 'Vorname',
  'input.ownerFirstName.label': 'Dein Vorname',
  'input.ownerFirstName.hint.min': 'Das sieht ein bischen kurz aus.',
  'input.ownerLastName.label': 'Dein Nachname',
  'input.ownerLastName.placeholder': 'Nachname',
  'input.ownerLastName.hint.min': 'Das sieht ein bischen kurz aus.',

  'input.ownerEmail.placeholder': 'deine@email.de',
  'input.ownerEmail.description':
    'Yay! Wir haben das Richtige für {petText} gefunden! Jetzt fehlt nur noch deine E-Mail, um dir deine Ergebnisse zu schicken.',
  'input.ownerEmail.hint.email': 'Bitte eine gültige E-Mail Adresse angeben',
  'input.neutered.opt.true': 'Ja',
  'input.neutered.opt.false': 'Nein',
  'input.gender.opt.female': 'Weibchen',
  'input.gender.opt.male': 'Männchen',

  'evaluation.title': 'Persönlicher Futterplan für <strong>{petName}</strong>',
  'evaluation.loading': 'Lädt...',
  'evaluation.description':
    '{petName} benötigt durchschnittlich ca. {foodTypes} der ausgewählten Pets Deli Sorten. Dieser Futterbedarf ergibt sich aus den Energie- und Nährwerten der ausgewählten Pets Deli Sorten.',
  'evaluation.table.dailyKcal': '{petName} Kalorienbedarf',
  'evaluation.table.factor': 'Faktor',
  'evaluation.table.caloryRequirements': 'Kalorien',
  'evaluation.table.RER': 'Energieverbrauch in Ruhe',
  'evaluation.table.RER.description':
    'Damit wird der Grundumsatz Deines Vierbeiners beschrieben, d.h. soviel Energie verbrennt Dein Tier ungefähr in Ruhe bzw. bei wenig bis keinerlei Aktivität.',
  'evaluation.table.neutered': 'Sterilisierung',
  'evaluation.table.neutered.description':
    'Bei sterilisierten Tieren senkt sich etwas die Hormonaktivität und dadurch verlangsamt sich auch der Stoffwechsel. Dadurch verbraucht Dein Vierbeiner etwas weniger Energie, sprich Nahrung, als ein unsterilisiertes Tier.',
  'evaluation.table.temper': 'Temperament',
  'evaluation.table.temper.description':
    'Das Temperament spiegelt maßgeblich das Aktivitätslevel eines Haustieres wider. Je nachdem wieviel sich Deine pelzige Fellnase bewegt, wird mehr oder weniger Energie zur Aufrechterhaltung der Körperfunktion benötigt.',
  'evaluation.foodType.barf': 'BARF',
  'evaluation.foodType.wetfood': 'Nassfutter',
  'evaluation.foodType.dryfood': 'Trockenfutter',
  'evaluation.foodType.none': 'Unentschlossen',
  'evaluation.suggestionsTitle': 'Und wir empfehlen außerdem:',
  'evaluation.suggestionsSubtitle':
    'Füge einfach die für Dich passenden Produkte Deinem&nbsp;Warenkorb&nbsp;hinzu',
  'evaluation.button.trySelection': 'Auswahl probieren!',
  'evaluation.otherPopularProducts.title': 'Weitere beliebte Produkte',
  'suggestions.title': 'Unsere Empfehlung für {petName}:',
  'suggestion.title': 'Unsere Empfehlung',
  'product.more': 'Mehr Erfahren',
  'product.toTry': 'Zum Kennenlernen',
  'product.deliveryTime': '1-2 Tage',
  'product.add': 'In den Warenkorb',
  'results.temper.lazy': 'weniger aktiv',
  'results.temper.quiet': 'ruhig',
  'results.temper.average': 'mittelgroß',
  'results.temper.active': 'aktiv',
  'results.temper.restless': 'sehr aktiv',
  'results.neutered.female': 'kastrierte',
  'results.neutered.male': 'kastrierter',
  'results.description':
    '{petName} ist {indefiniteArticle} {temper}{genderSuffix}{neutered} {breed}. Für eine ausgewogene Ernährung benötigt {pronoun} ca. {kcal} kCal pro Tag, um {possesivePronoun} Gewicht zu halten. Das entspricht ca. {foodTypes}.',
  'results.table.title': '{possessivePetName} Bedarf',
  'results.table.subtitle': 'auf Basis der angegeben Informationen',
  'results.next': 'Zu deinen Produkten',

  'wizard.title.petName': 'Wie heisst Dein Vierbeiner?',
  'wizard.title.breed': 'Welcher Rasse gehört {petName} an?',
  'wizard.title.dateOfBirth': 'Wann wurde {petName} geboren?',
  'wizard.title.weightKg': 'Wie viel wiegt {petName}?',
  'wizard.title.allergies':
    'Ist eine Futtermittelallergie bekannt? Wenn ja, welche:',
  'wizard.title.illnesses': 'Womit hat {petName} ein Problem?',
  'wizard.title.foodTypePreferences': 'Was soll {petName} bekommen?',
  'wizard.title.ownerEmail': 'Wie lautet deine E-mail-Adresse?',
  'wizard.title.neutered': 'Ist {petName} kastriert?',
  'wizard.title.gender': 'Welches Geschlecht hat {petName}?',

  'foodaid:page-title': 'Futterberater',

  'foodaid:questions:policy-1':
    'Mit dem Absenden Deiner Daten erklärst Du Dich mit unseren',
  'foodaid:questions:policy-2': 'AGB',
  'foodaid:questions:policy-3': 'Datenschutzbestimmungen',
  'foodaid:questions:policy-4':
    'einverstanden, sowie damit gelegentlich von Pets Deli auf passende Produkte für Deinen Vierbeiner hingewiesen zu werden.',

  'foodaid:evaluation:item-added-to-cart': '✓ HINZUGEFÜGT!',
  'foodaid:evaluation:sub-title':
    'Lege einfach die für dich passenden Produkte in den Warenkorb!',
  'foodaid:evaluation:results':
    '{count, number} {count, plural, one {Ergebnis} other {Ergebnisse}}',
  'foodaid:evaluation:our-categories': 'Unsere Kategorie',

  'foodaid:results:extended-title': 'Passende Produkte für {petName}',
  'foodaid:results:title': 'Passende Produkte',
  'foodaid:lazy-input:pet-name-input-dog': 'Name deines Doggos',
  'foodaid:lazy-input:pet-name-input-cat': 'Name deines Mieze',
  'foodaid:lazy-input:create-nutrition-plan': 'Starten',
  'foodaid:support:title':
    'Bei uns, bekommen besondere Tiere eine personalisierte Beratung!',
  'foodaid:support:description':
    'Es sieht so aus, als hätte {petName} bestimmte Bedürfnisse. Unsere Experten sind für euch da! Kontaktiere uns über:',
  'foodaid:support:schedule-appointment':
    'Unsere Bearter:innen melden sich bei dir / Unsere Berater:innen rufen dich zum vereinbarten Termin an',
  'foodaid:support:button:phone': 'Telefon',
  'foodaid:support:button:email': 'Email',
  'foodaid:support:button:whatsapp': 'WhatsApp',
  'foodaid:support:button:home': 'Home',

  'foodaid:loading-screen:loading': 'Loading...',
  'foodaid:loading-screen:title':
    'Wir sind gleich mit dem perfekten Futter für {petName} da!',
  'foodaid:loading-screen:description-1':
    'Während du wartest: Wusstest du, dass manche Hunde schneller sind als Geparden?',
  'foodaid:loading-screen:description-2':
    'Der Geruchssinn eines Hundes ist mindestens 40x besser als unserer.',
  'foodaid:questions:case-data:petName': 'Name',
  'foodaid:questions:case-data:allergies': 'Futtermittelallergie',
  'foodaid:questions:case-data:otherAllergies': 'Weitere Allergien',
  'foodaid:questions:case-data:breed': 'Rasse',
  'foodaid:questions:case-data:dateOfBirth': 'Geburtsdatum',
  'foodaid:questions:case-data:weightKg': 'Gewicht',
  'foodaid:questions:case-data:weightGoal': 'Gewichtsziel',
  'foodaid:questions:case-data:illnesses': 'Krankheiten',
  'foodaid:questions:case-data:foodTypePreferences': 'Futterart',
  'foodaid:questions:case-data:ownerEmail': 'E-Mail',
  'foodaid:questions:case-data:gender': 'Geschlecht',
  'foodaid:questions:case-data:neutered': 'Kastriert',

  'foodaid:nutritional-plan:header:title': 'Ganz nach eurem Geschmack',
  'foodaid:nutritional-plan:header:sub-title':
    'Ihr habt spezielle Bedürfnisse - wir haben das passende Futter. Lass uns zusammen das perfekte Menü für {petName} erstellen.',
  'foodaid:nutritional-plan:header:pet-name': '{petName} benötigt',
  'foodaid:nutritional-plan:header:kcalsperday': '{kcalsPerDay} kcal/Tag',
  'foodaid:nutritional-plan:header:icon-title-1': 'Passend für {petName}',
  'foodaid:nutritional-plan:header:icon-title-2':
    ' Frische Zutaten für den besten Geschmack',
  'foodaid:nutritional-plan:header:icon-title-3': 'Ohne Getreide & Zucker',
  'foodaid:nutritional-plan:product-description':
    'Produktinformationen, Zutaten, Vitamine, Mengen & Spurenelemente und Fütterungsempfehlungen <a>anzeigen</a>',
  'foodaid:nutritional-plan:added-to-cart': 'In den Warenkorb',
  'foodaid:nutritional-plan:in-cart': 'Im Warenkorb',
  'foodaid:nutritional-plan:remove-product': 'Entfernen',
};
