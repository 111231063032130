import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
} from 'react';
import { useSessionStorage } from 'react-use';

interface TrackingContextType {
  trackedExperiences: Set<string>;
  addTrackedExperience: (trackingKey: string) => void;
}

const ExperienceTrackingContext = createContext<TrackingContextType>({} as any);

const TRACKED_EXPERIENCES_KEY = 'pd:tracked-experiences';

/**
 * A context used to track list names for performance analysis purposes
 */
export const ExperienceTrackingContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [trackedExperiencesStorage, setTrackedExperiencesStorage] =
    useSessionStorage<string[]>(TRACKED_EXPERIENCES_KEY, []);

  const trackedExperiences = useMemo(
    () => new Set(trackedExperiencesStorage),
    [trackedExperiencesStorage]
  );

  // Use a ref to track if we're in the middle of an update
  const isUpdatingRef = useRef(false);

  /**
   * Adds a tracking key to the list of tracked experiences.
   */
  const addTrackedExperience = useCallback(
    (trackingKey: string): void => {
      // Prevent state updates during render
      if (isUpdatingRef.current) return;

      if (!trackedExperiences.has(trackingKey)) {
        isUpdatingRef.current = true;
        // Schedule the state update
        Promise.resolve().then(() => {
          // Instead of using a function updater, compute the new value directly
          const newValue = trackedExperiencesStorage?.includes(trackingKey)
            ? trackedExperiencesStorage
            : [...(trackedExperiencesStorage || []), trackingKey];

          setTrackedExperiencesStorage(newValue);
          isUpdatingRef.current = false;
        });
      }
    },
    [
      trackedExperiences,
      trackedExperiencesStorage,
      setTrackedExperiencesStorage,
    ]
  );

  const contextValue = useMemo(
    () => ({
      trackedExperiences,
      addTrackedExperience,
    }),
    [trackedExperiences, addTrackedExperience]
  );

  return (
    <ExperienceTrackingContext.Provider value={contextValue}>
      {children}
    </ExperienceTrackingContext.Provider>
  );
};

export const useExperienceTrackingContext = (): TrackingContextType =>
  useContext(ExperienceTrackingContext);
