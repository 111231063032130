import { CommonCategories } from 'config/types';

export default {
  dogs: 'hunde',
  cats: 'katzen',
  dog: 'hunde',
  cat: 'katze',
  puppy: 'puppy',
  pup: 'welpe',
  kitten: 'kitten',
  catShort: 'katz',
  forDogs: 'fur-hunde',
  forCats: 'fur-katzen',
  wetFood: 'Nassfutter',
  dryFood: 'Trockenfutter',
  barf: 'Frische-Menüs',
  snacks: 'Snacks',
  supplements: 'Nahrungsergänzung',
  health: 'Med',
  accessories: 'Zubehör',
  care: 'Pflege',
  bundles: 'Vorteilspakete',
  barfTastingPacks: 'Frische-Menü-Probierpakete',
  notMov: 'notmov',
  bundle: 'Bundle',
} as CommonCategories;
