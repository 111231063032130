import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCart } from '../../contexts/cart';
import useMOVConditions from '../../hooks/common/use-mov-conditions';
import { showErrorToast } from '../../utils/toasts';

export const useCartState = () => {
  const {
    loading: checkoutLoading,
    canUseCoupon,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsSubscriptionItem,
    discount,
    effectiveCart,
    effectiveCartErrors,
    isCartEmpty,
    isCartUpdating,
    isCheckoutBeingCreated,
    items,
    itemsCount,
    oosItems,
    setCouponCode,
    goToCheckout,
  } = useCart();

  const {
    insufficientMoney,
    minimumBasketValue,
    shouldShowMinimumBasketNotice,
  } = useMOVConditions({ effectiveCart });

  const { isReady, query } = useRouter();

  useEffect(() => {
    if (!isReady) return;

    const error = Array.isArray(query['error'])
      ? query['error'][0]
      : query['error'];
    const source = Array.isArray(query['source'])
      ? query['source'][0]
      : query['source'];
    const interactionReason = Array.isArray(query['interactionReason'])
      ? query['interactionReason'][0]
      : query['interactionReason'];

    if (interactionReason === 'DECLINED') {
      showErrorToast({
        error: 'error:express-checkout:common-error',
        caller: 'useCartState',
      });
      return;
    }

    if (error === 'InvalidShippingAddress' && source === 'express-checkout') {
      showErrorToast({
        error: 'error:express-checkout:invalid-address',
        caller: 'useCartState',
      });
      return;
    }

    if (error && source === 'express-checkout') {
      showErrorToast({
        error: 'error:express-checkout:common-error',
        caller: 'useCartState',
      });
    }
  }, [isReady, query]);

  return {
    canUseCoupon,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsSubscriptionItem,
    discount,
    effectiveCart,
    effectiveCartErrors,
    insufficientMoney,
    isCartEmpty,
    isCartUpdating,
    isCheckoutBeingCreated,
    items,
    itemsCount,
    loading: checkoutLoading,
    minimumBasketValue,
    oosItems,
    shouldShowMinimumBasketNotice,
    setCouponCode,
    goToCheckout,
  };
};
