import axios from 'axios';
import {
  SHOPIFY_ACTIVATE_ACCOUNT,
  SHOPIFY_RECOVER_CUSTOMER,
  SHOPIFY_RESET_CUSTOMER,
} from 'queries/auth';
import {
  CREATE_CHECKOUT,
  FETCH_CHECKOUT,
  UPDATE_CHECKOUT_LINE_ITEMS,
} from 'queries/checkout';
import {
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutLineItemsReplaceMutation,
  CheckoutLineItemsReplaceMutationVariables,
  CustomerActivateByUrlMutation,
  CustomerActivateByUrlMutationVariables,
  CustomerRecoverMutation,
  CustomerRecoverMutationVariables,
  CustomerResetByUrlMutation,
  CustomerResetByUrlMutationVariables,
  FetchCheckoutQuery,
  FetchCheckoutQueryVariables,
} from 'shopifyTypes';

/** Fetch a existing checkout data. */
export const fetchShopifyCheckout = async ({
  id,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  id: FetchCheckoutQueryVariables['id'];
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<FetchCheckoutQuery> =>
  queryGraphql<FetchCheckoutQuery, FetchCheckoutQueryVariables>({
    query: FETCH_CHECKOUT,
    shopifyAccessToken,
    shopifyGraphqlEndpoint,
    variables: { id },
  }).then((data) => {
    if (data.node?.__typename !== 'Checkout') {
      throw new Error(`Unexpected type : ${data.node?.__typename}`);
    }
    return data;
  });

/** Creates a new checkout. */
export const createShopifyCheckout = async ({
  input,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  input: CheckoutCreateMutationVariables['input'];
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<CheckoutCreateMutation> =>
  queryGraphql<CheckoutCreateMutation, CheckoutCreateMutationVariables>({
    query: CREATE_CHECKOUT,
    shopifyAccessToken,
    shopifyGraphqlEndpoint,
    variables: { input },
  });

/** Sets a list of line items to a checkout. */
export const updateShopifyCheckoutItems = async ({
  variables,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  variables: CheckoutLineItemsReplaceMutationVariables;
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<CheckoutLineItemsReplaceMutation> =>
  queryGraphql<
    CheckoutLineItemsReplaceMutation,
    CheckoutLineItemsReplaceMutationVariables
  >({
    query: UPDATE_CHECKOUT_LINE_ITEMS,
    shopifyAccessToken,
    shopifyGraphqlEndpoint,
    variables,
  });

/** Resets a customer’s password with the reset password URL received from a reset password email.  */
export const resetShopifyCustomer = async ({
  variables,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  variables: CustomerResetByUrlMutationVariables;
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<CustomerResetByUrlMutation> =>
  queryGraphql<CustomerResetByUrlMutation, CustomerResetByUrlMutationVariables>(
    {
      query: SHOPIFY_RESET_CUSTOMER,
      shopifyAccessToken,
      shopifyGraphqlEndpoint,
      variables,
    }
  );

/** Sends a reset password email to the customer. The reset password email contains a reset password URL and token that you can pass to the customerResetByUrl or customerReset mutation to reset the customer password. */
export const recoverShopifyCustomer = async ({
  variables,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  variables: CustomerRecoverMutationVariables;
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<CustomerRecoverMutation> =>
  queryGraphql<CustomerRecoverMutation, CustomerRecoverMutationVariables>({
    query: SHOPIFY_RECOVER_CUSTOMER,
    shopifyAccessToken,
    shopifyGraphqlEndpoint,
    variables,
  });

/** Activates a customer with the activation url received from customerCreate. */
export const activateShopifyCustomer = async ({
  variables,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
}: {
  variables: CustomerActivateByUrlMutationVariables;
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
}): Promise<CustomerActivateByUrlMutation> =>
  queryGraphql<
    CustomerActivateByUrlMutation,
    CustomerActivateByUrlMutationVariables
  >({
    query: SHOPIFY_ACTIVATE_ACCOUNT,
    shopifyAccessToken,
    shopifyGraphqlEndpoint,
    variables,
  });

const queryGraphql = <Type = any, Variables = Record<string, any>>({
  query,
  shopifyAccessToken,
  shopifyGraphqlEndpoint,
  variables,
}: {
  query: string;
  shopifyAccessToken: string;
  shopifyGraphqlEndpoint: string;
  variables?: Variables;
}): Promise<Type> =>
  axios
    .post(
      shopifyGraphqlEndpoint,
      { query, variables },
      {
        headers: {
          'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
        },
      }
    )
    /**
     * @TODO : should return data not data.data
     * because some errors are returned on data.errors
     * */
    .then(({ data }) => data?.data);
