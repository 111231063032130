import IconExpand from 'assets/icons/expand-more.svg';
import cn from 'classnames';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement } from 'tracking';

const TRACKING_NAME = 'Collection Filter Sort Select';

export const SortOptions = {
  placeholder: 'placeholder',
  relevance: 'relevance',
  rating: 'rating',
  'price-asc': 'price-asc',
  'price-desc': 'price-desc',
  acceptance: 'acceptance',
} as const;

export type SortOptions = (typeof SortOptions)[keyof typeof SortOptions];

const sortOptions = Object.entries(SortOptions).map(([key, value]) => ({
  value,
  label: <FormattedMessage id={`cdp:filter:sort:${key}`} />,
}));

interface SortSelectMobileProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {}

/**
 * Sort select for mobile.
 * No actual sorting is done here, just a tracking event is triggered.
 * @see https://petsdeli.atlassian.net/browse/PT-6960
 */
const SortSelectMobile = ({
  onChange,
  className,
  ...props
}: SortSelectMobileProps) => {
  const [sort, setSort] = useState<string>(SortOptions.placeholder);

  const _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(e.target.value);
    onChange && onChange(e);
  };

  useEffect(() => {
    if (sort === SortOptions.placeholder) {
      return;
    }

    gtm(
      selectElement({
        element: [TRACKING_NAME, `Choose Customer Sort: ${sort}`],
      })
    );
  }, [sort]);

  return (
    <ImpressionTrackerNew element={TRACKING_NAME}>
      <span className={cn('relative inline-block', className)}>
        <select
          className={cn(
            'w-full appearance-none rounded-full border border-other-divider px-4 py-2 pr-8',
            className
          )}
          onChange={_onChange}
          value={sort}
          {...props}
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <IconExpand className="absolute right-4 top-1/2 h-4 w-4 -translate-y-1/2" />
      </span>
    </ImpressionTrackerNew>
  );
};

export default SortSelectMobile;
