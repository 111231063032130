const getContentfulLocale = (locale?: string): string => {
  switch (locale) {
    case undefined:
      return 'de';
    default:
      return locale;
  }
};

// for the initial call we need to make sure to pass no locale for DE-CH because they are not always translated
const getInitialContentfulLocale = (locale?: string): string | undefined => {
  if (locale === 'de-CH') {
    return undefined;
  }

  return locale;
};

export { getInitialContentfulLocale };

export default getContentfulLocale;
