import {
  AgeSegmentationIds,
  AllergySegmentationIds,
  BreedSegmentationIds,
  CatNutritionSegmentationIds,
  NutritionSegmentationIds,
} from 'constants/segmentation';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { axios } from 'utils/axios';

export const PetType = {
  Dog: 'dog',
  Cat: 'cat',
} as const;

export interface Pet {
  id?: string;
  name?: string;
  // Sending null to the server will remove the value
  age?: AgeSegmentationIds | null;
  needs?: Array<NutritionSegmentationIds | CatNutritionSegmentationIds>;
  // Sending null to the server will remove the value
  breed?: BreedSegmentationIds | null;
  allergies?: Array<AllergySegmentationIds>;
  food_types?: Array<InputProductType>;
  pet_type: (typeof PetType)[keyof typeof PetType];
  updated_at: string;
}

export type CreatePetPayload = Omit<Pet, 'id' | 'updated_at'>;
export type UpdatePetPayload = Omit<Pet, 'updated_at'>;

/**
 * Fetches all pets for a given customer
 */
export const getPets = (customerId: number): Promise<Pet[]> =>
  axios
    .get(`/api/legacy/pets?customer_id=${customerId}`, {
      isPdexApi: true,
    })
    .then(({ data }) => data);

/**
 * Fetches a single pet by ID
 */
export const getPet = (petId: string): Promise<Pet> =>
  axios
    .get(`/api/legacy/pets/${petId}`, {
      isPdexApi: true,
    })
    .then(({ data }) => data);

/**
 * Creates a new pet
 */
export const createPet = (pet: CreatePetPayload): Promise<Pet> =>
  axios
    .post(
      '/api/legacy/pets',
      { pet },
      {
        isPdexApi: true,
      }
    )
    .then(({ data }) => data);

/**
 * Updates an existing pet
 */
export const updatePet = (pet: UpdatePetPayload): Promise<Pet> =>
  axios.put(
    `/api/legacy/pets/${pet.id}`,
    { pet },
    {
      isPdexApi: true,
    }
  );

/**
 * Deletes a pet by ID
 */
export const deletePet = (petId: string): Promise<void> =>
  axios.delete(`/api/legacy/pets/${petId}`, {
    isPdexApi: true,
  });
