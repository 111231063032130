import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { captureMessage } from '@sentry/browser';
import { track } from 'api/track';
import ApplePayButton from 'components/apple-pay-button';
import ModalSlideUp from 'components/modal-slide-up';
import config, { useConfig } from 'config';
import useApplyCoupon from 'hooks/coupon/use-apply-coupon';
import get from 'lodash/get';
import CouponModalPopUp from 'modules/checkout/components/coupon-modal-pop-up';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocalStorage, useSessionStorage } from 'react-use';
import { Gtm, gtm } from 'tracking/gtm';
import { showErrorToast } from 'utils/toasts';
import { SubscriptionInterval } from '../constants/order';
import {
  CreateEffectiveCartPayloadInput,
  EffectiveCartPayload,
  useEffectiveCart,
  useEffectiveCartPayload,
  useEffectiveCartPayloadItems,
} from '../hooks/effective-cart/useEffectiveCart';
import { EffectiveCart, SubscriptionElement } from '../interfaces/cart';
import {
  PdProductItem,
  isGiftProductItem,
  isSubscriptionItem,
} from '../interfaces/line-item';
import { Discount, DiscountType } from '../interfaces/order';
import { ShippingMethod } from '../interfaces/shipping';
import { useAuth } from './auth';
import { useTrackingContext } from './tracking';
import {
  ActionType,
  BasicCartItemInput,
  CartItemFunctionInput,
  CartItemInput,
  RedemptionCartItemInput,
  SubCartItemInput,
  isBasicCartItemInput,
  isRedemptionCartItemInput,
  isSubCartItemInput,
  isTargetPropertyIntervalType,
  isTargetPropertyRedemptionType,
} from './type/cart-type';

type CartResponse = any;

export interface EffectiveCartSubscriptionItem extends SubscriptionElement {
  items: PdProductItem[];
}

export interface CartContextInterface {
  canUseCoupon: boolean;
  cartTotal: number;
  containsPreorderItem: boolean;
  containsPreorderItemsOnly: boolean;
  containsSubscriptionItem: boolean;
  containsRedemptionItem: boolean;
  couponCode: string | null | undefined;
  discount?: Discount;
  effectiveCart: EffectiveCart | undefined;
  effectiveCartErrors?: EffectiveCart['errors'];
  effectiveCartPayload: EffectiveCartPayload;
  effectiveCartPayloadItems: CreateEffectiveCartPayloadInput['items'];
  isBonusModalOpen: boolean;
  isCartEmpty: boolean;
  isCartReady: boolean;
  groupedItems: {
    subscriptionItems: EffectiveCartSubscriptionItem[];
    oneOffPurchaseItems: EffectiveCart['oneOffPurchaseItems'];
  };
  isCartUpdating: boolean;
  isCheckoutBeingCreated: boolean;
  items: EffectiveCart['items'];
  itemsCount: number;
  loading: boolean;
  shippingMethod: ShippingMethod | null;
  useCredits: boolean;
  oosItems: PdProductItem[];
  addProductsToCart: (arg: Array<BasicCartItemInput>) => Promise<CartResponse>;
  addProductToCart: (arg: BasicCartItemInput) => Promise<CartResponse>;
  addSubItemToCart: (arg: SubCartItemInput) => Promise<CartResponse>;
  addSubItemsToCart: (arg: Array<SubCartItemInput>) => Promise<CartResponse>;
  addRedemptionGiftToCart: (input: RedemptionCartItemInput) => Promise<void>;
  goToCheckout: () => Promise<any>;
  refetchCart: () => void;
  reFetchEffectiveCart: () => Promise<EffectiveCart | undefined>;
  resetCheckout: () => void;
  setCouponCode: (code: string | null) => void;
  setExpressCheckoutCustomerId: (customerId: number | null) => void;
  setIsApplePayModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBonusModalOpen: (v: boolean) => void;
  setShippingMethod: (shippingMethod: ShippingMethod | null) => void;
  setUseCredits: (useCredits: boolean) => void;
  /** When targetItem is omitted, item is seen as one-time purchase item */
  updateItemQuantity: (input: {
    cartItemInput: SubCartItemInput;
    targetItem?: PdProductItem;
  }) => Promise<void>;
  /** When targetItem is omitted, item is seen as one-time purchase item */
  updateItemsQuantity: (
    input: Array<{
      cartItemInput: SubCartItemInput;
      targetItem?: PdProductItem;
    }>
  ) => Promise<void>;
  // it's used in cart interval change
  /** Update subscription interval of item, if interval is omitted, item is converted to one-time purchase   */
  updateItemSubscriptionInterval: (input: {
    cartItemInput: SubCartItemInput;
    targetItem: PdProductItem;
  }) => Promise<void>;
  /** Update subscription interval of items, if interval is omitted, item is converted to one-time purchase   */
  updateItemsSubInterval: (
    input: Array<{
      cartItemInput: SubCartItemInput;
      targetItem: PdProductItem;
    }>
  ) => Promise<void>;
  updateRedemptionGiftQuantity: (input: {
    cartItemInput: RedemptionCartItemInput;
    targetItem: PdProductItem;
  }) => Promise<void>;
  flushCart: () => void;
}

const sanitizeInput = (arg: CartItemFunctionInput): CartItemInput => {
  const temp = {
    variantId: arg.variantId,
    quantity: arg.quantity,
  };
  if (isSubCartItemInput(arg)) {
    return { ...temp, interval: arg.interval };
  } else if (isRedemptionCartItemInput(arg)) {
    return { ...temp, redeemItem: true, points: arg.points };
  } else if (isBasicCartItemInput(arg)) {
    return temp;
  }
  throw new Error('unexpected input');
};

const mergeByVariantIdAndInterval = (
  arr: Array<CartItemInput>
): Array<CartItemInput> => {
  return arr.reduce((acc, item) => {
    // Find an existing item in the accumulator that matches the current item's variantId and interval
    const existingItem = acc.find(
      (accItem) =>
        accItem.variantId === item.variantId &&
        ((isBasicCartItemInput(accItem) && isBasicCartItemInput(item)) ||
          (isRedemptionCartItemInput(accItem) &&
            isRedemptionCartItemInput(item)) ||
          (isSubCartItemInput(accItem) &&
            isSubCartItemInput(item) &&
            accItem.interval === item.interval))
    );

    if (existingItem) {
      // If a matching item is found, increment its quantity
      existingItem.quantity += item.quantity;
    } else {
      // If no matching item is found, add the current item to the accumulator
      acc.push({ ...item });
    }

    return acc;
  }, [] as Array<CartItemInput>);
};

const CartContext = React.createContext<CartContextInterface>({} as any);

interface PropTypes {
  children: React.ReactNode;
}

export const CartContextProvider: React.FC<PropTypes> = ({ children }) => {
  const { branchName, pdEnv } = config;
  const { currencyCode, paypalClientId } = useConfig();
  const paypalExpressInitialOptions = {
    clientId: paypalClientId,
    currency: currencyCode,
    components: 'buttons',
    intent: 'authorize',
    commit: false,
  };

  const [isApplePayModalOpen, setIsApplePayModalOpen] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState<boolean>(false);
  const router = useRouter();
  const { mapCheckoutItems } = useTrackingContext();
  const { isCustomerReady, shippingAddress, isSessionFetched } = useAuth();
  const [isCheckoutBeingCreated, setIsCheckoutBeingCreated] = useState(false);
  const [expressCheckoutCustomerId, setExpressCheckoutCustomerId] = useState<
    number | null
  >(null);
  const [couponCode, setCouponCode] = useLocalStorage<string | null>(
    'pd:cart-coupon-code',
    null
  );
  const [shippingMethod, setShippingMethod] =
    useSessionStorage<ShippingMethod | null>('pd:cart-shipping', null);
  const [useCredits, setUseCredits] = useSessionStorage<boolean>(
    'pd:checkout-use-credits',
    true
  );
  const [hasSeenAutoApplyCoupon, setHasSeenAutoApplyCoupon] =
    useSessionStorage<boolean>('pd:has-seen-auto-apply-coupon', false);
  /**
   * Call coupon code tracking API when session is already fetched
   */
  useEffect(() => {
    if (isSessionFetched && couponCode) {
      track({ name: 'Coupon Assignment', code: couponCode });
    }
  }, [couponCode, isSessionFetched]);

  const { couponCode: couponCodeViaAudience } = useApplyCoupon();

  useEffect(() => {
    if (!router.isReady) return;

    if (couponCodeViaAudience && !hasSeenAutoApplyCoupon) {
      setCouponCode(couponCodeViaAudience);
      setShowCouponModal(true);
      setHasSeenAutoApplyCoupon(true);
    } else if (router.query && router.query.coupon_code) {
      setCouponCode(
        Array.isArray(router.query.coupon_code)
          ? router.query.coupon_code[0]
          : router.query.coupon_code
      );
      if (router.query.omitModal) return;
      setShowCouponModal(true);
    }
  }, [
    couponCodeViaAudience,
    hasSeenAutoApplyCoupon,
    router.isReady,
    router.query,
    setCouponCode,
    setHasSeenAutoApplyCoupon,
  ]);

  const [localCartInput, setLocalInput] = useLocalStorage<Array<CartItemInput>>(
    'pd_checkout',
    []
  );

  const [cartItemInput, setCartItemInput] = useState<Array<CartItemInput>>(
    localCartInput || []
  );

  useEffect(() => {
    setLocalInput(cartItemInput);
  }, [cartItemInput, setLocalInput]);

  const flushCart = useCallback(() => {
    setLocalInput([]);
    setCartItemInput([]);
  }, [setLocalInput]);

  const updateCart = useCallback(
    async (items: Array<CartItemFunctionInput>): Promise<any> => {
      if (items.some((item) => item.variantId === null)) {
        try {
          captureMessage('variantId error', {
            level: 'error',
            extra: {
              url: window.location.href,
              cart: JSON.stringify(cartItemInput),
            },
            tags: {
              branchName,
              environment: pdEnv,
            },
          });
        } catch (error) {
          console.error(error);
        }
        throw new Error('variantId is null');
      }

      let _cartItemInput = [...cartItemInput];
      const updateItem = items.filter((item) => item.actionType === 'update');
      if (updateItem.length > 0) {
        let foundFlg = false;
        const updatedItems = _cartItemInput.map((cartItem) => {
          const temp = { ...cartItem };
          const updateTarget = updateItem.find((inputItem) => {
            if (inputItem.variantId !== cartItem.variantId) {
              return false;
            }
            // type assertion
            if (inputItem.actionType !== 'update') {
              return false;
            }

            if (
              isTargetPropertyIntervalType(inputItem.targetProperty) &&
              isSubCartItemInput(cartItem) &&
              inputItem.targetProperty.interval &&
              inputItem.targetProperty.interval === cartItem.interval
            ) {
              return true;
            }

            if (
              isTargetPropertyRedemptionType(inputItem.targetProperty) &&
              isRedemptionCartItemInput(cartItem)
            ) {
              return true;
            }

            if (
              isBasicCartItemInput(cartItem) &&
              typeof inputItem.targetProperty === 'undefined'
            ) {
              return true;
            }

            return false;
          });
          if (updateTarget) {
            foundFlg = true;
            return sanitizeInput(updateTarget);
          }
          return temp;
        });

        if (!foundFlg) {
          throw new Error("can' find target Item");
        }

        _cartItemInput = [...updatedItems];
      }
      const createItems = items.filter((item) => item.actionType === 'create');
      if (createItems.length > 0) {
        _cartItemInput.push(...createItems.map(sanitizeInput));
      }

      setCartItemInput(
        mergeByVariantIdAndInterval(_cartItemInput).filter(
          (item) => item.quantity !== 0
        )
      );
    },
    [cartItemInput]
  );

  const addProductToCart: CartContextInterface['addProductToCart'] =
    useCallback(
      async ({ variantId, quantity }) =>
        updateCart([{ variantId, quantity, actionType: 'create' }]),
      [updateCart]
    );

  const addSubItemToCart: CartContextInterface['addSubItemToCart'] =
    useCallback(
      ({ variantId, quantity, interval }) => {
        return updateCart([
          {
            variantId,
            quantity,
            interval,
            actionType: 'create',
          },
        ]);
      },
      [updateCart]
    );

  const addProductsToCart: CartContextInterface['addProductsToCart'] =
    useCallback(
      (items) => updateCart(items.map((v) => ({ actionType: 'create', ...v }))),
      [updateCart]
    );

  const addSubItemsToCart: CartContextInterface['addSubItemsToCart'] =
    useCallback(
      (items) => updateCart(items.map((v) => ({ actionType: 'create', ...v }))),
      [updateCart]
    );

  /** Function to add redemption item into cart  */
  const addRedemptionGiftToCart: CartContextInterface['addRedemptionGiftToCart'] =
    useCallback(
      (item) => updateCart([{ ...item, actionType: 'create' }]),
      [updateCart]
    );

  const updateItemSubscriptionInterval: CartContextInterface['updateItemSubscriptionInterval'] =
    useCallback(
      async ({ cartItemInput, targetItem }) => {
        // Unfortunately empty string might come in
        // Here we sanitize input
        if (
          typeof cartItemInput.interval === 'string' &&
          cartItemInput.interval.length === 0
        ) {
          console.warn('Empty string is not correct input');
          delete cartItemInput.interval;
        }

        if (isGiftProductItem(targetItem)) {
          throw new Error('unexpected usage of updateItemQuantity');
        }
        const arg = {
          actionType: ActionType.Update,
          ...cartItemInput,
          targetProperty: isSubscriptionItem(targetItem)
            ? {
                interval: targetItem.interval as SubscriptionInterval,
              }
            : undefined,
        };

        return updateCart([arg]);
      },
      [updateCart]
    );

  const updateItemsSubInterval: CartContextInterface['updateItemsSubInterval'] =
    useCallback(
      async (items) => {
        items.forEach((item) => {
          if (isGiftProductItem(item.targetItem)) {
            throw new Error('unexpected usage of updateItemQuantity');
          }
          // Unfortunately empty string might come in
          // Here we sanitize input
          if (
            typeof item.cartItemInput.interval === 'string' &&
            item.cartItemInput.interval.length === 0
          ) {
            console.warn('Empty string is not correct input');
            delete item.cartItemInput.interval;
          }
        });

        return updateCart(
          items.map((item) => ({
            actionType: ActionType.Update,
            ...item.cartItemInput,
            targetProperty: isSubscriptionItem(item.targetItem)
              ? {
                  interval: item.targetItem.interval as SubscriptionInterval,
                }
              : undefined,
          }))
        );
      },
      [updateCart]
    );

  const updateItemsQuantity: CartContextInterface['updateItemsQuantity'] =
    useCallback(
      async (items) => {
        const input = items.map((item) => {
          if (isGiftProductItem(item.targetItem)) {
            throw new Error('unexpected usage of updateItemQuantity');
          }

          return {
            actionType: ActionType.Update,
            ...item.cartItemInput,
            // assign property for subscription items
            targetProperty:
              item.targetItem && isSubscriptionItem(item.targetItem)
                ? {
                    interval: item.targetItem.interval as SubscriptionInterval,
                  }
                : undefined,
            ...(item.targetItem &&
              isSubscriptionItem(item.targetItem) && {
                interval: item.targetItem.interval,
              }),
          };
        });

        return updateCart(input);
      },
      [updateCart]
    );

  const updateItemQuantity: CartContextInterface['updateItemQuantity'] =
    useCallback(
      async (item) => {
        return updateItemsQuantity([item]);
      },
      [updateItemsQuantity]
    );

  /** Function to update the quantity of redemption item in cart  */
  const updateRedemptionGiftQuantity: CartContextInterface['updateRedemptionGiftQuantity'] =
    useCallback(
      async (item) => {
        if (!isGiftProductItem(item.targetItem)) {
          throw new Error('unexpected usage of updateItemQuantity');
        }
        const arg = {
          actionType: ActionType.Update,
          ...item.cartItemInput,
          targetProperty: {
            redeemItem: true as const,
          },
        };
        return updateCart([arg]);
      },
      [updateCart]
    );

  /** effectiveCartPayloadItems */
  const effectiveCartPayloadItems = useEffectiveCartPayloadItems({
    items: cartItemInput,
  });

  const effectiveCartPayload = useEffectiveCartPayload({
    shippingAddress,
    shippingMethod,
    couponCode,
    useCredits,
    expressCheckoutCustomerId,
    items: effectiveCartPayloadItems,
  });

  const {
    effectiveCart,
    loading: effectiveCartLoading,
    refetch,
    error: cartError,
  } = useEffectiveCart(effectiveCartPayload);

  const effectiveCartItems = useMemo(() => {
    return effectiveCart ? effectiveCart.items : [];
  }, [effectiveCart]);

  const groupedItems = useMemo(() => {
    const subscriptionItems =
      effectiveCart?.subscriptions?.map((subscription) => ({
        ...subscription,
        items: effectiveCart?.items.filter((item) =>
          subscription.items.includes(item.variantId)
        ),
      })) ?? [];
    const oneOffPurchaseItems =
      effectiveCart?.items.filter((item) => !isSubscriptionItem(item)) ?? [];

    return { subscriptionItems, oneOffPurchaseItems };
  }, [effectiveCart]);

  const goToCheckout = useCallback(async () => {
    setIsCheckoutBeingCreated(true);
    try {
      gtm({
        group: Gtm.GroupName.Cart,
        name: Gtm.CartEventName.CheckoutOptionSelect,
        data: {
          type: Gtm.CheckoutType.Standard,
          checkout: {
            items: mapCheckoutItems(effectiveCartItems),
          },
        },
      });

      router.push(`/checkout/address`);
    } catch (error) {
      console.error(error, 'goToCheckout');
      showErrorToast({ error: 'error:unknown', caller: 'CartContextProvider' });
    } finally {
      setIsCheckoutBeingCreated(false);
    }
  }, [effectiveCartItems, mapCheckoutItems, router]);

  const cartTotal = get(effectiveCart, 'cartTotal', 0);
  const itemsCount = effectiveCart?.items.reduce((pre, cur) => {
    const _pre = pre + cur.quantity;
    return _pre;
  }, 0);

  const containsPreorderItemsOnly = useMemo(() => {
    return effectiveCartItems.every((item) => item.tags.includes('Preorder'));
  }, [effectiveCartItems]);
  const containsPreorderItem = useMemo(() => {
    return effectiveCartItems.some((item) => item.tags.includes('Preorder'));
  }, [effectiveCartItems]);
  const containsSubscriptionItem = useMemo(() => {
    return effectiveCartItems.some((item) => isSubscriptionItem(item));
  }, [effectiveCartItems]);
  const containsRedemptionItem = useMemo(() => {
    return effectiveCartItems.some((item) => isGiftProductItem(item));
  }, [effectiveCartItems]);
  const canUseCoupon = useMemo(
    () => effectiveCart?.discount?.type !== DiscountType.REFERAL,
    [effectiveCart?.discount]
  );

  const oosItems = useMemo(
    () => effectiveCartItems.filter((item) => item.tags.includes('test_oos')),
    [effectiveCartItems]
  );

  const value: CartContextInterface = {
    canUseCoupon,
    cartTotal,
    containsPreorderItem,
    containsPreorderItemsOnly,
    containsSubscriptionItem,
    containsRedemptionItem,
    couponCode,
    discount: effectiveCart && effectiveCart.discount,
    effectiveCart,
    effectiveCartErrors: effectiveCart && effectiveCart.errors,
    effectiveCartPayload,
    effectiveCartPayloadItems,
    isBonusModalOpen,
    isCartEmpty: !effectiveCartLoading && effectiveCart?.items.length === 0,
    isCartReady: isCustomerReady && !effectiveCartLoading,
    isCartUpdating: effectiveCartLoading,
    isCheckoutBeingCreated,
    items: effectiveCartItems,
    itemsCount: itemsCount || 0,
    loading: effectiveCartLoading,
    shippingMethod,
    useCredits,
    groupedItems,
    oosItems,
    addProductsToCart,
    addProductToCart,
    addRedemptionGiftToCart,
    addSubItemToCart,
    addSubItemsToCart,
    goToCheckout,
    refetchCart: refetch,
    reFetchEffectiveCart: refetch,
    resetCheckout: refetch,
    setCouponCode,
    setExpressCheckoutCustomerId,
    setIsApplePayModalOpen,
    setIsBonusModalOpen,
    setShippingMethod,
    setUseCredits,
    updateItemQuantity,
    updateItemsQuantity,
    updateItemsSubInterval,
    updateItemSubscriptionInterval,
    updateRedemptionGiftQuantity,
    flushCart,
  };

  return (
    <CartContext.Provider value={value}>
      <PayPalScriptProvider options={paypalExpressInitialOptions}>
        {children}
        {couponCode && showCouponModal && (
          <CouponModalPopUp couponCode={couponCode} />
        )}
        <ModalSlideUp
          open={isApplePayModalOpen}
          onClose={() => setIsApplePayModalOpen(false)}
          title={
            <div>
              <p className="mb-1">
                <FormattedMessage id="cart:apple-pay-modal:title" />
              </p>
              <p className="text-sm font-normal">
                <FormattedMessage id="cart:apple-pay-modal:content" />
              </p>
            </div>
          }
        >
          <ApplePayButton className="h-10 max-w-60 grow" />
        </ModalSlideUp>
      </PayPalScriptProvider>
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextInterface => useContext(CartContext);
