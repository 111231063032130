import TranslationsProvider from 'contexts/translations';
import { NextPage } from 'next';
import Head from 'next/head';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import title from '../components/seo/title';

const Error: NextPage<any> = (props) => {
  return (
    <TranslationsProvider>
      <ErrorPageComp {...props} />
    </TranslationsProvider>
  );
};

const ErrorPageComp: NextPage<any> = (props) => {
  const statusCode =
    props.statusCode ||
    (props.initialState && props.initialState.statusCode) ||
    500;
  const { formatMessage } = useIntl();

  return (<>
    <Head>
      {title(
        statusCode === 404
          ? formatMessage({
              id: 'error:page:page-title:page-not-found',
            })
          : formatMessage({
              id: 'error:page:page-title:error',
            })
      )}
    </Head>
    <div
      className="container flex items-center justify-center py-10"
      style={{ minHeight: '40vh' }}
    >
      {statusCode === 404 || statusCode === 505 ? (
        <div className="flex flex-col">
          <NextLink href="/" aria-label="Pets Deli" className="mb-4 mr-auto lg:mr-0">

            <img
              src="/images/petsdeli-logo.svg"
              className="w-auto"
              alt="Pets Deli"
              role="presentation"
            />

          </NextLink>

          <h1 className="text-3xl font-black text-primary-main ">
            <FormattedMessage id="error:page:header" />
          </h1>
          <p className="py-4 text-primary-main">
            <FormattedMessage id="error:page:suggestions:header" />
          </p>
          <NextLink href="/" className="mb-3 text-brand-blue-neu underline">

            <FormattedMessage id="error:page:suggestions:home" />

          </NextLink>
          <NextLink
            href={`/pages/${formatMessage({
              id: 'error:page:suggestions:foodaid:handle',
            })}`}
            className="mb-3 text-brand-blue-neu underline">

            <FormattedMessage id="error:page:suggestions:foodaid" />

          </NextLink>
          <NextLink href="/account" className="mb-3 text-brand-blue-neu underline">

            <FormattedMessage id="error:page:suggestions:account" />

          </NextLink>

          <NextLink href="/cart" className="mb-3 text-brand-blue-neu underline">

            <FormattedMessage id="error:page:suggestions:cart" />

          </NextLink>
        </div>
      ) : (
        <h1 className="text-center">
          <FormattedMessage id="error:page:generic-error-message" />
        </h1>
      )}
    </div>
  </>);
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
