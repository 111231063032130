import paypal from 'config/paypal';
import { ShopEnvironment } from 'config/types';
import { ShopId } from 'constants/shop';

export default {
  analyticsId: 'UA-50762266-11',
  apiEndpoint: 'https://api-staging.petsdeli.de',
  assetsOrigin:
    'https://petsdeli-staging-frontend-assets.s3.eu-central-1.amazonaws.com',
  backInStockUrl: 'https://petsdeli-staging.myshopify.com',
  contentfulEnv: 'staging',
  contentfulSpace: 's8qothrtqrf9',
  cookieDomain: '.petsdeli.de',
  currencyCode: 'EUR',
  foodaidConfigUrl:
    'https://foodaid-assets-dev.s3.eu-central-1.amazonaws.com/conf.yml',
  foodaidEndpoint: 'https://api.petsdeli.de/fube-staging-2023-04',
  frontendUrl: 'https://staging-frontend.petsdeli.de',
  googleAuthClientId:
    '235973678467-cqcqcs14ajg1e5evd1uqo4oodae4ph61.apps.googleusercontent.com',
  gtmParams:
    'GTM-NK69QT6&gtm_auth=mVziPUTr8RQqgHd0gjcs7Q&gtm_preview=env-334&gtm_cookies_win=x',
  metricsUrl: 'https://metrics.petsdeli.de',
  ninetailedApiKey: 'b68d0d4d-2053-409f-9a36-ce2401ee289f',
  ninetailedEnv: 'development',
  oosEndpoint: 'https://api-staging.oos.petsdeli.de',
  paypalClientId: paypal.staging,
  shopId: ShopId.DE,
  shopifyAccessToken: 'eae8c0c980b79b8de86604aea13b0392',
  shopifyDomain: 'jzk1.myshopify.com',
  shopifyGraphqlEndpoint:
    'https://petsdeli-staging.myshopify.com/api/2023-04/graphql.json',
  shopifyUrl: 'https://petsdeli-staging.myshopify.com',
} as ShopEnvironment;
