import IconMagazine from 'assets/icons/magazine.svg';
import IconPhone from 'assets/icons/phone-2.svg';
import cn from 'classnames';
import PetProfileMobileNavigationLink from 'components/pet-profile/mobile-navigation-link';
import { useConfig } from 'config';
import { FeatureFlag, getFeatureFlag } from 'constants/feature-flag';
import { isSegmentationLink } from 'constants/segmentation';
import { useSegmentationContext } from 'contexts/segmentation';
import { useTrackingContext } from 'contexts/tracking';
import useLockBodyScroll from 'hooks/common/use-lock-body-scroll';
import useNavigationData from 'hooks/navigation/use-navigation-data';
import useDynamicTest from 'hooks/test/use-dynamic-ux';
import { NavigationItem } from 'interfaces/navigation-data';
import Link from 'next/link';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import IconChevronLeft from '../../../../assets/icons/chevron-left-stroke.svg';
import IconMenuClose from '../../../../assets/icons/menu-close.svg';
import IconMenu from '../../../../assets/icons/menu.svg';
import { PetContextValues, usePetContext } from '../../../../contexts/pet';
import NavigationLink from '../navigation-link';
import styles from './mobile.module.css';

const ComponentName = 'Navigation';

interface PropTypes {
  className?: string;
}

/**
 * @TODO : Too many repetitive codes. Should be refactored
 */ const Mobile: React.FC<PropTypes> = ({ className }) => {
  const { shopId } = useConfig();
  const [visible, setVisibility] = React.useState(false);
  const { loading, showDynamicUx } = useDynamicTest();

  const intl = useIntl();
  const { trackHotjarEvent } = useTrackingContext();

  const { mobile } = useNavigationData();
  const { current } = usePetContext();
  const { pushSegmentation, getSegmentationFromUrl } = useSegmentationContext();

  const [petType, setPetType] = React.useState<PetContextValues>('dogs');
  const [subNav, setSubNav] = React.useState(null as null | NavigationItem);

  useLockBodyScroll(visible);

  useEffect(() => {
    if (current !== false) {
      setPetType(current);
    }
  }, [current]);

  const navigationItem =
    (className: string, petType: string, subNav?: NavigationItem) =>
    // eslint-disable-next-line react/display-name
    (item: NavigationItem, index: number) => {
      const rootLabel = intl.formatMessage({
        id: `header:navigation-${petType}`,
      });

      return (
        <NavigationLink
          key={index}
          item={item}
          className={className}
          onClick={() => {
            if (item.itemType === 'entry' || item.itemType === 'card') {
              setVisibility(false);
            } else {
              setSubNav(item);
            }

            if ('url' in item && item.url) {
              const segmentation = getSegmentationFromUrl({
                url: item.url,
              });

              if (segmentation) {
                pushSegmentation({
                  segmentation: segmentation.id,
                });
              }
              isSegmentationLink(item.url) && trackHotjarEvent();
            }

            if (item.title) {
              gtm(
                selectElement({
                  element: subNav
                    ? [
                        ComponentName,
                        rootLabel,
                        String(subNav.title),
                        item.title,
                      ]
                    : [ComponentName, rootLabel, item.title],
                })
              );
            }
          }}
        />
      );
    };

  const navigationTree = (pet: 'dogs' | 'cats'): JSX.Element => {
    return (
      <>
        <div className="flex grow flex-col overflow-x-hidden overflow-y-scroll">
          {showDynamicUx && !loading && pet === 'dogs' && (
            <PetProfileMobileNavigationLink setVisibility={setVisibility} />
          )}
          {mobile[pet].map((nav, index) => {
            return (
              <React.Fragment key={index}>
                <div className="bg-background-default -mt-2 h-1" />
                <div className="bg-other-white pt-4 pb-16">
                  {nav.map(navigationItem('font-black', pet))}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  const navigation = (): JSX.Element => {
    return (
      <>
        <div
          data-test="mobile-navigation"
          className={cn(
            styles.navigation,
            'z-mobile-navigation bg-other-white fixed top-0 bottom-0 left-0 flex h-full w-10/12 flex-col items-stretch justify-between overflow-hidden overflow-x-hidden lg:hidden'
          )}
        >
          <div
            className="fixed top-0 right-0 p-2"
            onClick={() => {
              setVisibility(false);
              setSubNav(null);
            }}
          >
            <IconMenuClose className="text-text-dark-bg-contrast-white w-6" />
          </div>
          <div className="text-other-black w-full text-center text-sm tracking-wide uppercase">
            <div className="bg-primary-main flex">
              <button
                data-test="mobile-nav-dogs"
                className={`h-16 w-1/2 pt-1 font-black capitalize focus:outline-hidden`}
                onClick={() => {
                  gtm(
                    selectElement({
                      element: [
                        ComponentName,
                        intl.formatMessage({ id: 'header:navigation-dogs' }),
                      ],
                    })
                  );
                  setPetType('dogs');
                }}
              >
                <span
                  className={`border-other-white text-other-white relative text-base ${
                    petType !== 'dogs' && 'text-grey-125'
                  }`}
                >
                  <FormattedMessage id="header:navigation-dogs" />
                  {petType === 'dogs' && (
                    <div className="border-other-white bg-other-white absolute top-full left-0 h-1 w-full rounded-lg border" />
                  )}
                </span>
              </button>
              <button
                data-test="mobile-nav-cats"
                className={`h-16 w-1/2 pt-1 font-black capitalize focus:outline-hidden`}
                onClick={() => {
                  gtm(
                    selectElement({
                      element: [
                        ComponentName,
                        intl.formatMessage({ id: 'header:navigation-cats' }),
                      ],
                    })
                  );
                  setPetType('cats');
                }}
              >
                <span
                  className={`border-other-white text-other-white relative text-base ${
                    petType !== 'cats' && 'text-grey-125'
                  }`}
                >
                  <FormattedMessage id="header:navigation-cats" />
                  {petType === 'cats' && (
                    <div className="border-other-white bg-other-white absolute top-full left-0 h-1 w-full rounded-lg border" />
                  )}
                </span>
              </button>
            </div>
          </div>
          <div className="flex grow overflow-x-hidden overflow-y-auto">
            <div
              className={cn(
                styles.screen,
                'bg-other-white flex h-full transform flex-col transition-transform duration-300 ease-in-out',
                petType === 'cats' && '-translate-x-full'
              )}
            >
              {navigationTree('dogs')}
            </div>
            <div
              className={cn(
                styles.screen,
                'bg-other-white flex h-full transform flex-col transition-transform duration-300 ease-in-out',
                petType === 'cats' && '-translate-x-full'
              )}
            >
              {navigationTree('cats')}
            </div>
          </div>

          <div
            className={cn(
              styles.screen,
              'bg-other-white absolute top-0 h-full transform overflow-auto leading-[1.4rem] transition-transform duration-300 ease-in-out',
              subNav === null && '-translate-x-full'
            )}
          >
            <button
              className="bg-primary-main text-other-white mb-2 flex w-full items-center py-5 pr-3 pl-4 font-normal focus:outline-hidden"
              onClick={() => {
                setSubNav(null);
              }}
            >
              <IconChevronLeft className="mb-1 h-6 w-4" />
              <FormattedMessage id="header:navigation:mobile:back" />
            </button>
            {petType &&
              subNav !== null &&
              (subNav.itemType === 'nested-card' ||
                subNav.itemType === 'nested-entry') && (
                <>
                  {subNav.items && (
                    <div className="py-4">
                      <div className="text-primary-main mb-2 px-4 text-lg font-black">
                        {subNav.title}
                      </div>
                      {subNav.items.map(navigationItem('px8', petType, subNav))}
                    </div>
                  )}
                </>
              )}
          </div>
          <div className="bg-grey-50 text-primary-main absolute bottom-0 flex w-full items-center justify-between p-4">
            {getFeatureFlag(FeatureFlag.magazin, shopId) && (
              <Link
                href="/magazin"
                className="border-primary-main bg-other-white mx-2 flex h-8 w-2/3 items-center justify-center rounded-lg border px-2 py-1 text-sm font-medium"
                onClick={() => {
                  selectElement({
                    element: [
                      ComponentName,
                      intl.formatMessage({
                        id: 'header:navigation:mobile:magazine',
                      }),
                    ],
                  });
                  setVisibility(false);
                }}
              >
                <IconMagazine className="mr-2 w-5" />
                <FormattedMessage id="header:navigation:mobile:magazine" />
              </Link>
            )}

            <Link
              href="/pages/unsere-futterberatung-online-und-telefonisch"
              className={cn(
                'border-primary-main bg-other-white mx-2 flex h-8 w-2/3 items-center justify-center rounded-lg border px-2 py-1 text-sm font-medium',
                getFeatureFlag(FeatureFlag.magazin, shopId) ? 'w-2/3' : 'w-full'
              )}
              onClick={() => {
                selectElement({
                  element: [
                    ComponentName,
                    intl.formatMessage({
                      id: 'header:navigation:mobile:consulting',
                    }),
                  ],
                });

                setVisibility(false);
              }}
            >
              <IconPhone className="mr-2 w-5" />
              <FormattedMessage id="header:navigation:mobile:consulting" />
            </Link>
          </div>
        </div>
        <div
          data-test="modal-backdrop"
          className={cn(
            'z-mobile-navigation-backdrop bg-background-tile-dark fixed inset-0 lg:hidden'
          )}
          onClick={() => {
            setVisibility(false);
            setSubNav(null);
          }}
        />
      </>
    );
  };

  return (
    <>
      <button
        aria-label="Navigation auf oder zuklappen"
        className={cn(
          className,
          'absolute flex flex-col items-center justify-center leading-none focus:outline-hidden lg:hidden'
        )}
        onClick={() => {
          if (!visible) {
            gtm(
              selectElement({
                element: ComponentName,
              })
            );
          }
          setVisibility((visible) => !visible);
        }}
      >
        {visible ? (
          <IconMenuClose className="text-primary-main h-auto w-5" />
        ) : (
          <IconMenu className="text-primary-main h-auto w-5" />
        )}
      </button>
      {visible && ReactDOM.createPortal(navigation(), document.body)}
    </>
  );
};

export default Mobile;
