import { ShopId } from 'constants/shop';

/**
 * Get the shop ID based on the locale
 */
const getShopId = (locale?: string): ShopId => {
  switch (locale) {
    case 'de-CH':
      return ShopId.CH;
    case 'de':
    default:
      return ShopId.DE;
  }
};

export default getShopId;
