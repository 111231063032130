import cn from 'classnames';
import { SubscriptionInterval } from 'constants/order';
import useFilteredIntervalOptions from 'hooks/product/use-filtered-interval-options';
import { FormattedMessage } from 'react-intl';

interface Props {
  cancelInterval?: boolean;
  interval: string | null;
  productId?: number;
  productIds?: number[];
  setInterval: (interval: SubscriptionInterval | null) => void;
  title?: React.ReactNode;
}

const InlineIntervalSelector: React.FC<Props> = ({
  cancelInterval,
  interval,
  productId,
  productIds = [],
  setInterval,
  title,
}) => {
  const { filteredIntervalOptions } = useFilteredIntervalOptions({
    productIds: productId ? [productId, ...productIds] : [...productIds],
  });

  return (
    <>
      <h3 className="mt-6 text-lg font-black text-primary-main">
        {title || <FormattedMessage id="common:product:interval" />}
      </h3>

      <div
        className={cn(
          'flex overflow-x-scroll',
          cancelInterval ? 'py-4' : 'pb-4'
        )}
      >
        {cancelInterval && (
          <button
            className={cn(
              ' focus-default relative mx-1 flex h-20 w-1/5 grow items-center justify-center whitespace-nowrap rounded-lg border px-3 pb-2 text-center font-black leading-tight text-primary-main shadow-sm',
              interval === null
                ? 'border-primary-main ring-1 ring-primary-main'
                : 'border-grey-100'
            )}
            onClick={() => {
              setInterval(null);
            }}
          >
            <FormattedMessage id="cart:interval-selection:deactivate-subscription" />
          </button>
        )}
        {filteredIntervalOptions.map((intervalValue) => {
          return (
            <button
              key={intervalValue.value}
              className={cn(
                'focus-default relative mx-1 flex h-20 grow items-center justify-center rounded-lg border px-3 pb-2 text-center font-black leading-tight text-primary-main shadow-sm',
                interval === intervalValue.value
                  ? 'border-primary-main ring-1 ring-primary-main'
                  : 'border-grey-100',
                cancelInterval ? 'w-1/5' : 'w-1/4'
              )}
              onClick={() => {
                setInterval(intervalValue.value);
              }}
            >
              <FormattedMessage id={intervalValue.translationKeyCdp} />

              {intervalValue.value === '4week' && (
                <div
                  className="absolute bottom-0 left-0 w-full rounded-b-lg bg-primary-main text-text-dark-bg-contrast-white"
                  style={{ fontSize: '10px' }}
                >
                  <FormattedMessage id="funnel:configurator:most-popular" />
                </div>
              )}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default InlineIntervalSelector;
