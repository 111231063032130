import { AxiosResponse } from 'axios';
import { LoginResponse } from 'models/auth';
import { encodePassword } from 'utils/auth';
import { axios } from 'utils/axios';

type LoginInput = {
  email: string;
  password: string;
};

/**
 * Authenticate user and sets cookies related to authentication: pd_cat, pd_ca and pd_cid
 * @see https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1103331353
 * @param LoginInput
 * @returns LoginResponse
 */
export const login = ({
  email,
  password,
}: LoginInput): Promise<AxiosResponse<LoginResponse>> =>
  axios.post<LoginResponse>('/default/login', {
    email,
    password: encodePassword(password),
  });

/**
 * Logout user and remove cookies related to authentication: pd_cat, pd_ca and pd_cid
 */
export const logout = (): Promise<void> => axios.get('/default/logout');

type AuthBaseResponse =
  | {
      error: null;
      data: {
        customer: {
          id: number;
          email: string;
          ordersCount?: number;
          tags: string;
        };
        referalToken: string;
      };
    }
  | {
      error: { type: string };
      data: null;
    };

type RegisterResponse = AuthBaseResponse;

/**
 * Register User
 */
export const register = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<RegisterResponse> =>
  axios
    .post<RegisterResponse>('/default/customers', {
      email,
      password: encodePassword(password),
    })
    .then(({ data }) => data);

type GuestAuthResponse = AuthBaseResponse;

/**
 * Register User as guest customer. Tags in response contains "guest_customer"
 */
export function createShopifyGuestCustomer(params: {
  email: string;
  checkoutAsGuest: boolean;
}): Promise<GuestAuthResponse> {
  return axios
    .post<GuestAuthResponse>('/default/customers', params, {
      withCredentials: true,
    })
    .then(({ data }) => data);
} // Add missing closing brace and semicolon here

type resetCustomerInput = {
  resetUrl: string;
  password: string;
};

/** Resets a customer's password with the reset password URL received from a reset password email. */
export const resetCustomer = async (
  args: resetCustomerInput
): Promise<boolean> => {
  console.log(args);
  return Promise.reject(new Error('API is not implemented'));
};

type recoverCustomerInput = {
  email: string;
};

/** Sends a reset password email to the customer. The reset password email contains a reset password URL and token that you can pass to the customerResetByUrl or customerReset mutation to reset the customer password. */
export const recoverCustomer = async (
  args: recoverCustomerInput
): Promise<boolean> => {
  console.log(args);
  return Promise.reject(new Error('API is not implemented'));
};

type ActivateCustomerInput = {
  activationUrl: string;
  password: string;
};

/** Activates a customer with the activation url received from customerCreate. */
export const activateCustomer = async (
  args: ActivateCustomerInput
): Promise<boolean> => {
  console.log(args);
  return Promise.reject(new Error('API is not implemented'));
};
