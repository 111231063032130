import { AxiosRequestConfig } from 'axios';
import { getLocaleConfig, default as globalConfig } from 'config';
import merge from 'lodash/merge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { axios } from 'utils/axios';
import getShopId from 'utils/i18n/get-shop-id';

// Whitelisted paths that should not be prefixed with the API endpoint
const NON_PREFIXED_PATHS = ['/api/judge-me/global-overview'];

export const generateApiInterceptor = (
  config: AxiosRequestConfig,
  locale?: string
): AxiosRequestConfig => {
  const isPdexApi = config?.isPdexApi;
  const { apiEndpoint } = getLocaleConfig(locale);
  const shopId = getShopId(locale);

  // Use PDEX endpoint or locale-specific API endpoint
  const selectedEndpoint = isPdexApi ? globalConfig.pdexEndpoint : apiEndpoint;

  const newConfig = { ...config };

  // Only modify URL if it's not absolute and not in whitelist
  if (
    newConfig.url &&
    !isAbsoluteURLRegex.test(newConfig.url) &&
    !NON_PREFIXED_PATHS.includes(newConfig.url)
  ) {
    // Ensure URL doesn't have double slashes
    const cleanEndpoint = selectedEndpoint.endsWith('/')
      ? selectedEndpoint.slice(0, -1)
      : selectedEndpoint;
    const cleanUrl = newConfig.url.startsWith('/')
      ? newConfig.url
      : `/${newConfig.url}`;
    newConfig.url = `${cleanEndpoint}${cleanUrl}`;
  }

  return merge(newConfig, {
    headers: {
      shopid: shopId,
    },
    withCredentials: true,
  });
};

// https://github.com/sindresorhus/is-absolute-url/blob/master/index.js#L7
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

/**
 * Hooks an axios interceptor with shared configuration for API requests
 */
const useApiInterceptor = (): void => {
  const { locale } = useRouter();

  let requestInterceptor: number | null = null;

  if (typeof window !== 'undefined') {
    requestInterceptor = axios.interceptors.request.use((config) =>
      generateApiInterceptor(config, locale)
    );
  }

  useEffect(() => {
    return () => {
      if (requestInterceptor !== null) {
        axios.interceptors.request.eject(requestInterceptor);
      }
    };
  });
};

export default useApiInterceptor;
