import {
  EntryNavigationItem,
  INavigationData,
  NavigationItem,
  NestedEntryNavigationItem,
} from '../../../interfaces/navigation-data';
import {
  commonMobileFooter,
  commonTrustbar,
  dogsCommonBreeds,
  dogsCommonEc,
  dogsCommonNeeds,
  dogsSnack,
  footer,
} from './common';

/**
 * Product Types
 */
const productTypesDogs = (): Array<EntryNavigationItem> => [
  {
    url: '/collections/fur-hunde-probierpakete',
    title: 'Probierpakete',
    src: '/images/category-slider/hunde-probierpakete.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-nassfutter',
    title: 'Nassfutter',
    src: '/images/category-slider/hunde-nassfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-trockenfutter',
    title: 'Trockenfutter',
    src: '/images/category-slider/hunde-trockenfutter.jpg',
    itemType: 'entry',
  },
  {
    url: '/collections/fur-hunde-snacks',
    title: 'Snacks',
    src: '/images/category-slider/hunde-snacks.jpg',
    itemType: 'entry',
  },
];

/**
 * Mobile menu tree for Dogs
 */
const mobileMenuTreeDogs = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/hunde',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  ...dogsSnack,
  ...dogsCommonEc,
  ...dogsCommonNeeds,
  ...dogsCommonBreeds,
  {
    title: 'Zubehör	',
    url: '/collections/fur-hunde-zubehor',
    itemType: 'entry',
  },
  {
    title: 'Gesundheit',
    url: '/collections/fur-hunde-gesundheit',
    itemType: 'entry',
  },
  ...commonMobileFooter,
];

const desktopMenuTreeDogs = (): Array<NavigationItem> => [
  {
    items: [...dogsSnack, ...dogsCommonEc],
    itemType: 'nested-entry',
  },
  {
    items: [...dogsCommonNeeds, ...dogsCommonBreeds],
    itemType: 'nested-entry',
  },
  {
    items: [
      {
        title: 'Zubehör	',
        url: '/collections/fur-hunde-zubehor',
        itemType: 'entry',
      },
      {
        title: 'Gesundheit',
        url: '/collections/fur-hunde-gesundheit',
        itemType: 'entry',
      },
    ],
    itemType: 'nested-entry',
  },
];

/**
 * Mobile menu tree for Cats
 */
const mobileMenuTreeCats = (): Array<
  NestedEntryNavigationItem | EntryNavigationItem
> => [
  {
    url: '/pages/katzen',
    title: 'Home',
    itemType: 'entry',
    dividerBottom: true,
  },
  {
    title: 'Snacks',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Alle Snacks',
        url: '/collections/fur-katzen-snacks',
        itemType: 'entry',
      },
      {
        title: 'Creme Snacks',
        url: '/collections/fur-katzen-snacks/creme-snack',
        itemType: 'entry',
      },
      {
        title: 'Soft Snacks',
        url: '/collections/fur-katzen-snacks/soft-snacks',
        itemType: 'entry',
      },
      {
        title: 'Filet Snacks',
        url: '/collections/fur-katzen-snacks/filet-snack',
        itemType: 'entry',
      },
      {
        title: 'Suppen',
        url: '/collections/fur-katzen-snacks/suppe',
        itemType: 'entry',
      },
      {
        title: 'Sensitiv',
        url: '/collections/fur-katzen-snacks/sensitiv',
        itemType: 'entry',
      },
      {
        title: 'Pasten',
        url: '/collections/fur-katzen-snacks/pasten',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nassfutter',
    url: '/collections/fur-katzen-nassfutter',
    itemType: 'entry',
  },
  {
    title: 'Trockenfutter',
    url: '/collections/fur-katzen-trockenfutter',
    itemType: 'entry',
    dividerBottom: true,
  },
  {
    title: 'Spezielle Bedürfnisse',
    itemType: 'nested-entry',
    items: [
      {
        title: 'Sehr wählerisch',
        url: '/collections/fur-katzen-wahlerisch',
        itemType: 'entry',
      },
      {
        title: 'Verdauungsprobleme',
        url: '/collections/fur-katzen-verdauungsprobleme',
        itemType: 'entry',
      },
      {
        title: 'Freigänger',
        url: '/collections/fur-katzen-freiganger',
        itemType: 'entry',
      },
      {
        title: 'Übergewicht',
        url: '/collections/fur-katzen-ubergewicht',
        itemType: 'entry',
      },
      {
        title: 'Sterilisiert',
        url: '/collections/fur-katzen-sterilisiert',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Nach Alter',
    itemType: 'nested-entry',
    dividerBottom: true,
    items: [
      {
        title: 'Kitten (0-1 Jahre)',
        url: '/collections/fur-katzen-kitten-und-junge-katze',
        itemType: 'entry',
      },
      {
        title: 'Adult (1-10 Jahre)',
        url: '/collections/fur-katzen-adult ',
        itemType: 'entry',
      },
      {
        title: 'Senior (10+ Jahre)',
        url: '/collections/fur-katzen-senior',
        itemType: 'entry',
      },
    ],
  },
  {
    title: 'Gesundheit',
    url: '/collections/fur-katzen-nahrungserganzung',
    itemType: 'entry',
  },
  ...commonMobileFooter,
];

const productTypesCatsDesktop = (): Array<NavigationItem> => [
  {
    itemType: 'nested-entry',
    items: [
      {
        title: 'Snacks',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Alle Snacks',
            url: '/collections/fur-katzen-snacks',
            itemType: 'entry',
          },
          {
            title: 'Creme Snacks',
            url: '/collections/fur-katzen-snacks/creme-snack',
            itemType: 'entry',
          },
          {
            title: 'Soft Snacks',
            url: '/collections/fur-katzen-snacks/soft-snacks',
            itemType: 'entry',
          },
          {
            title: 'Filet Snacks',
            url: '/collections/fur-katzen-snacks/filet-snack',
            itemType: 'entry',
          },
          {
            title: 'Suppen',
            url: '/collections/fur-katzen-snacks/suppe',
            itemType: 'entry',
          },
          {
            title: 'Sensitiv',
            url: '/collections/fur-katzen-snacks/sensitiv',
            itemType: 'entry',
          },
          {
            title: 'Pasten',
            url: '/collections/fur-katzen-snacks/pasten',
            itemType: 'entry',
          },
        ],
      },
      {
        url: '/collections/fur-katzen-nassfutter',
        title: 'Nassfutter',
        itemType: 'entry',
      },
      {
        url: '/collections/fur-katzen-trockenfutter',
        title: 'Trockenfutter',
        itemType: 'entry',
      },
    ],
  },
  {
    itemType: 'nested-entry',
    items: [
      {
        title: 'Spezielle Bedürfnisse',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Sehr wählerisch',
            url: '/collections/fur-katzen-wahlerisch',
            itemType: 'entry',
          },
          {
            title: 'Verdauungsprobleme',
            url: '/collections/fur-katzen-verdauungsprobleme',
            itemType: 'entry',
          },
          {
            title: 'Freigänger',
            url: '/collections/fur-katzen-freiganger',
            itemType: 'entry',
          },
          {
            title: 'Übergewicht',
            url: '/collections/fur-katzen-ubergewicht',
            itemType: 'entry',
          },
          {
            title: 'Sterilisiert',
            url: '/collections/fur-katzen-sterilisiert',
            itemType: 'entry',
          },
        ],
      },
      {
        title: 'Nach Alter',
        itemType: 'nested-entry',
        items: [
          {
            title: 'Kitten (0-1 Jahre)',
            url: '/collections/fur-katzen-kitten-und-junge-katze',
            itemType: 'entry',
          },
          {
            title: 'Adult (1-10 Jahre)',
            url: '/collections/fur-katzen-adult',
            itemType: 'entry',
          },
          {
            title: 'Senior (10+ Jahre)',
            url: '/collections/fur-katzen-senior',
            itemType: 'entry',
          },
        ],
      },
    ],
  },
  {
    itemType: 'nested-entry',
    items: [
      {
        title: 'Gesundheit',
        url: '/collections/fur-katzen-nahrungserganzung',
        itemType: 'entry',
      },
    ],
  },
];

const useNavigationDataDe = (): INavigationData => {
  return {
    mobile: {
      dogs: [[...mobileMenuTreeDogs()]],
      cats: [[...mobileMenuTreeCats()]],
    },
    category: {
      dogs: [...productTypesDogs()],
      cats: [],
    },
    footer: footer(),
    trustbar: [...commonTrustbar],
    desktop: [
      {
        title: 'Hunde',
        context: 'dogs',
        url: '/pages/hunde',
        items: [...desktopMenuTreeDogs()],
      },
      {
        title: 'Katzen',
        context: 'cats',
        url: '/pages/katzen',
        items: [...productTypesCatsDesktop()],
      },
    ],
  };
};
export default useNavigationDataDe;
