import { generateApiInterceptor } from 'api/use-api-interceptor';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getLocaleConfig } from 'config';
import getShopId from 'utils/i18n/get-shop-id';

const TIMEOUT = 5000;

export const configureAxiosInstance = (
  axiosInstance: AxiosInstance,
  locale?: string
): (() => void) => {
  if (!locale) return () => {};

  const shopId = getShopId(locale);
  const { apiEndpoint } = getLocaleConfig(locale);
  const previousConfig = { ...axiosInstance.defaults };

  // Set required defaults
  axiosInstance.defaults = {
    ...axiosInstance.defaults,
    baseURL: apiEndpoint,
    timeout: TIMEOUT,
    headers: {
      ...axiosInstance.defaults.headers,
    },
  };

  const interceptorId = axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const configWithShopId = {
        ...config,
        headers: {
          ...config.headers,
          shopid: shopId,
        },
        withCredentials: true,
      };

      return generateApiInterceptor(configWithShopId, locale);
    }
  );

  const responseInterceptorId = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const fullUrl = error.config?.url || '';
      console.error('Request Failed:', {
        url: fullUrl,
        error: error.message,
        shopId: error.config?.headers?.shopid,
      });
      return Promise.reject(error);
    }
  );

  return () => {
    axiosInstance.defaults = previousConfig;
    axiosInstance.interceptors.request.eject(interceptorId);
    axiosInstance.interceptors.response.eject(responseInterceptorId);
  };
};
