import paypal from 'config/paypal';
import { ShopEnvironment } from 'config/types';
import { ShopId } from 'constants/shop';

export default {
  analyticsId: 'UA-50762266-9',
  apiEndpoint: 'https://api.petsdeli.ch',
  assetsOrigin: 'https://frontend-assets-prod.s3.eu-central-1.amazonaws.com',
  backInStockUrl: 'https://jzk1-schweiz.myshopify.com',
  contentfulEnv: 'master',
  contentfulSpace: 's8qothrtqrf9',
  cookieDomain: '.petsdeli.ch',
  currencyCode: 'CHF',
  foodaidConfigUrl:
    'https://foodaid-assets.s3.eu-central-1.amazonaws.com/conf.yml',
  foodaidEndpoint: 'https://api.petsdeli.de/fube-live-2023-04',
  frontendUrl: 'https://www.petsdeli.ch',
  googleAuthClientId:
    '235973678467-cqcqcs14ajg1e5evd1uqo4oodae4ph61.apps.googleusercontent.com',
  gtmParams:
    'GTM-NK69QT6&gtm_auth=-BmqEwRQ9fDb2EbfJOvQpg&gtm_preview=env-2&gtm_cookies_win=x',
  judgeMePublicToken: 'wIwdlKMf8ApcnQvLZEODAPYnsVQ',
  metricsUrl: 'https://metrics.petsdeli.ch',
  ninetailedApiKey: 'b68d0d4d-2053-409f-9a36-ce2401ee289f',
  ninetailedEnv: 'main',
  oosEndpoint: 'https://api.oos.petsdeli.ch',
  paypalClientId: paypal.production,
  pdexEndpoint: 'https://merchant.petsdeli.de',
  sentryAuthToken:
    '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',
  shopId: ShopId.CH,
  shopifyAccessToken: 'b91b59476cabc03176cb7bc4e5b8a7b0',
  shopifyDomain: 'jzk1-schweiz.myshopify.com',
  shopifyGraphqlEndpoint:
    'https://jzk1-schweiz.myshopify.com/api/2023-04/graphql.json',
  shopifyUrl: 'https://shop.petsdeli.ch',
} as ShopEnvironment;
