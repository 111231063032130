import { SegmentationIds } from 'constants/segmentation';
import { InputProductType } from 'interfaces/api-input/input-product-type';
import { ProductType } from 'interfaces/product';

export default {
  'common:breed': 'Rasse',
  'common:age': 'Alter',
  'common:need': 'Bedürfnis',
  'common:back': 'Zurück',
  'common:submit': 'Speichern',
  'common:and': 'und',
  'common:still': 'Noch',
  'common:add': 'Hinzufügen',
  'common:buttons:load-more': 'Mehr anzeigen',
  'common:buttons:learn-more': 'Mehr erfahren',
  'common:buttons:cancel': 'Abbrechen',
  'common:buttons:continue': 'Weiter',
  'common:add-to-cart': 'In den Warenkorb',
  'common:products': 'Produkte',
  'common:promotions': 'Aktionen',
  'common:price:shipping': 'Versand',
  'common:price:subtotal': 'Zwischensumme',
  'common:price:totalPrice': 'Gesamtpreis',
  'common:price:tax': 'inkl. MwSt.',
  'common:price:discount': 'Rabatt',
  'common:price:from': 'ab',
  'common:product:add': 'Hinzufügen',
  'common:product:variant': 'Variante',
  'common:product:quantity': 'Menge',
  'common:product:interval': 'Intervall',
  'common:product:size': 'Grösse',
  'common:product:to-the-porduct': 'Zum Produkt',
  'common:button:confrim': 'Bestätigen',
  'common:input:required': 'Pflichtfeld',
  'common:input:street:number-required':
    'Straße muss mindestens eine Nummer enthalten',
  'common:input:street:letter-required':
    'Straße muss mindestens einen Buchstaben enthalten',
  'common:input:street:min-length':
    'Straße muss mindestens 2 Zeichen lang sein',
  'common:product:out-of-stock': 'Ausverkauft',

  'common:address:shipping-address': 'Lieferadresse',
  'common:address:billing-address': 'Rechnungsadresse',
  'common:payment:payment-method': 'Zahlungsmethode',
  'common:unknown': 'Unbekannt',
  'common:loading': 'Lädt...',
  'common:country:de': 'Deutschland',
  'common:country:at': 'Österreich',
  'common:country:ch': 'Schweiz',
  'common:for-dogs': 'Für Hunde',
  'common:dogs': 'Hunde',
  'common:for-cats': 'Für Katzen',
  'common:cats': 'Katzen',
  'common:birthday': 'Geburtstag',
  'common:add-item': 'Hinzufügen',
  'common:remove-item': 'Entfernen',
  'common:understood': 'Verstanden',
  'common:saved': 'Gespeichert',
  'common:undo': 'Rückgängig',

  'common:shop-switcher:CH:button': 'Ja, zum deutschen Shop',
  'common:shop-switcher:CH:button-stay': 'Nein, im Schweizer Shop bleiben',
  'common:shop-switcher:CH:text':
    'Auf petsdeli.ch sind nur Lieferungen in die Schweiz möglich. Bist du Kunde in Deutschland?',

  'common:shop-switcher:DE:button': 'Ja, zum Schweizer Shop',
  'common:shop-switcher:DE:button-stay': 'Nein, im Deutschen Shop bleiben',
  'common:shop-switcher:DE:text':
    'Auf petsdeli.de sind nur Lieferungen nach Deutschland und Österreich möglich. Bist du Kunde in der Schweiz?',

  'common:shop-switcher:title': 'Bist du auf dem richtigen Weg?',

  'common:shop-switcher:PL:button':
    'Tak, przejdź do polskiego sklepu internetowego',
  'common:shop-switcher:PL:text':
    'Na petsdeli.ch możliwe są tylko dostawy do Szwajcarii. Jesteś klientem w Polsce?',

  'common:tabs-accordeon:product-info': 'Produktinformation',
  'common:tabs-accordeon:product-info:plural': 'Produktinformationen',
  [`common:breed:${SegmentationIds.Bulldog}`]: 'Bulldoggen',
  [`common:breed:${SegmentationIds.Chihuahua}`]: 'Chihuahuas',
  [`common:breed:${SegmentationIds.Dachshund}`]: 'Dackel',
  [`common:breed:${SegmentationIds.Havanese}`]: 'Malteser',
  [`common:breed:${SegmentationIds.Poodle}`]: 'Pudel',
  [`common:breed:${SegmentationIds.Retriever}`]: 'Retriever',
  [`common:breed:${SegmentationIds.ShepherdDog}`]: 'Schäferhunde',
  [`common:breed:${SegmentationIds.Spitz}`]: 'Spitz',
  [`common:breed:${SegmentationIds.Terrier}`]: 'Terrier',
  [`common:breed:${SegmentationIds.Herdingdog}`]: 'Hütehunde',
  [`common:breed:${SegmentationIds.MixedLarge}`]: 'Mischling (groß)',
  [`common:breed:${SegmentationIds.MixedSmall}`]: 'Mischling (klein)',

  [`common:age:${SegmentationIds.Welpe}`]: 'Welpe',
  [`common:age:${SegmentationIds.DogAdult}`]: 'Adult',
  [`common:age:${SegmentationIds.DogSenior}`]: 'Senior',
  [`common:age:${SegmentationIds.Kitten}`]: 'Kitten',
  [`common:age:${SegmentationIds.CatAdult}`]: 'Adult',
  [`common:age:${SegmentationIds.CatSenior}`]: 'Senior',

  [`common:allergy:${SegmentationIds.grainFree}`]: 'Getreidefrei',
  [`common:allergy:${SegmentationIds.beef}`]: 'Rind',
  [`common:allergy:${SegmentationIds.chicken}`]: 'Huhn',
  [`common:allergy:${SegmentationIds.duck}`]: 'Ente',
  [`common:allergy:${SegmentationIds.fish}`]: 'Fisch',
  [`common:allergy:${SegmentationIds.lamb}`]: 'Lamm',
  [`common:allergy:${SegmentationIds.horse}`]: 'Pferd',
  [`common:allergy:${SegmentationIds.goat}`]: 'Ziege',
  [`common:allergy:${SegmentationIds.sweetPotato}`]: 'Süßkartoffeln',
  [`common:allergy:${SegmentationIds.potato}`]: 'Kartoffeln',

  [`common:product-type:${ProductType.WetFood}`]: 'Nassfutter',
  [`common:product-type:${ProductType.DryFood}`]: 'Trockenfutter',
  [`common:product-type:${ProductType.FoodSupplement}`]: 'Nahrungsergänzung',
  [`common:product-type:${ProductType.Med}`]: 'Med',
  [`common:product-type:${ProductType.FreshMenus}`]: 'BARF',
  [`common:product-type:${ProductType.Snacks}`]: 'Snacks',
  [`common:product-type:${ProductType.Accessories}`]: 'Zubehör',
  [`common:product-type:${ProductType.Gift}`]: 'Geschenk',
  [`common:product-type:${ProductType.Care}`]: 'Pflege',
  [`common:product-type:${ProductType.Testpaket}`]: 'Testpaket',
  [`common:product-type:${InputProductType.WetFood}`]: 'Nassfutter',
  [`common:product-type:${InputProductType.DryFood}`]: 'Trockenfutter',
  [`common:product-type:${InputProductType.FoodSupplement}`]:
    'Nahrungsergänzung',
  [`common:product-type:${InputProductType.Med}`]: 'Med',
  [`common:product-type:${InputProductType.Barf}`]: 'BARF',
  [`common:product-type:${InputProductType.Snacks}`]: 'Snacks',
  [`common:product-type:${InputProductType.Accessories}`]: 'Zubehör',
  [`common:product-type:${InputProductType.Gift}`]: 'Geschenk',
  [`common:product-type:${InputProductType.Care}`]: 'Pflege',
  [`common:product-type:${InputProductType.TestPackage}`]: 'Testpaket',
  'common:bestseller': 'Bestseller',

  'common:hero-filter:nassfutter': 'Nassfutter',
  'common:hero-filter:trockenfutter': 'Trockenfutter',
  'common:hero-filter:barf': 'BARF',
  'common:hero-filter:snacks': 'Snacks',
  'common:hero-filter:all': 'Alle',

  'common:one-time-purchase:title': 'Einmalige Lieferung',

  'common:pet-profile:age-selector:title': 'Bitte wähle das Alter:',
  'common:pet-profile:age-selector:chip:welpe': 'Welpe (0-1 Jahre)',
  'common:pet-profile:age-selector:chip:dog-adult': 'Adult (1-8 Jahre)',
  'common:pet-profile:age-selector:chip:senior': 'Senior (ab 8 Jahren)',
  'common:pet-profile:age-selector:desktop-age-selector:welpe': 'Junior',
  'common:pet-profile:age-selector:desktop-age-selector:description:welpe':
    'bis 1 Jahr',
  'common:pet-profile:age-selector:desktop-age-selector:dog-adult': 'Adult',
  'common:pet-profile:age-selector:desktop-age-selector:description:dog-adult':
    'zwischen 1 und 8 Jahren',
  'common:pet-profile:age-selector:desktop-age-selector:senior': 'Senior',
  'common:pet-profile:age-selector:desktop-age-selector:description:senior':
    'ab 8 Jahren',
  'common:pet-profile:breed-selector:title': 'Bitte wähle eine Rasse:',
  [`common:pet-profile:breed-selector:${SegmentationIds.Bulldog}`]: 'Bulldogge',
  [`common:pet-profile:breed-selector:${SegmentationIds.Chihuahua}`]:
    'Chihuahua',
  [`common:pet-profile:breed-selector:${SegmentationIds.Dachshund}`]: 'Dackel',
  [`common:pet-profile:breed-selector:${SegmentationIds.Havanese}`]:
    'Havaneser & Malteser',
  [`common:pet-profile:breed-selector:${SegmentationIds.Herdingdog}`]:
    'Hütehund',
  [`common:pet-profile:breed-selector:${SegmentationIds.MixedLarge}`]:
    'Mischling (groß)',
  [`common:pet-profile:breed-selector:${SegmentationIds.MixedSmall}`]:
    'Mischling (klein)',
  [`common:pet-profile:breed-selector:${SegmentationIds.Poodle}`]:
    'Pudel & Pudelmix',
  [`common:pet-profile:breed-selector:${SegmentationIds.Retriever}`]:
    'Retriever',
  [`common:pet-profile:breed-selector:${SegmentationIds.ShepherdDog}`]:
    'Schäferhund',
  [`common:pet-profile:breed-selector:${SegmentationIds.Spitz}`]: 'Spitz',
  [`common:pet-profile:breed-selector:${SegmentationIds.Terrier}`]: 'Terrier',
  'common:pet-profile:needs-selector:title': 'Bedürfnisse:',
  'common:pet-profile:needs-selector:pickyeater': 'wählerisches Essverhalten',
  'common:pet-profile:needs-selector:overweight': 'Übergewicht',
  'common:pet-profile:needs-selector:intolerance': 'Unverträglichkeiten',
  'common:pet-profile:needs-selector:intolerance-subtitle':
    'Wähle die Zutaten aus, auf die du verzichten möchtest (optional):',
  'common:pet-profile:needs-selector:digestion': 'Verdauungsprobleme',
  'common:pet-profile:needs-selector:skin-fur': 'Haut- & Fellprobleme',
  'common:pet-profile:needs-selector:pickyeater-description':
    'Mäkelig, Unruhe, Blähungen',
  'common:pet-profile:needs-selector:overweight-description':
    'Trägheit, keine Taillenform erkennbar',
  'common:pet-profile:needs-selector:intolerance-description':
    'Kratzen, Durchfall, Magengrummeln',
  'common:pet-profile:needs-selector:digestion-description':
    'Durchfall, Blähungen, Druckempfindlichkeit',
  'common:pet-profile:needs-selector:skin-fur-description':
    'Kratzen, Lecken, Kahle Stellen',
  'common:pet-profile:create-new-profile': 'Erstelle ein Profil',
  'common:pet-profile:your-pet': 'Dein Vierbeiner',
  'common:pet-profile:allergy': 'Unverträglichkeit',
};
