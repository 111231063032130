import paypal from 'config/paypal';
import { ShopEnvironment } from 'config/types';
import { ShopId } from 'constants/shop';

export default {
  analyticsId: 'UA-50762266-5',
  apiEndpoint: 'https://api.petsdeli.de',
  assetsOrigin: 'https://frontend-assets-prod.s3.eu-central-1.amazonaws.com',
  backInStockUrl: 'https://jzk1.myshopify.com',
  contentfulEnv: 'master',
  contentfulSpace: 's8qothrtqrf9',
  cookieDomain: '.petsdeli.de',
  currencyCode: 'EUR',
  foodaidConfigUrl:
    'https://foodaid-assets.s3.eu-central-1.amazonaws.com/conf.yml',
  foodaidEndpoint: 'https://api.petsdeli.de/fube-live-2023-04',
  frontendUrl: 'https://www.petsdeli.de',
  googleAuthClientId:
    '235973678467-cqcqcs14ajg1e5evd1uqo4oodae4ph61.apps.googleusercontent.com',
  gtmParams:
    'GTM-NK69QT6&gtm_auth=-BmqEwRQ9fDb2EbfJOvQpg&gtm_preview=env-2&gtm_cookies_win=x',
  judgeMePublicToken: 'H_wgWW_5Y6On2s2rCScBCJy',
  metricsUrl: 'https://metrics.petsdeli.de',
  ninetailedApiKey: 'b68d0d4d-2053-409f-9a36-ce2401ee289f',
  ninetailedEnv: 'main',
  oosEndpoint: 'https://api.oos.petsdeli.de',
  paypalClientId: paypal.production,
  sentryAuthToken:
    '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',
  shopId: ShopId.DE,
  shopifyAccessToken: 'a7dbe970b144e21453645a299919b974',
  shopifyDomain: 'jzk1.myshopify.com',
  shopifyGraphqlEndpoint: 'https://jzk1.myshopify.com/api/2023-04/graphql.json',
  shopifyUrl: 'https://shop.petsdeli.de',
} as ShopEnvironment;
